/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IconButton } from 'components/buttons/IconSVG';
import { st } from 'components/inputs/CustomInput';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { getExcelFileName } from 'utils/ExcelUtil';
import { findUtMatrixLibraryList } from 'apis/ut/UtMatrixLibrary';
import { UtMatrixLibrary } from 'models/ut/UtMatrixLibrary';

interface Props {
  open: boolean;
  close: () => void;
  title?: string;
  condition?: {
    stndEqpId?: string;
    bildPlntCd?: string;
  };
  singleSelect?: boolean; // 단일선택 여부 (default: true)
  onCallback: (result: UtMatrixLibrary | UtMatrixLibrary[] | null) => void;
}

const StandardLibraryPopup = ({
  open,
  close,
  title,
  singleSelect = true,
  onCallback,
  ...props
}: Props) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixLibrary[]>([]);
  const [checkedItems, setCheckedItems] = useState<UtMatrixLibrary[]>([]);
  const [condition, setCondition] = useState<any>(
    Object.assign(
      {
        stndEqpId: '',
        bildPlntCd: '',
        curVerYn: 'Y',
      },
      props?.condition || {}
    )
  );

  useEffect(() => {
    if (condition?.stndEqpId) {
      handleSearch(condition);
    }
  }, [condition?.stndEqpId]);

  /**
   * 선택 이벤트 처리
   */
  const handleConfirm = () => {
    if (singleSelect) {
      onCallback && onCallback((checkedItems || []).length ? checkedItems[0] : null);
    } else {
      onCallback && onCallback(checkedItems);
    }
    close();
  };

  /**
   * Quick Select 이벤트 처리
   * @param item
   */
  const handleQuickSelect = (item: UtMatrixLibrary) => {
    onCallback && onCallback(item);
    close();
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ut.label.Library List', 'Library List');
    book.saveAsync(getExcelFileName(t('ut.label.Library List', 'Library List')));
  };

  /**
   * 조회 이벤트 처리
   */
  const handleSearch = async (params) => {
    findUtMatrixLibraryList(params).then((result) => {
      setRowData(result || []);
    });
  };

  const layoutDefinition = [
    {
      binding: 'btnSelect',
      header: ' ',
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button />',
      width: 39,
      visible: singleSelect,
    },
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'stndEqpId',
      header: String(t('ut.label.표준설비코드', '표준설비코드')),
      width: 100,
    },
    {
      binding: 'eqpMchNm',
      header: String(t('ut.label.표준설비명', '표준설비명')),
      width: 100,
    },
    {
      binding: 'utmLibId',
      header: String(t('ut.label.Library ID', 'Library ID')),
      width: 100,
    },
    {
      binding: 'utmLibVerNo',
      header: String(t('ut.label.Version', 'Version')),
      width: 100,
    },
    {
      binding: 'prjNm',
      header: String(t('ut.label.프로젝트', '프로젝트')),
      width: 100,
    },
    {
      binding: 'vdcpNm',
      header: String(t('ut.label.Vendor', 'Vendor')),
      width: 150,
    },
    {
      binding: 'genNm',
      header: String(t('ut.label.Generation', 'Generation')),
      minWidth: 150,
      width: '*',
    },
    {
      binding: 'vltgNvl',
      header: String(t('ut.label.Voltage', 'Voltage')),
      width: 100,
    },
    {
      binding: 'elpwPhasNm',
      header: String(t('ut.label.Phase', 'Phase')),
      width: 100,
    },
    {
      binding: 'ctwTpNm',
      header: String(t('ut.label.Wire', 'Wire')),
      width: 100,
    },
    {
      binding: 'od2EcNvl',
      header: String(t('ut.label.Current(입력값)', 'Current(입력값)')),
      width: 100,
    },
    {
      binding: 'elpwCapa',
      header: String(t('ut.label.Capacity', 'Capacity')),
      width: 100,
    },
    {
      binding: 'cdaCspCapa',
      header: String(t('ut.label.CDA', 'CDA')),
      width: 100,
    },
    {
      binding: 'nitrPeakCspCapa',
      header: String(t('ut.label.N2', 'N2')),
      width: 100,
    },
    {
      binding: 'iwCspCapa',
      header: String(t('ut.label.IW', 'IW')),
      width: 100,
    },
    {
      binding: 'frwtCspCapa',
      header: String(t('ut.label.FW', 'FW')),
      width: 100,
    },
    {
      binding: 'coolCspCapa',
      header: String(t('ut.label.PCW', 'PCW')),
      width: 100,
    },
    {
      binding: 'wwCspCapa',
      header: String(t('ut.label.WW', 'WW')),
      width: 100,
    },
    {
      binding: 'stemCspCapa',
      header: String(t('ut.label.Steam', 'Steam')),
      width: 100,
    },
    {
      binding: 'lngCspCapa',
      header: String(t('ut.label.NG', 'NG')),
      width: 100,
    },
    {
      binding: 'insdExhaCspCapa',
      header: String(t('ut.label.Exhaust air [Return(to Air Handling Unit)]', 'Return Air')),
      width: 100,
    },
    {
      binding: 'otsdExhaCspCapa',
      header: String(t('ut.label.Exhaust air [Ventilation(to Outside)]', 'Exhaust air')),
      width: 100,
    },
    {
      binding: 'suarCspCapa',
      header: String(t('ut.label.Supply air', 'Supply air')),
      width: 100,
    },
    {
      binding: 'rmk',
      header: String(t('ut.label.Remark', 'Remark')),
      width: 100,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('ut.label.등록자', '등록자')),
      width: 100,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('ut.label.등록일자', '등록일자')),
      width: 100,
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        // Quick Select
        if ('btnSelect' === binding) {
          handleQuickSelect && handleQuickSelect(item);
        }
      }
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (item) {
        handleQuickSelect && handleQuickSelect(item);
      }
    });
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleConfirm}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={title || t('ut.label.Library 선택', 'Library 선택')}
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.Library List', 'Library List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <div style={{ display: 'inline-flex', width: '100px', paddingTop: '5px' }}>
            <label htmlFor="curVerYn_popup" style={{ paddingTop: '0px' }}>
              {t('ut.label.이전버전보기', '이전버전보기')}
            </label>
            <Checkbox
              id="curVerYn_popup"
              css={st.checkbox}
              className="custom"
              checked={condition?.curVerYn === 'N'}
              onClick={(e) => {
                const params = {
                  ...condition,
                  curVerYn: condition?.curVerYn === 'Y' ? 'N' : 'Y',
                };
                setCondition(params);
                handleSearch(params);
              }}
              disableRipple
            />
          </div>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(condition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={500}
        isReadOnly={true}
        isFilter={false}
        autoCheck={true}
        rowSelection={singleSelect ? 'single' : 'multiple'}
        allowPinning={false}
        align="center"
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
    </CustomDialog>
  );
};

export default StandardLibraryPopup;
