/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findStandardUse } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from '../../../utils/SessionUtil';

const StandardUseContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const isUtWriter = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);
  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);
  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);

  useImperativeHandle(ref, () => ({
    searchStandardUse: (condition) => {
      searchStandardUse(condition);
    },
  }));

  const searchStandardUse = (condition) => {
    findStandardUse(condition).then((result) => {
      setRowData(result);
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      width: 100,
    },
    {
      binding: 'qty',
      header: String(t('ut.label.라인/설비 수량', '라인/설비 수량')),
      width: 150,
      align: 'center',
      cellTemplate: (params) =>
        `${(params.item.eqpPrdnLnQty || 0).toLocaleString()} / ${(
          (params.item.machEqpQty || 0) + (params.item.elpwEqpQty || 0)
        ).toLocaleString()}`,
    },
    {
      header: String(t('ut.label.Electrocity[KW]', 'Electrocity[KW]')),
      collapseTo: 'od2EcNvl',
      columns: [
        {
          binding: 'od2EcNvl',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'elpwStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          // 수용률 = 사용량 / 실사용량 (계산용 필드로 DB에 값저장하지 않음)
          binding: 'elecDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.CDA[l/min]', 'CDA[l/min]')),
      collapseTo: 'cdaCspSumCapa',
      columns: [
        {
          binding: 'cdaCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'cdaStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'cdaDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.N₂[l/min]', 'N₂[l/min]')),
      collapseTo: 'nitrPeakCspSumCapa',
      columns: [
        {
          binding: 'nitrPeakCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'nitrStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'nitrDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.IW[l/min]', 'IW[l/min]')),
      collapseTo: 'iwCspSumCapa',
      columns: [
        {
          binding: 'iwCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'iwStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'iwDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.IW2[l/min]', 'IW2[l/min]')),
      collapseTo: 'frwtCspSumCapa',
      columns: [
        {
          binding: 'frwtCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'frwtStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'frwtDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.PCW[l/min]', 'PCW[l/min]')),
      collapseTo: 'coolCspSumCapa',
      columns: [
        {
          binding: 'coolCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'coolStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'coolDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.WW[l/min]', 'WW[l/min]')),
      collapseTo: 'wwCspSumCapa',
      columns: [
        {
          binding: 'wwCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'wwStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'wwDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
      collapseTo: 'stemCspSumCapa',
      columns: [
        {
          binding: 'stemCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'stemStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'steamDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
      collapseTo: 'lngCspSumCapa',
      columns: [
        {
          binding: 'lngCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'lngStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'lngDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Ex.Return[CMH]', 'Ex.Return[CMH]')),
      collapseTo: 'insdExhaCspSumCapa',
      columns: [
        {
          binding: 'insdExhaCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'insdExhaStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'insdExhaDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Ex.Vent[CMH]', 'Ex.Vent[CMH]')),
      collapseTo: 'otsdExhaCspSumCapa',
      columns: [
        {
          binding: 'otsdExhaCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'otsdExhaStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'otsdExhaDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Supply Air', 'Supply Air')),
      collapseTo: 'suarCspSumCapa',
      columns: [
        {
          binding: 'suarCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'suarStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'suarDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
  ];

  const handleSubmit = () => {
    props?.onSubmit?.();
  };

  const handleModify = () => {
    props?.onModify?.();
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.표준사용량 비교', '표준사용량 비교')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && condition?.prdnProcCdNm && condition?.eqclNm && (
            <div className="info warning">
              {condition?.utmNo} / {condition?.prdnProcCdNm} / {condition?.eqclNm}
            </div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchStandardUse(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isReadOnly={true}
        isSelector={false}
        frozenColumns={3}
        excludeFilter={['prdnProcCdNm', 'qty']}
      />
      <GlobalBtnGroup>
        {isWrite && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleSubmit}>
            {t('com.button.제출', '제출')}
          </Button>
        )}
        {isWriteComplete && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleModify}>
            {t('com.button.수정', '수정')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(StandardUseContent);
