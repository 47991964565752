import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { CommonBG, CommonBorder, CommonText, GrayColor, BasicColor } from 'ui/theme/Color';
import { ButtonSize, ButtonStyle, ButtonDesign } from 'components/buttons/ButtonStyle';

export const SearchBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  border: 1px solid ${CommonBorder.Basic};
  background-color: ${CommonBG.Deep};
  & > div + div {
    border-top: 1px solid ${CommonBorder.Basic};
    & > div > ul {
      width: calc(100% - 162px) !important;
      &.button2 {
        width: calc(100% - 126px) !important;
      }
    }
  }
`;

export const SearchBoxRow = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
  min-height: 32px;
  box-sizing: content-box;
  flex-shrink: 1;
  ul + ul {
    margin-top: 8px;
  }
`;

export const SearchRows = styled.ul`
  width: 100%;
  display: flex;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  min-height: 32px;
  &.w50 {
    width: 50%;
  }
  &.none {
    & > li {
      width: 100%;
      label {
        width: 130px !important;
        & + div {
          width: calc(100% - 130px) !important;
        }
      }
    }
  }
  &.twoCol {
    & > li {
      width: calc((100% - 24px) / 2);
    }
  }
  &.twoColhalf {
    & > li {
      width: calc((100% - 24px) / 2);
      & > label {
        width: 70px !important;
        & + div {
          width: calc(100% - 70px) !important;
        }
      }
    }
  }
  &.OneThree {
    li:first-child {
      width: 25%;
    }
  }
  &.FourColThree {
    li:first-child {
      width: calc((100% - 72px) / 4);
    }
    li:nth-child(2) {
      width: calc((100% - 72px) / 4);
    }
    li:nth-child(3) {
      width: calc((100% - 72px) / 4);
    }
  }
  &.FourColOneTwoOne {
    li:first-child {
      width: calc((100% - 72px) / 4);
    }
    li:nth-child(2) {
      margin-left: 72px;
      width: calc((100% - 120px) / 2);
    }
    li:nth-child(3) {
      width: calc((100% - 72px) / 4);
    }
  }
  &.FourCol {
    li {
      width: calc((100% - 72px) / 4);
    }
  }
  &.center {
    li {
      align-items: center !important;
    }
  }
  li {
    width: calc((100% - 48px) / 3);
  }
`;

export const SearchCols = styled.li`
  display: flex;
  align-items: flex-start;
  min-height: 32px;
  &.flex {
    display: inline-flex;
    align-items: center;
    justify-content: stretch;
    /* padding-top:7px; */
    & > label {
      padding-top: 0px;
    }
  }
  &.width25 {
    width: calc((100% - 72px) / 4) !important;
    & label:not(.multiSelectLabel) {
      width: 100px !important;
      &:not(.switch) {
        min-width: 100px;
      }
      & + div {
        width: calc(100% - 80px) !important;
      }
      & + label {
        width: 70px !important;
      }
    }
  }
  &.width33 {
    width: calc((((100% - 24px) / 2) - 24px) / 2) !important;
  }
  &.colspan3 {
    width: calc(((100% - 24px) / 4) * 2);
    & > div {
      width: calc((100% - 134px) / 2) !important;
      & + div {
        margin-left: 4px;
      }
    }
  }
  .widthHalf {
    display: flex;

    &.width3-1 {
      > label {
        max-width: 70px;
      }
      div:first-child {
        max-width: 25%;
      }
      div:nth-last-child() {
        max-width: calc(100% - 55px);
      }
    }
  }

  &.colspan31 {
    width: calc(((100% - 24px) / 4) * 2);
    & > div {
      width: calc((100% - 138px) / 3) !important;
      & + div {
        margin-left: 4px;
      }
    }
  }

  &.colspan41 {
    width: calc(((100% - 24px) / 4) * 2);
    label {
      width: 100px !important;
      min-width: 100px;
    }
    & > div {
      width: calc(100% - 100px) !important;
    }
  }

  &.colspan2 {
    width: calc(((100% - 24px) / 4) * 2);
    & > div {
      width: calc((100% - 138px) / 3) !important;
      & + div {
        margin-left: 4px;
      }
    }
  }
  &.colspan21 {
    width: calc(((100% - 24px) / 4) * 2);
    & > div {
      width: calc((100% - 134px) / 2) !important;
      & + div {
        margin-left: 4px;
      }
    }
  }
  &.width3 {
    &:first-child {
      width: calc((100% - 174px) / 2);
      label {
        width: 100px !important;
        & + div {
          width: calc(100% - 100px) !important;
        }
      }
    }
    &:nth-child(2) {
      width: calc((100% - 174px) / 2);
      label {
        width: 80px !important;
        & + div {
          width: calc(100% - 80px) !important;
        }
      }
    }
    &:nth-child(3) {
      width: 174px;
      align-items: center;
      label {
        width: 80px !important;
        &:first-child {
          padding-top: 0px;
        }
      }
    }
  }
  &.none {
    width: 100%;
    & > div {
      width: calc((100% - 138px) / 3);
      & + div {
        margin-left: 4px;
      }
    }
  }

  & + li {
    margin-left: 24px;
  }
  label {
    padding-top: 5px;
    font-size: 13px;
    font-weight: bold;
    text-align: right;
    width: 130px !important;
    line-height: 1.5;
    white-space: nowrap;
    display: block;
    margin-right: 8px;
    & + div {
      width: calc(100% - 130px);
    }
    span {
      &.dot {
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          clear: both;
          position: absolute;
          top: 0px;
          left: -6px;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background: ${CommonText.Error};
        }
      }
    }
  }
`;

export const SearchButtonWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: auto;
  height: 100%;
  margin-left: 24px;
  flex-shrink: 2;
  button + button {
    margin-left: 4px;
  }
  button {
    &.find {
      ${ButtonSize.md}
      ${ButtonStyle.containedNormal}
      ${ButtonDesign.IconLfMD}
    }
    &.chevron {
      ${ButtonSize.md}
      ${ButtonStyle.containedNormal}
      ${ButtonDesign.IconMD}
      &:active,
      &.active {
        transform: rotate(180deg);
      }
    }
    &.reload {
      ${ButtonSize.md}
      ${ButtonStyle.outlineNormal}
      ${ButtonDesign.IconMD}
    }
    &.bookmark {
      ${ButtonSize.md}
      ${ButtonStyle.outlineNormal}
      ${ButtonDesign.IconMD}
    }
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const SearchBtn = styled(Button)`
  width: 76px !important;
  padding: 8px 12px 8px 36px;
  background: ${GrayColor.Gray800};
  color: ${BasicColor.White};
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    clear: both;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    left: 12px;
    background: url(/assets/icon/ico_search_wh.svg) no-repeat center center / 20px 20px;
  }
  &:hover {
    background: ${GrayColor.Gray700};
  }
  &:active {
    background: ${GrayColor.Gray900};
  }
  &:disabled {
    background: rgba(31, 31, 31, 0.4);
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const SearchButtons = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  .buttonSrch {
    svg {
      margin-right: 5px;
      font-size: 18px;
    }
  }
  .buttonFold {
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;
    svg {
      transform: rotate(180deg);
      transition: 0.2s;
    }

    &.close {
      svg {
        transform: rotate(0deg);
      }
    }
  }
`;
