/** @jsxImportSource @emotion/react */
import React, { useRef, useImperativeHandle, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { DataType, addClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import CustomGrid from 'components/grids/CustomGrid';
import { FileTypeName, ManagementMode } from 'models/common/Common';
import { findUtMatrixAllLibraryList, findUtMatrixLibraryList } from 'apis/ut/UtMatrixLibrary';
import { UtMatrixLibrary } from 'models/ut/UtMatrixLibrary';
import { CrudCode } from 'models/common/Edit';
import { UtMatrixStandardEquipment } from 'models/ut/UtMatrixStandardEquipment';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';

interface Props {
  aprReqId: string;
  mode: ManagementMode;
  masterData?: UtMatrixStandardEquipment;
  isReadOnly?: boolean;
  onChange: (detailList: UtMatrixLibrary[]) => void;
  gridHeight: number;
}

const EditUtMatrixLibraryDetailGrid = ({
  aprReqId,
  mode,
  masterData,
  onChange,
  isReadOnly = false,
  gridHeight = 570,
}: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const gridRef = useRef<any>();
  const [subTitle, setSubTitle] = useState<string>('');
  const [rowData, setRowData] = useState<UtMatrixLibrary[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [code, setCode] = useState<any>();

  const isEditable = useMemo(
    () => [ManagementMode.CREATE, ManagementMode.MODIFY].includes(mode),
    [mode]
  );

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    setSubTitle(masterData?.stndEqpId || '');
    if (!masterData?.stndEqpId) {
      setRowData([]);
      return;
    }

    const setDetailData = async () => {
      // 상세 목록이 없는 경우 조회
      if (!(masterData?.detailList || []).length && mode !== ManagementMode.CREATE) {
        await handleSearch({
          aprReqId: aprReqId,
          stndEqpId: masterData?.stndEqpId,
          curVerYn: 'Y',
        });
      } else {
        const rows = masterData?.detailList || [];
        setRowData(rows);
      }
    };

    setDetailData();
  }, [aprReqId, masterData?.stndEqpId]);

  const getCommonCodesForGrid = async () => {
    const utVltgList: Code[] = await getCommonCodeNames('UT_VLTG_LIST');
    const utVltgPhasMapList: Code[] = await getCommonCodeNames('UT_VLTG_PHAS_MAP_LIST');
    const elpwPhasCd: Code[] = await getCommonCodeNames('ELPW_PHAS_CD');
    const ctwTpCd: Code[] = await getCommonCodeNames('CTW_TP_CD');

    setCode({
      utVltgList: utVltgList,
      elpwPhasCd: elpwPhasCd,
      ctwTpCd: ctwTpCd,
      utVltgPhasMapList: utVltgPhasMapList,
    });
  };

  const handleSearch = async (condition) => {
    const response = await findUtMatrixLibraryList(condition);
    setSubTitle(condition?.stndEqpId || '');

    // 등록 요청이 아닐경우 version + 1
    if ((response || []).length && mode !== ManagementMode.CREATE) {
      const updatedRow = response.map(
        (item) =>
          ({
            ...item,
            utmLibVerNo:
              (typeof item.utmLibVerNo === 'number'
                ? item.utmLibVerNo
                : Number(item.utmLibVerNo ?? 0)) + 1,
          } as UtMatrixLibrary)
      );

      setRowData(updatedRow);
      onChange(updatedRow);
    } else {
      setRowData(response);
      onChange(response);
    }
  };

  const layoutDefinition = useMemo(() => {
    const mapUtVltgList = new DataMap(code?.utVltgList || [], 'cmnCd', 'cmnCdNm');
    mapUtVltgList.getDisplayValues = (item) => {
      return (code?.utVltgList || [])
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .filter((o) => !!o.cmnCdNm)
        .map((o) => o.cmnCdNm || '');
    };

    const mapPhaseList = new DataMap(code?.elpwPhasCd || [], 'cmnCd', 'cmnCdNm');
    mapPhaseList.getDisplayValues = (item) => {
      // 선택한 Voltage 값에 따라 Phase 콤보박스 필터링
      const targets = (code?.utVltgPhasMapList || [])
        .filter((o) => o.optValCtn2 === item.vltgNvl)
        .map((o) => o.optValCtn3 || '');
      return (code?.elpwPhasCd || [])
        .filter((o) => !!o.cmnCdNm && (targets || []).includes(o.cmnCd))
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((o) => o.cmnCdNm || '');
    };

    const mapWireList = new DataMap(code?.ctwTpCd || [], 'cmnCd', 'cmnCdNm');
    mapWireList.getDisplayValues = (item) => {
      const targets = (code?.utVltgPhasMapList || [])
        .filter((o) => o.optValCtn2 === item.vltgNvl && o.optValCtn3 === item.elpwPhasCd)
        .map((o) => o.optValCtn4 || '');
      return (code?.ctwTpCd || [])
        .filter((o) => !!o.cmnCdNm && (targets || []).includes(o.cmnCd))
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((o) => o.cmnCdNm || '');
    };

    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        align: 'center',
        width: 45,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'utmLibId',
        header: String(t('ut.grid.Library ID', 'Library ID')),
        align: 'left',
        width: 200,
        isReadOnly: true,
      },
      {
        binding: 'utmLibVerNo',
        header: String(t('ut.grid.Version', 'Version')),
        align: 'center',
        minWidth: 80,
        width: '*',
        isReadOnly: true,
      },
      {
        binding: 'prjNm',
        header: String(t('ut.grid.프로젝트', '프로젝트')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'vdcpNm',
        header: String(t('ut.grid.Vendor', 'Vendor')),
        align: 'center',
        width: 180,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'genNm',
        header: String(t('ut.grid.Generation', 'Generation')),
        align: 'center',
        width: 140,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'vltgNvl',
        header: String(t('ut.grid.Voltage', 'Voltage')),
        align: 'center',
        width: 90,
        cssClass: 'WijmoSelect',
        dataMap: mapUtVltgList,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'elpwPhasCd',
        header: String(t('ut.grid.Phase', 'Phase')),
        align: 'center',
        width: 110,
        cssClass: 'WijmoSelect',
        dataMap: mapPhaseList,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'ctwTpCd',
        header: String(t('ut.grid.Wire', 'Wire')),
        align: 'center',
        width: 90,
        cssClass: 'WijmoSelect',
        dataMap: mapWireList,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'od2EcNvl',
        header: String(t('ut.grid.Current(입력)', 'Current(입력)')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'brkeCapa',
        header: String(t('ut.label.Breaker', 'Breaker')),
        width: 120,
        align: 'right',
        dataType: DataType.Number,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'elpwCapa',
        header: String(t('ut.grid.Capacity', 'Capacity')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
        dataType: DataType.Number,
      },
      {
        binding: 'cdaCspCapa',
        header: String(t('ut.grid.CDA', 'CDA')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'nitrPeakCspCapa',
        header: String(t('ut.grid.N2', 'N2')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'iwCspCapa',
        header: String(t('ut.grid.IW', 'IW')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'frwtCspCapa',
        header: String(t('ut.grid.FW', 'FW')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'coolCspCapa',
        header: String(t('ut.grid.PCW', 'PCW')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'wwCspCapa',
        header: String(t('ut.grid.WW', 'WW')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'stemCspCapa',
        header: String(t('ut.grid.ST', 'ST')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'lngCspCapa',
        header: String(t('ut.grid.NG', 'NG')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'insdExhaCspCapa',
        header: String(t('ut.grid.RA', 'RA')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'otsdExhaCspCapa',
        header: String(t('ut.grid.EA', 'EA')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'suarCspCapa',
        header: String(t('ut.grid.SA', 'SA')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
      {
        binding: 'rmk',
        header: String(t('ut.grid.Remark', 'Remark')),
        align: 'center',
        width: 110,
        isReadOnly: !isEditable,
        isRequired: false,
      },
    ];
  }, [code, masterData]);

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (['utmLibId', 'utmLibVerNo', 'genNm', 'vdcpNm'].includes(binding)) {
        addClass(cell, 'dot');
      }
    }
  });

  const onCellEditEnding = useEvent((grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;

    // 전압값수치
    if ('vltgNvl' === binding) {
      const utVltg = (code?.utVltgList || []).filter((o) => o.cmnCd === newVal);
      if (utVltg.length) {
        // 전기위상코드 선택 (ref. 전압값수치)
        const elpwPhasCd = (code?.elpwPhasCd || []).filter(
          (o) => o.cmnCd === utVltg[0]?.optValCtn3
        );
        data.elpwPhasCd = elpwPhasCd.length ? elpwPhasCd[0].cmnCd : '';
        // 단선유형코드 선택 (ref. 전기위상코드)
        if (elpwPhasCd.length) {
          const ctwTpCd = (code?.ctwTpCd || []).filter(
            (o) => o.cmnCd === elpwPhasCd[0]?.optValCtn1
          );
          data.ctwTpCd = ctwTpCd.length ? ctwTpCd[0].cmnCd : '';
        }
      } else {
        data.elpwPhasCd = '';
        data.ctwTpCd = '';
      }
    } else if ('elpwPhasCd' === binding) {
      const elpwPhasCd = (code?.elpwPhasCd || []).filter((o) => o.cmnCd === newVal);
      // 단선유형코드 선택 (ref. 전기위상코드)
      if (elpwPhasCd.length) {
        const ctwTpCd = (code?.ctwTpCd || []).filter((o) => o.cmnCd === elpwPhasCd[0]?.optValCtn1);
        data.ctwTpCd = ctwTpCd.length ? ctwTpCd[0].cmnCd : '';
      } else {
        data.ctwTpCd = '';
      }
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.formatItem.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      const row = e.panel.rows[e.row];

      if (e.panel.cellType !== CellType.Cell) {
        return;
      }

      // 상태 열 스타일 적용
      if ('crudKey' === col.binding) {
        const item = s.rows[e.row].dataItem;
        if (mode === ManagementMode.CREATE) {
          item.crudKey = CrudCode.CREATE;
        }
        if (mode === ManagementMode.DELETE) {
          item.crudKey = CrudCode.DELETE;
        }
        e.cell.innerHTML = GridStatusCellTemplate({ item: item });
        e.cell.style.textAlign = 'center';
      }
    });
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  const handleAddRow = async () => {
    if (!masterData?.eqclId) {
      openMessageBar({
        type: 'error',
        content: t('ut.msg.대상설비를 선택 후 진행해주세요.', '대상설비를 선택 후 진행해주세요.'),
      });
      return;
    }

    const response = await findUtMatrixAllLibraryList({
      aprReqId: aprReqId,
      stndEqpId: masterData?.stndEqpId,
    });

    const mergedRow = [...rowData, ...response].reduce<UtMatrixLibrary[]>((acc, item) => {
      const exist = acc.find((i) => i.utmLibId === item.utmLibId);
      if (exist) {
        Object.assign(exist, item); // 병합
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, []);

    const numbers = mergedRow
      .map((row) => {
        const match = row?.utmLibId?.match(/\d+$/); // 마지막 숫자 추출
        return match ? parseInt(match[0], 10) : null; // 숫자로 변환
      })
      .filter((num) => num !== null) as number[];

    const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0;
    const newNumber = (maxNumber + 1).toString().padStart(3, '0'); // 3자리 포맷

    const newRow = {
      crudKey: CrudCode.CREATE,
      utmLibId: masterData.stndEqpId + '-' + newNumber,
      utmLibVerNo: 1,
    } as UtMatrixLibrary;
    const rows = [newRow, ...rowData];
    setRowData(rows);
    onChange(rows);
  };

  const handleCopyRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);

    if (!selectedRows.length) {
      openMessageBar({
        type: 'error',
        content: t('com.label.복사할 행을 선택해 주세요.', '복사할 행을 선택해 주세요.'),
      });
      return;
    } else if (selectedRows.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.복사할 행은 하나만 선택해 주세요.',
          '복사할 행은 하나만 선택해 주세요.'
        ),
      });
      return;
    }

    const numbers = rowData
      .map((row) => {
        const match = row?.utmLibId?.match(/\d+$/); // 마지막 숫자 추출
        return match ? parseInt(match[0], 10) : null; // 숫자로 변환
      })
      .filter((num) => num !== null) as number[];

    const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0;
    const newNumber = (maxNumber + 1).toString().padStart(3, '0'); // 3자리 포맷

    const newRow = {
      ...selectedRows[0].dataItem,
      crudKey: CrudCode.CREATE,
      utmLibId: masterData?.stndEqpId + '-' + newNumber,
      utmLibVerNo: 1,
    } as UtMatrixLibrary;

    setRowData([newRow, ...rowData]);
    onChange([newRow, ...rowData]);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);

    if ((selectedRows || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        // 각 선택된 아이템에 대해 삭제 로직 수행
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
    onChange(filteredData);
    gridRef.current?.collectionView.refresh();
  };

  const handleRefresh = async () => {
    if (mode === ManagementMode.CREATE) {
      setRowData([]);
    } else {
      await handleSearch({
        aprReqId: aprReqId,
        stndEqpId: masterData?.stndEqpId,
        curVerYn: 'Y',
      });
    }
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.UT Matrix Library', 'UT Matrix Library')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>
        {isEditable && !isReadOnly && (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="plus" onClick={handleAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="plus" onClick={handleCopyRow} disableRipple>
              {t('com.button.행복사', '행복사')}
            </Button>
            <Button css={IconButton.button} className="minus" onClick={handleDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
            <Button
              css={IconButton.button}
              className="refresh"
              onClick={handleRefresh}
              disableRipple
            >
              {t('com.button.새로고침', '새로고침')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isSelector={[ManagementMode.CREATE, ManagementMode.MODIFY].includes(mode)}
        showCheckAll={false}
        isFilter={false}
        isReadOnly={isReadOnly}
        allowSorting={false}
        allowPinning={false}
        allowDragging={false}
        height={gridHeight}
        initialized={onInitialized}
        cellEditEnding={onCellEditEnding}
        loadedRows={(grid, e) => {
          if (isEditable) {
            // 전체 행 편집모드 활성화 후 특정 컬럼 readonly 처리는 itemFormatter에서 처리
            grid.rows.forEach((row) => {
              row.isReadOnly = false;
            });
          }
        }}
        cellEditEnded={useEvent((grid, e) => {
          const binding = grid.columns[e.col].binding;
          const value = grid.getCellData(e.row, e.col);
          onChange(rowData);
        })}
      />
    </>
  );
};

export default EditUtMatrixLibraryDetailGrid;
