import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { IdleAssetCondition, IdleAssetMaster, IdleAssetDetail } from 'models/asst/IdleAsset';

export const findIdleAssetMaster = async (condition?: IdleAssetCondition) => {
  const params = {
    ...condition,
    copCds: '',
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/master`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetMaster[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetMaster[];
};

export const findIdleAssetDetail = async (rvwReqId: string, copCd: string) => {
  const params = {
    rvwReqId: rvwReqId,
    copCd: copCd,
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/detail`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetDetail[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetDetail[];
};
