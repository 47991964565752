/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { IdleAssetDetail } from 'models/asst/IdleAsset';
import { getExcelFileName } from 'utils/ExcelUtil';
import { findIdleAssetDetail } from 'apis/asst/IdleAsset';

interface Prop {
  onClikDetail: (item: IdleAssetDetail) => void;
}

const IdleAssetEquipmentGrid = ({ onClikDetail }: Prop, ref) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<IdleAssetDetail[]>([]);
  const [subTitle, setSubTitle] = useState<string>('');

  useImperativeHandle(ref, () => ({
    search: (params) => {
      setSubTitle(
        `${t('asst.label.요청정보', '요청정보')} ${params.copCd} ${t(
          'asst.label.설비기술팀',
          '설비기술팀'
        )} : ${params.rvwReqId}`
      );
      console.log(params);
      findIdleAssetDetail(params.rvwReqId, params.copCd).then((result) => {
        setRowData(result);
      });
    },
  }));

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('asst.label.불용자산 설비 List', '불용자산 설비 List');
    book.saveAsync(getExcelFileName(t('asst.label.불용자산 설비 List', '불용자산 설비 List')));
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'asstTpCdNm',
      header: String(t('asst.label.분류', '분류')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'eqpId',
      header: String(t('asst.label.설비ID', '설비ID')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'eqpNm',
      header: String(t('asst.label.설비명', '설비명')),
      align: 'center',
      minWidth: 200,
      width: '*',
    },
    {
      binding: 'cctrCd',
      header: String(t('asst.label.코스트센터', '코스트센터')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'cctrNm',
      header: String(t('asst.label.코스트 센터명', '코스트 센터명')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'asstNo',
      header: String(t('asst.label.자산번호', '자산번호')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'asstNm',
      header: String(t('asst.label.고정자산명', '고정자산명')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'procNm',
      header: String(t('asst.label.공정', '공정')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'rvwStatCdNm',
      header: String(t('asst.label.상태', '상태')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'progressRate',
      header: String(t('asst.label.검토율', '검토율')),
      align: 'center',
      width: 150,
      cellTemplate: CellMaker.makeLink({
        text: '${item.progressRate}',
        click: (e, ctx) => {
          onClikDetail(ctx.item);
        },
      }),
    },
    {
      binding: 'rvwRltDivsCdNm',
      header: String(t('asst.label.검토결과', '검토결과')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'tgtCopCd',
      header: String(t('asst.label.활용법인', '활용법인')),
      align: 'center',
      width: 150,
    },
    {
      // TODO
      binding: '',
      header: String(t('asst.label.지연여부', '지연여부')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'acqrDt',
      header: String(t('asst.label.자산취득일자', '자산취득일자')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'mdlNm',
      header: String(t('asst.label.대응모델', '대응모델')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'asstAcqrAmt',
      header: String(t('asst.label.취득가액', '취득가액')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'deprAmt',
      header: String(t('asst.label.잔존가액', '잔존가액')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'asstQty',
      header: String(t('asst.label.수량', '수량')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'asstQtyUnitNm',
      header: String(t('asst.label.단위', '단위')),
      align: 'center',
      width: 150,
    },
    {
      // TODO 확인필요
      binding: '',
      header: String(t('asst.label.site', 'site')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('asst.label.등록자', '등록자')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('asst.label.등록일자', '등록일자')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'atchFileGrId',
      header: String(t('asst.label.참고자료', '참고자료')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'rmk',
      header: String(t('asst.label.비고', '비고')),
      align: 'center',
      width: 150,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.불용자산 설비 List', '불용자산 설비 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="docu"
            disableRipple
            onClick={() => console.log('검토결과입력팝업')}
          >
            {t('asst.button.검토결과입력', '검토결과입력')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={220}
        allowPinning={false}
        isReadOnly={true}
        initialized={onInitialized}
      />
    </>
  );
};

export default React.forwardRef(IdleAssetEquipmentGrid);
