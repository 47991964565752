/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { getExcelFileName } from 'utils/ExcelUtil';
import { SearchParam, UtMatrixStandardCode } from 'models/ut/UtMatrixStandardCode';
import { Code } from 'models/common/CommonCode';
import { getUtMatrixStandardCodeList } from 'apis/ut/UtMatrixStandartdCode';
import { useMessageBar } from 'hooks/useMessageBar';
import _ from 'lodash';

interface Props {
  open: boolean;
  close: () => void;
  condition?: {
    prdnProcCd?: string;
    eqclId?: string;
    dtalProcCd?: string;
    eqpMchId?: string;
  };
  selectedIds?: string[]; // 선택된 표준설비ID (stndEqpId)
  singleSelect?: boolean; // 단일선택 여부 (default: true)
  showDialogButtons?: boolean;
  onCallback: (result: any | any[]) => void;
}

const StandardEquipmentPopup = ({
  open,
  close,
  singleSelect = true,
  showDialogButtons = true,
  condition,
  selectedIds = [],
  onCallback,

  ...props
}: Props) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [code, setCode] = useState<any>();
  const [rowData, setRowData] = useState<any[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);

  const initSearchParam = {
    prdnProcCds: [],
    eqclIds: [],
    dtalProcCds: [],
    eqpMchId: '',
  };
  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);
  const [searchState, setSearchState] = useState<any>();

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    if ((gridRef.current?.rows || []).length) {
      gridRef.current.rows.forEach((o) => {
        if ((selectedIds || []).includes(o.dataItem.stndEqpId)) {
          o.isSelected = true;
        }
      });
    }
  }, [rowData]);

  useEffect(() => {
    if (!_.isEmpty(condition)) {
      setSearchParam({
        prdnProcCds: [condition?.prdnProcCd] || [],
        eqclIds: [condition?.eqclId] || [],
        dtalProcCds: [],
        eqpMchId: '',
      });
      if (condition?.prdnProcCd && condition?.eqclId) {
        setSearchState(true);
      }
    }
  }, [condition?.prdnProcCd, condition?.eqclId]);

  useEffect(() => {
    if (searchState) {
      handleSearch();
      setSearchState(false);
    }
  }, [searchState]);

  const getCommonCodes = async () => {
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclIdCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'EQCL_ID',
    });
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');
    setPrdnProcCode(prdnProcCd);
    setEqclIdCode(eqclIdCd);
    setDtalProcCode(dtalProcCd);
  };

  /**
   * 선택 이벤트 처리
   */
  const handleConfirm = () => {
    const checkedItems = gridRef.current.rows.filter((o) => o.isSelected).map((o) => o.dataItem);
    if (singleSelect) {
      onCallback && onCallback((checkedItems || []).length ? checkedItems[0] : null);
    } else {
      onCallback && onCallback(checkedItems);
    }
    close();
  };

  /**
   * Quick Select 이벤트 처리
   * @param item
   */
  const handleQuickSelect = (item: any) => {
    onCallback && onCallback(item);
    close();
  };

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ut.label.표준설비코드 List', '표준설비코드 List');
    book.saveAsync(getExcelFileName(t('ut.label.표준설비코드 List', '표준설비코드 List')));
  };

  /**
   * 조회 이벤트 처리
   */
  const handleSearch = async () => {
    getUtMatrixStandardCodeList(searchParam).then((result: UtMatrixStandardCode[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const layoutDefinition = [
    {
      binding: 'btnSelect',
      header: ' ',
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button />',
      width: 39,
      visible: singleSelect,
    },
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('ut.label.공정', '공정')),
      binding: 'prdnProcCd',
      align: 'left',
      width: 120,
      cellTemplate: (item) => `<span>${item.item.prdnProcCdNm}</span>`,
    },
    {
      binding: 'prdnProcCdNm',
      visible: false,
    },
    {
      header: String(t('ut.label.설비군', '설비군')),
      binding: 'eqclId',
      align: 'left',
      width: 150,
      cellTemplate: (item) => `<span>${item.item.eqclIdNm}</span>`,
    },
    {
      binding: 'eqclIdNm',
      visible: false,
    },
    {
      header: String(t('ut.label.세부공정', '세부공정')),
      binding: 'dtalProcCd',
      align: 'left',
      width: 150,
      cellTemplate: (item) => `<span>${item.item.dtalProcCdNm}</span>`,
    },
    {
      binding: 'dtalProcCdNm',
      visible: false,
    },
    {
      header: String(t('ut.label.Main 명', 'Main 명')),
      binding: 'eqpMainNm',
      align: 'left',
      width: 150,
    },
    {
      header: String(t('ut.label.Machine 명', 'Machine 명')),
      binding: 'eqpMchNm',
      align: 'left',
      minWidth: 150,
      width: '*',
    },
    {
      header: String(t('ut.label.표준설비코드', '표준설비코드')),
      binding: 'stndEqpId',
      align: 'center',
      width: 150,
    },
    {
      header: String(t('ut.label.Library 수', 'Library 수')),
      binding: 'libraryQty',
      align: 'center',
      width: 100,
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        // Quick Select
        if ('btnSelect' === binding) {
          handleQuickSelect && handleQuickSelect(item);
        }
      }
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (item) {
        handleQuickSelect && handleQuickSelect(item);
      }
    });
  };

  const btnReload = () => {
    setSearchParam({
      prdnProcCds: [],
      eqclIds: [],
      dtalProcCds: [],
      eqpMchId: '',
    });
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleConfirm}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('ut.label.표준설비코드 조회', '표준설비코드 조회')}
      open={open}
      onClose={close}
      onCancel={close}
      buttons={(showDialogButtons && dialogButtons) || null}
      size={'lg'}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqclId"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCode"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  name="eqpMchNm"
                  value={searchParam?.eqpMchNm || ''}
                  defaultValue={searchParam?.eqpMchNm || ''}
                  placeholder={String(
                    t('com.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.표준설비코드 List', '표준설비코드 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch()}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={500}
        isReadOnly={true}
        isFilter={false}
        autoCheck={true}
        rowSelection={singleSelect ? 'single' : 'multiple'}
        allowPinning={false}
        align="center"
        initialized={onInitialized}
      />
    </CustomDialog>
  );
};

export default StandardEquipmentPopup;
