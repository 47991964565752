/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { DataType, addClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import CustomGrid from 'components/grids/CustomGrid';
import { UtMatrixDetail } from 'models/ut/UtMatrix';
import { downloadExcelTemplates } from 'apis/common/Excel';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';

interface Props {
  detailRowData?: any;
}

const DetailSummaryContent = ({ detailRowData }: Props, ref) => {
  const gridRef = useRef<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);

  useImperativeHandle(ref, () => ({
    btnDetailExcelExport: () => {
      btnDetailExcelExport();
    },
  }));

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'eqclNm',
      header: String(t('ut.label.설비군', '설비군')),
      width: 110,
      isReadOnly: true,
    },
    {
      header: String(t('ut.label.Facility Location', 'Facility Location')),
      columns: [
        {
          binding: 'bldgFloCd',
          header: String(t('ut.label.Floor', 'Floor')),
          width: 100,
        },
        {
          binding: 'istlLocNm',
          header: String(t('ut.label.Room', 'Room')),
          width: 100,
        },
      ],
    },
    {
      binding: 'prdnLnNm',
      header: String(t('ut.label.Line', 'Line')),
      width: 100,
    },
    {
      binding: 'eqpMainNm',
      header: String(t('ut.label.Main', 'Main')),
      width: 100,
    },
    {
      binding: 'eqpMchNm',
      header: String(t('ut.label.Machine', 'Machine')),
      width: 100,
    },
    {
      binding: 'eqpUntNm',
      header: String(t('ut.label.Unit', 'Unit')),
      width: 100,
    },
    {
      header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
      columns: [
        {
          binding: 'machEqpNm',
          header: String(t('ut.label.Mech', 'Mech')),
          width: 100,
        },
        {
          binding: 'eltrEqpNm',
          header: String(t('ut.label.Elec', 'Elec')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.설비 Qty', '설비 Qty')),
      columns: [
        {
          binding: 'machEqpQty',
          header: String(t('ut.label.Mech', 'Mech')),
          width: 100,
        },
        {
          binding: 'eltrEqpQty',
          header: String(t('ut.label.Elec', 'Elec')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.EQP Spec(Dimension_mm)', 'Dimension[mm]/Weight[kg]')),
      collapseTo: 'eqpDimWthLen',
      isCollapsed: true,
      columns: [
        {
          binding: 'eqpDimWthLen',
          header: String(t('ut.label.Width', 'Width')),
          width: 190,
          align: 'right',
        },
        {
          binding: 'eqpDimDpthNvl',
          header: String(t('ut.label.Depth', 'Depth')),
          width: 120,
          align: 'right',
        },
        {
          binding: 'eqpDimHigtNvl',
          header: String(t('ut.label.Height', 'Height')),
          width: 120,
          align: 'right',
        },
        {
          binding: 'eqpWgt',
          header: String(t('ut.label.EQP Spec(Weight_kg)', 'Weight')),
          width: 120,
          align: 'right',
        },
      ],
    },
    {
      header: String(t('ut.label.Electricity', 'Electricity')),
      columns: [
        {
          binding: 'vltgNvl',
          header: String(t('ut.label.Voltage[V]', 'Voltage[V]')),
        },
        {
          binding: 'psnPhasCd',
          header: String(t('ut.label.Phase[Φ]', 'Phase[Φ]')),
          width: 100,
        },
        {
          binding: 'ctwTpCd',
          header: String(t('ut.label.Wires[EA]', 'Wires[EA]')),
          width: 100,
        },
        {
          binding: 'frqNvl',
          header: String(t('ut.label.Frequency[Hz]', 'Frequency[Hz]')),
          width: 100,
        },
        {
          binding: 'pwftNvl',
          header: String(t('ut.label.Power Factor-', 'Power Factor-')),
          width: 100,
        },
        {
          binding: 'od1EcNvl', // TODO 수식 시스템 계산
          header: String(t('ut.label.Current(계산값)[A]', 'Current(계산값)[A]')),
          width: 100,
        },
        {
          binding: 'od2EcNvl',
          header: String(t('ut.label.Current(입력값)[A]', 'Current(입력값)[A]')),
          width: 100,
        },
        {
          binding: 'brkeCapa', // TODO 자동계산
          header: String(t('ut.label.Breaker(MCCB)-', 'Breaker(MCCB)-')),
          width: 100,
        },
        {
          binding: 'plcClctYn',
          header: String(t('ut.label.PLC접지유무-', 'PLC접지유무-')),
          width: 100,
        },
        {
          binding: 'psnCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'elpwCapa',
          header: String(t('ut.label.Capacity[KW]', 'Capacity[KW]')),
          width: 100,
        },
        {
          binding: 'elpwSumCapa', // TODO 수식계산
          header: String(t('ut.label.Capacity Sum', 'Capacity Sum')),
          width: 100,
        },
        {
          binding: 'elpwHeatCapa',
          header: String(t('ut.label.Heat Capacity[KW]', 'Heat Capacity[KW]')),
          width: 100,
        },
        {
          binding: 'dmdElpwTarfNvl', // TODO 조건부 필수
          header: String(t('ut.label.Demand Factor[%]', 'Demand Factor[%]')),
          width: 100,
        },
        {
          binding: 'dmdElpwCapa', // TODO 수식계산
          header: String(
            t('ut.label.Capacity_Sum(수용율 적용)[KW]', 'Capacity_Sum(수용율 적용)[KW]')
          ),
          width: 100,
        },
        {
          binding: 'cdsCapa', // TODO 수식계산
          header: String(
            t(
              'ut.label.Static Condenser Capa. (p.f. = 0.92)[KVAR]',
              'Static Condenser Capa. (p.f. = 0.92)[KVAR]'
            )
          ),
          width: 100,
        },
        {
          binding: 'elpwAtulCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.CDA', 'CDA (Press: 6barG, Temp: -)')),
      columns: [
        {
          binding: 'cdaSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'cdaDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'cdaPntCnt',
          header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
          width: 100,
        },
        {
          binding: 'cdaPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'cdaCspCapa',
          header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'cdaCspSumCapa', // TODO 수식계산
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'cdaAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.N2', 'N2 (Press: 8 barG, Temp: -)')),
      columns: [
        {
          binding: 'nitrSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'nitrDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'nitrPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'nitrPntCnt',
          header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
          width: 100,
        },
        {
          binding: 'nitrCspCapa',
          header: String(t('ut.label.AVG Consumption[ℓ/min]', 'AVG Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'nitrCspSumCapa',
          header: String(t('ut.label.AVG Consumption Sum', 'AVG Consumption Sum')),
          width: 100,
        },
        {
          binding: 'nitrPeakCspCapa',
          header: String(t('ut.label.Peak Consumption[ℓ/min]', 'Peak Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'nitrPeakCspSumCapa', // TODO 수식
          header: String(t('ut.label.Peak Consumption Sum', 'Peak Consumption Sum')),
          width: 100,
        },
        {
          binding: 'nitrAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.IW', 'IW (Press: 3 barG, Temp: 10 ~ 32℃)')),
      columns: [
        {
          binding: 'iwSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'iwDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'iwTmpr',
          header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
          width: 100,
        },
        {
          binding: 'iwPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'iwDrnCapa',
          header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'iwDrnSumCapa',
          header: String(t('ut.label.Drain Sum', 'Drain Sum')),
          width: 100,
        },
        {
          binding: 'iwCspCapa',
          header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'iwCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'iwAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(
        t('ut.label.IW for Emergency fire', 'IW for Emergency fire (Press: 3 barG, Temp:20~25℃)')
      ),
      columns: [
        {
          binding: 'frwtSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'frwtDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
          cssClass: 'WijmoSelect',
        },
        {
          binding: 'frwtTmpr',
          header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
          width: 100,
        },
        {
          binding: 'frwtPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'frwtDrnCapa',
          header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'frwtDrnSumCapa',
          header: String(t('ut.label.Drain Sum', 'Drain Sum')),
          width: 100,
        },
        {
          binding: 'frwtCspCapa',
          header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'frwtCspSumCapa', // TODO 수식 (AV * Mech 수량)
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'frwtAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.PCW', 'PCW (Press: 3 barG, Temp:8~13℃)')),
      columns: [
        {
          binding: 'coolSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'coolDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'coolTmpr',
          header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
          width: 100,
        },
        {
          binding: 'coolPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'coolCspCapa',
          header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'coolCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'coolAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.WW', 'WW (Press: 3 barG, Temp: 8℃(Δ5))')),
      columns: [
        {
          binding: 'wwSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'wwDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'wwTmpr',
          header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
          width: 100,
        },
        {
          binding: 'wwPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'wwCspCapa',
          header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'wwCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'wwAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Steam', 'Steam (Press: 3 barG, Temp)')),
      columns: [
        {
          binding: 'stemSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'stemDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
          cssClass: 'WijmoSelect',
        },
        {
          binding: 'stemCwtSize',
          header: String(
            t('ut.label.Condenstaor connection size[Φ]', 'Condenstaor connection size[Φ]')
          ),
          width: 100,
        },
        {
          binding: 'stemCwtDevcCnctTpCd',
          header: String(
            t('ut.label.Condensator connection[Type]', 'Condensator connection[Type]')
          ),
          width: 100,
          cssClass: 'WijmoSelect',
        },
        {
          binding: 'stemPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'stemCspCapa',
          header: String(t('ut.label.Consumption[t/hr]', 'Consumption[t/hr]')),
          width: 100,
        },
        {
          binding: 'stemCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'stemAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.NG', 'NG (Press: 2 barG, Temp: -)')),
      columns: [
        {
          binding: 'lngSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'lngDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
          cssClass: 'WijmoSelect',
        },
        {
          binding: 'lngPres',
          header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
          width: 100,
        },
        {
          binding: 'lngCspCapa',
          header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
          width: 100,
        },
        {
          binding: 'lngCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'lngAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(
        t(
          'ut.label.Exhaust air [Return(to Air Handling Unit)]',
          'Exhaust air [Return(to Air Handling Unit)]'
        )
      ),
      columns: [
        {
          binding: 'insdExhaSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'insdExhaCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'insdExhaCllrTpCd',
          header: String(t('ut.label.Collector Type[Type]', 'Collector Type[Type]')),
          width: 100,
        },
        {
          binding: 'insdExhaTmpr',
          header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
          width: 100,
        },
        {
          binding: 'insdExhaCspCapa',
          header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
          width: 100,
        },
        {
          binding: 'insdExhaCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'insdExhaAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(
        t('ut.label.Exhaust air [Ventilation(to Outside)]', 'Exhaust air [Ventilation(to Outside)]')
      ),
      columns: [
        {
          binding: 'otsdExhaSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'otsdExhaCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'otsdExhaCspCapa',
          header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
          width: 100,
        },
        {
          binding: 'otsdExhaCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'otsdExhaAtulcspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Supply air', 'Supply air')),
      columns: [
        {
          binding: 'suarSize',
          header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
          width: 100,
        },
        {
          binding: 'suarDevcCnctTpCd',
          header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
          width: 100,
        },
        {
          binding: 'suarCllrTpCd',
          header: String(t('ut.label.Connection Type[Duct/Pipe]', 'Connection Type[Duct/Pipe]')),
          width: 100,
        },
        {
          binding: 'suarCnctCnt',
          header: String(t('ut.label.Connection Quantity[EA]', 'Connection Quantity[EA]')),
          width: 100,
        },
        {
          binding: 'suarCspCapa',
          header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
          width: 100,
        },
        {
          binding: 'suarCspSumCapa', // TODO 수식
          header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
          width: 100,
        },
        {
          binding: 'suarAtulCspCapa',
          header: String(t('ut.label.실사용량', '실사용량')),
          width: 100,
        },
      ],
    },
    {
      binding: 'rmk',
      header: String(t('ut.label.Remark', 'Remark')),
      width: 100,
    },
  ];

  const onInitialized = (grid) => {
    setFlexRef(grid);
  };

  const btnDetailExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '상세요약';
    book.saveAsync(getExcelFileName(t('ut.label.상세요약', '상세요약')));
  };

  return (
    <>
      {/* <SubTitleLayout>
        <SubTitleGroup>
          <h3>상세보기</h3>
          <span className="total">
            총 <span>{(rowData || []).length.toLocaleString()}</span>건
          </span>
        </SubTitleGroup>
      </SubTitleLayout> */}
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={detailRowData}
        height={388}
        excludeFilter={['prdnProcCdNm', 'dtalProcCdNm']}
        initialized={onInitialized}
        isReadOnly={true}
        isSelector={false}
      />
    </>
  );
};

export default React.forwardRef(DetailSummaryContent);
