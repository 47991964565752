/** @jsxImportSource @emotion/react */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useTranslation } from 'react-i18next';
import { useMessageBar } from 'hooks/useMessageBar';
import { ManagementMode } from 'models/common/Common';
import { ReqChgTpCd } from 'models/mp/MpEquipment';
import { CrudCode } from 'models/common/Edit';
import EditUtMatrixLibraryMasterGrid from 'pages/ut/library/EditUtMatrixLibraryMasterGrid';
import EditUtMatrixLibraryDetailGrid from 'pages/ut/library/EditUtMatrixLibraryDetailGrid';
import { ContentSection } from 'components/layouts/ContentSection';
import { ContentFlex } from 'components/layouts/ContentFlex';
import {
  UtEquipmentApprovalRequest,
  UtMatrixStandardEquipment,
  UtMatrixStandardEquipmentCondition,
} from 'models/ut/UtMatrixStandardEquipment';
import { checkAvailableDeleteRequest } from 'apis/ut/UtMatrixLibrary';

interface Props {
  aprReqId: string; // 결재요청ID
  mode: ManagementMode;
  requestMasterList: UtMatrixStandardEquipment[];
  isReadOnly?: boolean;
}

/**
 * UT 라이브러리 등록/수정/삭제 요청 팝업 화면
 *
 * @param open
 * @param mode
 * @param condition
 * @param close
 * @constructor
 */
const UtMatrixLibraryRequestPage = (props: Props, ref) => {
  const { mode, isReadOnly = false } = props;
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<UtMatrixStandardEquipment[]>(
    props?.requestMasterList || []
  );
  const [detailTarget, setDetailTarget] = useState<UtMatrixStandardEquipment>({});

  useEffect(() => {
    init(mode, props?.requestMasterList);
  }, [mode, isReadOnly, props?.requestMasterList]);

  const init = (mode, masterRows) => {
    if (ManagementMode.CREATE === mode && !(masterRows || []).length && !isReadOnly) {
      const newRow = {
        crudKey: CrudCode.CREATE,
        reqChgTpCd: ReqChgTpCd.CREATE,
      };
      setRowData([newRow]);
    } else {
      // 조회된 라이브러리 리스트가 존재(요청내역)
      let isRequest = false;
      masterRows &&
        masterRows.map((master) => {
          if ((master.detailList || []).length > 0) {
            isRequest = true;
            master.detailList.map((item) => {
              if (item.useYn === 'N') {
                item.crudKey = CrudCode.DELETE;
              } else if (item.useYn === 'Y' && Number(item.utmLibVerNo) === 1) {
                item.crudKey = CrudCode.CREATE;
              } else {
                item.crudKey = CrudCode.UPDATE;
              }
            });
          }
        });

      if (isRequest) {
        setRowData(masterRows);
        setDetailTarget(masterRows[0]);
        return;
      }

      const rows = (masterRows || []).reduce((acc, cur) => {
        const keys =
          ManagementMode.DELETE === mode
            ? { crudKey: CrudCode.DELETE }
            : ManagementMode.MODIFY === mode
            ? { crudKey: CrudCode.UPDATE }
            : ManagementMode.CREATE === mode
            ? { crudKey: CrudCode.CREATE }
            : {};
        return [
          ...acc,
          {
            ...cur,
            ...keys,
          },
        ];
      }, [] as UtMatrixStandardEquipment[]);

      setRowData(rows);
      if (rows.length) {
        setDetailTarget(rows[0]);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let isSuccess = true;

      const masterList = rowData.map((o) => ({ ...o }));
      const detailList = masterList
        .filter((item) => item.detailList)
        .flatMap((item) => item.detailList!);

      if (ManagementMode.DELETE === mode) {
        // 삭제할 항목이 존재하는지 체크
        const isNotExistDetail = masterList.some(
          (item) => !item.detailList || item.detailList.length === 0
        );

        if (isNotExistDetail) {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.msg.삭제할 항목을 확인 후 요청해주세요.',
              '삭제할 항목을 확인 후 요청해주세요.'
            ),
          });
          return false;
        }

        // 삭제할 라이브러리가 존재하는지 체크
        const isNotExistDetailLib = masterList.some((item) => !item.libCnt || item.libCnt === 0);
        if (isNotExistDetailLib) {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.msg.삭제할 라이브러리가 없는 항목이 있습니다.',
              '삭제할 라이브러리가 없는 항목이 있습니다.'
            ),
          });
          return false;
        }

        // 삭제> item에 등록되어있는 항목인지 체크
        const deleteList = detailList
          .filter((item) => item.crudKey === CrudCode.DELETE)
          .map((item) => ({ ...item, utmLibVerNo: item.utmLibVerNo && item.utmLibVerNo - 1 }));

        if ((deleteList || []).length > 0) {
          const res = await checkAvailableDeleteRequest(deleteList);
          if (res.successOrNot !== 'Y') {
            isSuccess = false;
            openMessageBar({
              type: 'error',
              content:
                res.data && typeof res.data === 'string'
                  ? res.data
                  : t(
                      'ut.msg.item에 등록되어 있는 항목은 삭제 요청할 수 없습니다.',
                      'item에 등록되어 있는 항목은 삭제 요청할 수 없습니다.'
                    ),
            });
          }
        }
      } else {
        // 요청할 항목이 존재하는지 체크
        const isNotExistDetail = masterList.some(
          (item) => !item.detailList || item.detailList.length === 0
        );

        if (isNotExistDetail) {
          isSuccess = false;
          openMessageBar({
            type: 'error',
            content: t(
              'ut.msg.미작성 항목이 있습니다. 모든 항목을 작성한 후에 요청해주세요.',
              '미작성 항목이 있습니다. 모든 항목을 작성한 후에 요청해주세요.'
            ),
          });
        }
      }

      return isSuccess;
    },
    getBizReqData: (aprReqId: string, reqRsn: string) => {
      const request = {
        castType: 'UtEquipmentRequest', // 서버측 결재요청상세vo > bizReqData cast 타입
        aprReqId: aprReqId,
        reqRsn: reqRsn,
        requestMasterList: rowData,
      } as UtEquipmentApprovalRequest;

      return request;
    },
  }));

  return (
    <>
      <ContentFlex>
        <ContentSection className="section width100p">
          <EditUtMatrixLibraryMasterGrid
            mode={mode}
            rowData={rowData}
            isReadOnly={isReadOnly}
            onRefresh={() => {
              init(mode, props?.requestMasterList);
              setDetailTarget({});
            }}
            onChange={(masterList) => {
              setRowData(masterList);
            }}
            onClickDetail={(target) => {
              setDetailTarget((prev) => target);
            }}
            gridHeight={280}
          />
          <EditUtMatrixLibraryDetailGrid
            mode={mode}
            aprReqId={props.aprReqId}
            masterData={detailTarget}
            isReadOnly={isReadOnly}
            onChange={(detailList) => {
              setRowData((prev) => {
                return (prev || []).reduce((acc, cur) => {
                  if (cur.stndEqpId === detailTarget.stndEqpId) {
                    cur.detailList = detailList;
                  }
                  acc.push(cur);
                  return acc;
                }, [] as UtMatrixStandardEquipment[]);
              });
            }}
            gridHeight={280}
          />
        </ContentSection>
      </ContentFlex>
    </>
  );
};

export default forwardRef(UtMatrixLibraryRequestPage);
