/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { tabs } from 'components/layouts/Tab';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getDtalProcCodes } from 'apis/ut/UtMatrixManager';
import CustomGrid from 'components/grids/CustomGrid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import FacilityInspectionHistoryPopup from './FacilityInspectionHistoryPopup';
import FacilityScheduleRegistPopup from './FacilityScheduleRegistPopup';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import { hasRole } from 'utils/SessionUtil';
import {
  SetupMaster,
  SetupTgtEqp,
  SetupTgtEqpSchedule,
} from 'models/pjm/SetupScheduleRegistDetail';
import {
  getSetupEqpTgtScheduleList,
  getSetupScheduleRegistList,
} from 'apis/pjm/SetupScheduleRegist';
import _ from 'lodash';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import { CrudCode } from 'models/common/Edit';
import SetupMgrManagementPage from '../SetupMgrManagementPage';
import SetupMgrMultiModal from './SetupMgrMultiModal';
import useEvent from 'react-use-event-hook';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};

const SetupScheduleRegistPopup = ({ open, close, condition, onCallback }: Props) => {
  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});

  const [isOpenFacilityInspectionHistoryModal, setOpenFacilityInspectionHistoryModal] =
    useState<boolean>(false);
  const [isOpenFacilityScheduleRegistModal, setOpenFacilityScheduleRegistModal] =
    useState<boolean>(false);

  const [copCode, setCopCode] = useState<Code[]>([]);
  const [prdnPldoCode, setPrdnPldoCode] = useState<Code[]>([]);
  const [emlPrjTpCode, setEmlPrjTpCode] = useState<Code[]>([]);

  const [isOpenMode, setOpenMode] = useState<string>('');
  const [mode, setMode] = useState<string>('');

  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenSetupMgrMultiModal, setOpenSetupMgrMultiModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);

  const [setupMasterData, setSetupMasterData] = useState<SetupMaster>({});
  const [setupEquipRowData, setSetupEquipRowData] = useState<SetupTgtEqp[]>([]);
  const [setupEqpTgtScheduleRowData, setSetupEqpTgtScheduleRowData] = useState<
    SetupTgtEqpSchedule[]
  >([]);

  const [userIds, setUserIds] = useState<string>('');

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const completedStat = useMemo(() => {
    return setupMasterData.elmPrjStatCd === 'COMPLETE' ? true : false;
  }, [setupMasterData]);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  const initCondition = async () => {
    const copCode: Code[] = await getCommonCodeNames('ELM_COP_CD'); //법인
    const elmPrjTpCode: Code[] = await getCommonCodeNames('ELM_PRJ_TP_CD'); // 구분
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE'); // 대상설비 공장

    setCopCode(copCode);
    setPrdnPldoCode(factoryCode);
    setEmlPrjTpCode(elmPrjTpCode);
  };

  useEffect(() => {
    if (condition?.mode === 'WRITE') {
      console.log('elmPrjId', condition.elmPrjId);
      searchList(condition.elmPrjId);
    } else if (condition?.mode === 'NEW') {
      setSetupMasterData({
        elmPrjStatCd: 'PLANNING',
        elmPrjStatCdNm: '일정수립중',
      });
    }
  }, [condition]);

  const setupEquipment = [
    {
      binding: 'elmPrjId',
      visible: false,
    },
    {
      binding: 'prdnPldoCdNm',
      header: String(t('pjm.label.Factory', 'Factory')),
      isReadOnly: true,
      align: 'center',
      width: 200,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('pjm.label.공정', '공정')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      binding: 'eqclIdNm',
      header: String(t('pjm.label.설비군', '설비군')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      header: String(t('pjm.label.Line', 'Line')),
      align: 'center',
      columns: [
        {
          binding: 'eqpLnId',
          header: String(t('pjm.label.A/C', 'A/C')),
          width: 80,
          isReadOnly: true,
          align: 'center',
        },
        {
          binding: 'eqpId',
          header: String(t('pjm.label.호기', '호기')),
          width: 80,
          isReadOnly: true,
          align: 'center',
        },
      ],
    },
    {
      binding: 'tgtEqpId',
      header: String(t('pjm.label.Machine', 'Machine')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      binding: 'schedule',
      header: String(t('pjm.label.일정', '일정')),
      isReadOnly: true,
      align: 'center',
      width: '*',
    },
    {
      binding: 'procStat',
      header: String(t('pjm.label.진행현황', '진행현황')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      binding: 'dlyYn',
      header: String(t('pjm.label.지연', '지연')),
      isReadOnly: true,
      align: 'center',
      width: 100,
    },
    {
      header: String(t('pjm.label.상세보기', '상세보기')),
      isReadOnly: true,
      width: 80,
      align: 'center',
      cssClass: 'WijmoPlay',
      cellTemplate: '<button id="btnPlay"></button>',
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('pjm.label.최종수정자', '최종수정자')),
      isReadOnly: true,
      width: 120,
      align: 'center',
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('pjm.label.최종수정일', '최종수정일')),
      isReadOnly: true,
      width: 150,
      align: 'center',
    },
  ];

  const eqpOnInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setHitTest(ht);

        if (e.target.id === 'btnPlay') {
          const binding = grid.columns[ht.col].binding;
          const item = grid.rows[ht.row].dataItem;
          const searchParam = {
            elmPrjId: item?.elmPrjId,
            tgtEqpId: item?.tgtEqpId,
          };
          searchEqpTgtScheduleList(searchParam);
        }
      }
    });
  };

  const equipSetupScheduleMangement = [
    {
      binding: 'prjSpCdNm',
      header: String(t('pjm.label.일정단계', '일정단계')),
      isReadOnly: true,
      align: 'center',
      width: 150,
    },
    {
      binding: 'spMgrIds',
      header: String(t('pjm.label.담당자', '담당자')),
      align: 'center',
      isReadOnly: !hasAuth,
      cssClass: 'WijmoFind',
      cellTemplate: (params) =>
        `<span>${params.item.spMgrNms}</span><button id="${
          hasAuth === true ? 'btnOpenMgr' : 'readOpenModal'
        }" />`,
      width: 200,
    },
    {
      binding: 'spMgrNms',
      visible: false,
    },
    {
      header: String(t('pjm.label.관리항목', '관리항목')),
      align: 'center',
      columns: [
        {
          binding: 'planEndDt',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 120,
          align: 'center',
        },
        {
          binding: 'isptDt',
          header: String(t('pjm.label.점검일정', '점검일정')),
          width: 110,
          align: 'center',
        },
        {
          binding: 'isptYn',
          header: String(t('pjm.label.점검여부', '점검여부')),
          width: 110,
          align: 'center',
        },
        {
          binding: 'dlyDdn',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 110,
          align: 'center',
        },
        {
          binding: 'cpltYn',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 110,
          align: 'center',
        },
        {
          binding: 'shipNo',
          header: String(t('pjm.label.선적번호', '선적번호')),
          width: 110,
          align: 'center',
        },
      ],
    },
    {
      binding: 'isptCtn',
      header: String(t('pjm.label.점검내용', '점검내용')),
      isReadOnly: true,
      align: 'left',
      width: 200,
    },
    {
      binding: 'isuTitNm',
      header: String(t('pjm.label.Issue', 'Issue')),
      isReadOnly: !hasAuth,
      align: 'left',
      width: 200,
    },
    {
      binding: 'atchFileGrId',
      header: String(t('pjm.label.첨부파일', '첨부파일')),
      isReadOnly: true,
      align: 'center',
      width: 110,
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('pjm.label.최종수정자', '최종수정자')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('pjm.label.최종수정일', '최종수정일')),
      isReadOnly: true,
      align: 'center',
      width: 150,
    },
  ];

  const eqpTgtScheduleOnInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setFlexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'btnOpenMgr':
            setOpenUserMultiModal(true);
            setUserIds(item.spMgrIds);
            // setMode('gridWrtUserId');
            break;
          case 'readOpenModal':
            setOpenUserReadModal(true);
            setUserIds(item.spMgrIds);
            break;
        }
      }
    });
  };

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    // readonly 설정
    if (['spMgrIds'].includes(binding)) {
      e.cancel = true;
    }
  });

  const searchList = (emlPrjId) => {
    getSetupScheduleRegistList(emlPrjId).then((result: SetupMaster) => {
      console.log('result');
      if (result !== null) {
        setSetupMasterData(result);
        setSetupEquipRowData(result?.setupTgtEqp || []);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    console.log('조회');
  };

  const searchEqpTgtScheduleList = (searchParam) => {
    getSetupEqpTgtScheduleList(searchParam).then((result: SetupTgtEqpSchedule[]) => {
      console.log('result', result);
      if (result !== null) {
        setSetupEqpTgtScheduleRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    console.log('조회');
  };

  const handleSave = () => {
    console.log('저장');
  };

  const handleOnChange = (name, value) => {
    setSetupMasterData({ ...setupMasterData, [name]: value });
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_대상설비_List', 'Setup_대상설비_List')));
  };

  const btnfacilityScheduleRegist = () => {
    console.log('일정등록');
    setOpenFacilityScheduleRegistModal(true);
  };

  const btnRefresh = () => {
    console.log('새로고침');
  };

  const callbackSpMgrData = (callbackData) => {
    flexItem['spMgrIds'] = callbackData.userId;
    flexItem['spMgrNms'] = callbackData.empNm;
    if (flexItem['crudKey'] !== CrudCode.CREATE && flexItem['crudKey'] !== CrudCode.DELETE) {
      flexItem['crudKey'] = CrudCode.UPDATE;
    }
  };

  const callbackPrjMgrData = (callbackData) => {
    setSetupMasterData({
      ...setupMasterData,
      prjMgrIds: callbackData.userId,
      prjMgrNms: callbackData.empNm,
    });
  };

  const dialogButtons = [
    <Button
      key={'draft'}
      css={IconButton.button}
      className="draft"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.임시저장', '임시저장')}
    </Button>,
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('com.label.Setup 일정등록(상세)', 'Setup 일정등록(상세)')}
      size="xl"
      open={open}
      onClose={close}
      onCancel={(dialogButtons || []).length ? close : undefined}
      buttons={(hasAuth && dialogButtons) || []}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup Master', 'Setup Master')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.법인', '법인')}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  options={copCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={setupMasterData?.copCd}
                  onChange={(value) => {
                    setSetupMasterData({
                      ...setSetupMasterData,
                      copCd: value,
                    });
                  }}
                  readOnly={completedStat}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.구분', '구분')}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  options={emlPrjTpCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={setupMasterData?.elmPrjTpCd}
                  onChange={(value) => {
                    setSetupMasterData({
                      ...setupMasterData,
                      elmPrjTpCd: value,
                    });
                  }}
                  readOnly={completedStat}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="elmPrjId"
                  placeholder={String(
                    t('pjm.label.프로젝트 ID를 입력해 주세요.', '프로젝트 ID를 입력해 주세요.')
                  )}
                  value={setupMasterData?.elmPrjId}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.elmPrjId}
                  msgError={String(
                    t('pjm.label.프로젝트 ID를 입력해 주세요.', '프로젝트 ID를 입력해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>
                <span>{t('mp.label.전체일정', '전체일정')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.schedule}</TableCell>
              {/* <TableCell>
                <CustomDatepicker
                  isRange={true}
                  value={{
                    startDate: setupMasterData?.startDate || '',
                    endDate: setupMasterData?.endDate || '',
                  }}
                  onChange={(newValue) => console.log(newValue)}
                />
              </TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.프로젝트 명', '프로젝트 명')}</span>
              </TableCell>
              <TableCell colSpan={5}>
                <CustomInputWithSearch
                  name="elmPrjNm"
                  placeholder={String(
                    t('pjm.label.Item 프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')
                  )}
                  value={setupMasterData?.elmPrjNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.elmPrjNm}
                  msgError={String(
                    t('pjm.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')
                  )}
                  readOnly={!hasAuth}
                />
              </TableCell>
              <TableCell>{t('pjm.label.설비대수', '설비대수')}</TableCell>
              <TableCell colSpan={5}>{setupMasterData?.eqpQty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('pjm.label.투자품의 일정', '투자품의 일정')}</TableCell>
              <TableCell>
                <CustomDatepicker
                  isRange={false} // 기간선택인 경우 생략가능
                  value={setupMasterData?.ivesEndDt} // 기본값 (date)
                  onChange={(newValue) => console.log(newValue)}
                />
              </TableCell>
              <TableCell>{t('pjm.label.투자품의 담당자', '투자품의 담당자')}</TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="prjMgr"
                  className="find"
                  placeholder={String(
                    t(
                      'pjm.label.투자품의 담당자를 입력해 주세요.',
                      '투자품의 담당자를 입력해 주세요.'
                    )
                  )}
                  value={setupMasterData.prjMgrNms || ''}
                  defaultValue={setupMasterData.prjMgrNms || ''}
                  onSearchClick={(e) => {
                    setOpenSetupMgrMultiModal(true);
                    // setSetupMasterData({
                    //   ...setupMasterData,
                    //   prjMgrNms: setupMasterData.prjMgrNms,
                    //   prjMgrIds: setupMasterData.prjMgrIds,
                    // });
                  }}
                  readOnly={true}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.prjMgr}
                  msgError={String(
                    t(
                      'pjm.label.투자품의 담당자를 입력해 주세요.',
                      '투자품의 담당자를 입력해 주세요.'
                    )
                  )}
                />
              </TableCell>
              <TableCell>{t('pjm.label.상태', '상태')}</TableCell>
              <TableCell>{setupMasterData?.elmPrjStatCdNm}</TableCell>
              <TableCell>{t('pjm.label.최종수정일', '최종수정일')}</TableCell>
              <TableCell>{setupMasterData?.dataUpdDtm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.준수', '준수')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.obsrCnt}</TableCell>
              <TableCell>
                <span>{t('pjm.label.지연', '지연')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.dlyCnt}</TableCell>
              <TableCell>
                <span>{t('pjm.label.적기완료', '적기완료')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.cpltCnt}</TableCell>
              <TableCell>
                <span>{t('pjm.label.지연완료', '지연완료')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.dlyCpltCnt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.지연사유/이슈', '지연사유/이슈')}</span>
              </TableCell>
              <TableCell colSpan={7}>
                <CustomInputWithSearch
                  name="isuTitNm"
                  placeholder={String(
                    t('pjm.msg.지연사유/이슈를 입력해 주세요.', '지연사유/이슈를 입력해 주세요.')
                  )}
                  value={setupMasterData?.isuTitNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.isuTitNm}
                  msgError={String(
                    t('pjm.msg.지연사유/이슈를 입력해 주세요.', '지연사유/이슈를 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup 대상설비', 'Setup 대상설비')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{setupEquipRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {setupMasterData.elmPrjStatCd !== 'COMPLETE' && hasAuth ? (
            <Button
              css={IconButton.button}
              className="regist"
              onClick={btnfacilityScheduleRegist}
              disableRipple
            >
              {t('com.button.설비별일정등록', '설비별일정등록')}
            </Button>
          ) : (
            ''
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnRefresh} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={setupEquipment}
        rowData={setupEquipRowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={completedStat}
        initialized={eqpOnInitialized}
        height={150}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
        onChangeCheckedItem={() => console.log('click')}
      />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.설비별 Setup 일정관리', '설비별 Setup 일정관리')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{setupEqpTgtScheduleRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <ComboBox
            options={[]}
            placeholder={String(t('com.label.선택', '선택'))}
            defaultValue={''}
            onChange={(value) => console.log(value)}
          />
          <ComboBox
            options={[]}
            placeholder={String(t('com.label.선택', '선택'))}
            defaultValue={''}
            onChange={(value) => console.log(value)}
          />
          <SubTitleGroup>
            <span className="info primary">10/14(월) ~ 10/20(일)</span>
          </SubTitleGroup>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={equipSetupScheduleMangement}
        rowData={setupEqpTgtScheduleRowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={completedStat}
        beginningEdit={beginningEdit}
        initialized={eqpTgtScheduleOnInitialized}
        height={400}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
        onChangeCheckedItem={() => console.log('click')}
      />
      {isOpenFacilityInspectionHistoryModal && (
        <FacilityInspectionHistoryPopup
          open={isOpenFacilityInspectionHistoryModal}
          close={() => setOpenFacilityInspectionHistoryModal(false)}
          onCallback={() => console.log('콜백')}
          condition={''}
        />
      )}
      {isOpenFacilityScheduleRegistModal && (
        <FacilityScheduleRegistPopup
          open={isOpenFacilityScheduleRegistModal}
          close={() => setOpenFacilityScheduleRegistModal(false)}
          onCallback={() => console.log('콜백')}
          condition={''}
        />
      )}
      {isOpenUserMultiModal && (
        <UserMultiModal
          open={isOpenUserMultiModal}
          close={() => setOpenUserMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={userIds}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            if (callbackData.userId === '') {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackSpMgrData(callbackData);
          }}
        />
      )}
      {isOpenSetupMgrMultiModal && (
        <SetupMgrMultiModal
          open={isOpenSetupMgrMultiModal}
          close={() => setOpenSetupMgrMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={setupMasterData.prjMgrIds}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            if (callbackData.userId === '') {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackPrjMgrData(callbackData);
          }}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
    </CustomDialog>
  );
};

export default SetupScheduleRegistPopup;
