/** @jsxImportSource @emotion/react */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Tab, Tabs } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { tabs } from 'components/layouts/Tab';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getDtalProcCodes } from 'apis/ut/UtMatrixManager';
import CustomGrid from 'components/grids/CustomGrid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import FacilityInspectionHistoryPopup from './FacilityInspectionHistoryPopup';
import PropTypes from 'prop-types';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import { FileTypeName } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { GatingRegist } from 'models/gtng/GatingRegist';
import DetailSummaryContent from 'pages/ut/review/DetailSummaryContent';
import ElectricSummaryContent from 'pages/ut/review/ElectricSummaryContent';
import MachineSummaryContent from 'pages/ut/review/MachineSummaryContent';
import ScheduleRegistGrid from '../regist/ScheduleRegistGrid';
import ManagerRegistGrid from '../regist/ManagerRegistGrid';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};

const SetupResultRegistPopup = ({ open, close, condition, onCallback }: Props) => {
  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});

  const [setupEquipRowData, setSetupEquipRowData] = useState<any>();

  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [askPurpCode, setAskPurpCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);

  const [checkedItems, setCheckedItems] = useState<any>();

  const initSearchParam = {
    bildPlntCds: [],
    askPurpCds: [],
    prdnProcCds: [],
    dtalProcCds: [],
  };
  const [searchParam, setSearchParam] = useState<any>(initSearchParam);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const factoryCode = await getCommonCodeNames('FACTORY_CODE');
    const elmCopCode = await getCommonCodeNames('ELM_COP_CD');
    // const askPurpCode = await getCommonCodeNames('ASK_PURP_CD');
    // const utmWkProgStatCode = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
    // const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');

    const factoryMerged = [...bildPlntCode, ...factoryCode, ...elmCopCode];
    setBildPlntCode(factoryMerged);
    // setAskPurpCode(askPurpCode);
    setPrdnProcCode(prdnProcCd);
    // setDtalProcCode(dtalProcCd);
    // setUtmWkProgStatCode(utmWkProgStatCode);
  };

  const equipSetupScheduleMangement = [
    {
      binding: 'bildPlntCdNm',
      header: String(t('pjm.label.공정', '공정')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.공정군', '공정군')),
      isReadOnly: true,
      align: 'center',
    },
    {
      header: String(t('pjm.label.Line', 'Line')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.A/C', 'A/C')),
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.호기', '호기')),
        },
      ],
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.Machine', 'Machine')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.일정단계', '일정단계')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.담당자', '담당자')),
      isReadOnly: true,
      align: 'center',
    },
    {
      header: String(t('pjm.label.관리항목', '관리항목')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.기준일정', '기준일정')),
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.점검여부', '점검여부')),
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.선적번호', '선적번호')),
        },
      ],
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.점검내용', '점검내용')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.Issue', 'Issue')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.첨부파일', '첨부파일')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.최종수정자', '최종수정자')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.최종수정일', '최종수정일')),
      isReadOnly: true,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setHitTest(ht);
      }
    });
  };

  const handleSave = () => {
    console.log('저장');
  };

  const handleOnChange = (name, value) => {
    console.log('변경');
    setSearchParam({ ...searchParam, [name]: value });
    if (name === 'prdnProcCd') {
      getDtalProcCode(value);
      setSearchParam({ ...searchParam, [name]: value, dtalProcCd: '' });
    }
  };

  const getDtalProcCode = async (value) => {
    if (value == '') {
      setDtalProcCode([]);
    } else {
      const dtalProcCode: Code[] = await getDtalProcCodes({
        optValCtn1: value,
        cmnGrCd: 'DTAL_PROC_CD',
      });
      setDtalProcCode(dtalProcCode);
    }
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_대상설비_List', 'Setup_대상설비_List')));
  };

  return (
    <CustomDialog
      title={t('com.label.실적입력', '실적입력')}
      size="xl"
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup Master', 'Setup Master')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.공장', '공장')}</span>
              </TableCell>
              <TableCell>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.구분', '구분')}</span>
              </TableCell>
              <TableCell>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.Setup ID', 'Setup ID')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="itemEngNm"
                  placeholder={String(
                    t('pjm.label.Setup ID를 입력해 주세요.', 'Setup ID를 입력해 주세요.')
                  )}
                  value={''}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.itemEngNm}
                  msgError={String(
                    t('pjm.label.Setup ID를 입력해 주세요.', 'Setup ID를 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.Setup Name', 'Setup Name')}</span>
              </TableCell>
              <TableCell colSpan={5}>
                <CustomInputWithSearch
                  name="itemEngNm"
                  placeholder={String(
                    t('pjm.label.Item 영문명을 입력해 주세요.', 'Item 영문명을 입력해 주세요.')
                  )}
                  value={''}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.itemEngNm}
                  msgError={String(
                    t('pjm.label.Item 영문명을 입력해 주세요.', 'Item 영문명을 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.실적입력', '실적입력')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{setupEquipRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <ComboBox
            options={[]}
            placeholder={String(t('com.label.선택', '선택'))}
            defaultValue={''}
            onChange={(value) => console.log(value)}
          />
          <ComboBox
            options={[]}
            placeholder={String(t('com.label.선택', '선택'))}
            defaultValue={''}
            onChange={(value) => console.log(value)}
          />
          <SubTitleGroup>
            <span className="info primary">10/14(월) ~ 10/20(일)</span>
          </SubTitleGroup>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={equipSetupScheduleMangement}
        rowData={setupEquipRowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={true}
        initialized={onInitialized}
        height={400}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
        onChangeCheckedItem={() => console.log('click')}
      />
    </CustomDialog>
  );
};

export default SetupResultRegistPopup;
