/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';

import {
  UtMatrixStandardEquipment,
  UtMatrixStandardEquipmentCondition,
} from 'models/ut/UtMatrixStandardEquipment';
import { useMessageBar } from '../../../hooks/useMessageBar';
import { ManagementMode } from '../../../models/common/Common';
import { Button } from '@mui/material';
import { IconButton } from '../../../components/buttons/IconSVG';
import { CrudCode } from '../../../models/common/Edit';
import StandardEquipmentPopup from '../popup/StandardEquipmentPopup';
import { checkAvailableRequest } from '../../../apis/ut/UtMatrixLibrary';

interface Props {
  mode: ManagementMode;
  rowData?: UtMatrixStandardEquipment[];
  isReadOnly?: boolean;
  onRefresh: () => void;
  onChange: (masterList: UtMatrixStandardEquipment[]) => void;
  onClickDetail: (target: UtMatrixStandardEquipment) => void;
  gridHeight: number;
}

interface TargetCondition {
  row: number;
  col: number;
}

const EditUtMatrixLibraryMasterGrid = (props: Props) => {
  const { mode, onRefresh, onChange, onClickDetail, isReadOnly = false, gridHeight = 570 } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [rowData, setRowData] = useState<UtMatrixStandardEquipment[]>(props?.rowData || []);
  const [selectedRow, setSelectedRow] = useState<UtMatrixStandardEquipment[]>();

  const [isOpenStandardEquipmentMultiModal, setOpenStandardEquipmentMultiModal] =
    useState<boolean>(false);
  const [isOpenStandardEquipmentModal, setOpenStandardEquipmentModal] = useState<boolean>(false);
  const [isOpenEquipmentGroupPopup, setOpenEquipmentGroupPopup] = useState<boolean>(false);
  const [targetCondition, setTargetCondition] = useState<TargetCondition>();

  const [code, setCode] = useState<any>();
  const isCreate = useMemo(() => ManagementMode.CREATE === mode && !isReadOnly, [mode, isReadOnly]);

  useEffect(() => {
    setRowData(props?.rowData || []);
  }, [props?.rowData]);

  const handleAddMultiRow = () => {
    setOpenStandardEquipmentMultiModal(true);
  };

  const handleAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
    } as UtMatrixStandardEquipment;
    const rows = [newRow, ...rowData];
    setRowData(rows);
    onChange(rows);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);

    if ((selectedRows || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }
    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
    onChange(filteredData);
    onClickDetail(filteredData[0] || []);
  };

  const layoutDefinition = [
    {
      binding: 'stndEqpId',
      header: String(t('ut.label.표준설비코드', '표준설비코드')),
      align: 'center',
      width: 270,
      isReadOnly: true,
      cssClass: isCreate ? 'WijmoFind' : '',
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        ${isCreate ? '<Button />' : ''} 
      `,
    },
    {
      binding: 'prdnProcCd',
      header: String(t('ut.label.공정코드', '공정코드')),
      align: 'center',
      width: 140,
      visible: false,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      align: 'center',
      width: 170,
    },
    {
      binding: 'eqclId',
      header: String(t('ut.label.설비군코드', '설비군코드')),
      align: 'center',
      visible: false,
    },
    {
      binding: 'eqclIdNm',
      header: String(t('ut.grid.설비군', '설비군')),
      align: 'center',
      width: 170,
    },
    {
      binding: 'dtalProcCd',
      header: String(t('ut.label.세부공정코드', '세부공정코드')),
      align: 'center',
      visible: false,
    },
    {
      binding: 'dtalProcCdNm',
      header: String(t('ut.grid.세부공정', '세부공정')),
      align: 'center',
      width: 170,
    },
    // {
    //   header: String(t('ut.grid.상세', '상세')),
    //   binding: 'detailNo',
    //   width: 70,
    //   isReadOnly: true,
    //   cssClass: 'WijmoPlay',
    //   cellTemplate: (params) => `<Button />`,
    // },
    {
      header: String(t('ut.grid.Main', 'Main')),
      binding: 'eqpMainNm',
      width: 280,
      align: 'center',
    },
    {
      binding: String(t('eqpMchNm')),
      header: String(t('ut.grid.Machine', 'Machine')),
      align: 'left',
      width: 280,
    },
    {
      binding: 'libCnt',
      header: String(t('ut.grid.Library 수', 'Library 수')),
      width: 100,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        // 상세보기
        if ('stndEqpId' === binding) {
          // 신규인 경우에만 설비코드 변경 가능
          if (ManagementMode.CREATE === mode) {
            setOpenStandardEquipmentModal(true);
            setTargetCondition({
              row: ht.row,
              col: ht.col,
            });
          }
        } else {
          setSelectedRow(item);
          onClickDetail(item);
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.표준설비코드 List', '표준설비코드 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {isCreate && (
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="plus"
              onClick={handleAddMultiRow}
              disableRipple
            >
              {t('com.button.표준설비코드', '표준설비코드')}
            </Button>
            <Button css={IconButton.button} className="plus" onClick={handleAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="minus" onClick={handleDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
            <Button css={IconButton.button} className="refresh" onClick={onRefresh} disableRipple>
              {t('com.button.새로고침', '새로고침')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData || []}
        isFilter={false}
        isReadOnly={true}
        height={gridHeight}
        rowSelection={'single'}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setSelectedRow(items)}
      />

      {isOpenStandardEquipmentMultiModal && (
        <StandardEquipmentPopup
          open={isOpenStandardEquipmentMultiModal}
          close={() => setOpenStandardEquipmentMultiModal(false)}
          onCallback={(target) => {
            if (target) {
              const masterList = target;

              const targetIdSet = new Set(masterList.map((item) => item.stndEqpId));
              const validIds = rowData.some((item) => targetIdSet.has(item.stndEqpId));

              if (validIds) {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'ut.msg.설비코드는 중복 선택할 수 없습니다.',
                    '설비코드는 중복 선택할 수 없습니다.'
                  ),
                });
                return false;
              }

              checkAvailableRequest(mode, masterList).then((res) => {
                if (res.successOrNot === 'Y' && res.data) {
                  const newRows = masterList.map((item) => ({
                    ...item,
                    crudKey: CrudCode.CREATE,
                  }));

                  const filteredEmptyRows = rowData.filter(
                    (row) => row.stndEqpId && row.stndEqpId !== ''
                  );

                  const rows = [...newRows, ...filteredEmptyRows];
                  setRowData(rows);
                  onChange(rows);
                } else {
                  openMessageBar({
                    type: 'error',
                    content:
                      res.data && typeof res.data === 'string'
                        ? res.data
                        : t(
                            'ut.msg.처리 중 오류가 발생하였습니다.',
                            '처리 중 오류가 발생하였습니다..'
                          ),
                  });
                  return false;
                }
              });
            }
          }}
          singleSelect={false}
        />
      )}
      {isOpenStandardEquipmentModal && (
        <StandardEquipmentPopup
          open={isOpenStandardEquipmentModal}
          close={() => setOpenStandardEquipmentModal(false)}
          onCallback={(target) => {
            if (typeof targetCondition?.row === 'number') {
              const masterList = [{ ...target }];

              const targetIdSet = new Set(masterList.map((item) => item.stndEqpId));
              const validIds = rowData.some((item) => targetIdSet.has(item.stndEqpId));
              if (validIds) {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'ut.msg.설비코드는 중복 선택할 수 없습니다.',
                    '설비코드는 중복 선택할 수 없습니다.'
                  ),
                });
                return false;
              }

              checkAvailableRequest(mode, masterList).then((res) => {
                if (res.successOrNot === 'Y' && res.data) {
                  const selectedRow = gridRef.current.rows[targetCondition?.row].dataItem;

                  const stndEqpId = (target as UtMatrixStandardEquipment).stndEqpId;
                  gridRef.current.setCellData(
                    targetCondition?.row,
                    targetCondition?.col,
                    stndEqpId
                  );

                  selectedRow.prdnProcCd = target.prdnProcCd;
                  selectedRow.prdnProcCdNm = target.prdnProcCdNm;
                  selectedRow.eqclId = target.eqclId;
                  selectedRow.eqclIdNm = target.eqclIdNm;
                  selectedRow.dtalProcCd = target.dtalProcCd;
                  selectedRow.dtalProcCdNm = target.dtalProcCdNm;
                  selectedRow.eqpMainId = target.eqpMainId;
                  selectedRow.eqpMainNm = target.eqpMainNm;
                  selectedRow.eqpMchId = target.eqpMchId;
                  selectedRow.eqpMchNm = target.eqpMchNm;
                  selectedRow.libCnt = target.libraryQty;
                  gridRef.current?.refresh();
                } else {
                  openMessageBar({
                    type: 'error',
                    content:
                      res.data && typeof res.data === 'string'
                        ? res.data
                        : t('ut.msg.수정 요청할 수 없습니다.', '수정 요청할 수 없습니다.'),
                  });
                }
              });
            }
          }}
          singleSelect={true}
        />
      )}
    </>
  );
};

export default EditUtMatrixLibraryMasterGrid;
