import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { SetupDashboard } from 'models/pjm/SetupDashboard';
import { SearchParam } from 'models/pjm/SetupManager';
import { callApi } from 'utils/ApiUtil';

export const getSetupDashboardList = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupDashboardList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<SetupDashboard[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupDashboard[];
};
