/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { MenuContext } from 'App';
import { getRoute } from 'routers/Router';
import { ContentLayout, cs } from 'components/layouts/ContentLayout';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import PageHeaderLayout from 'components/layouts/PageHeaderLayout';
import { SideMenuBar } from 'components/layouts/menu/SideMenuBar';
import StacksMenuBar from 'components/layouts/menu/StacksMenuBar';
import { SVGIcon } from 'components/buttons/IconSVG';
import { CommonText, CommonBG, InfoText, InfoBG } from 'ui/theme/Color';
import { Menu, MenuContextType, MenuEnum } from 'models/admin/Menu';
import MainPage from 'pages/main/MainPage';
import NotFoundPage from 'pages/common/NotFoundPage';
import useSessionStore from 'stores/useSessionStore';
import { MessageBar } from 'components/modals/common/MessageBar';
import { hasMenuAuth } from '../../apis/Session';
import { callApi } from '../../utils/ApiUtil';

interface MainLayoutProps {
  children: ReactNode;
  showMenu: boolean;
}

export const MainLayout = ({ children, showMenu }: MainLayoutProps) => {
  const { t } = useTranslation();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { userId, menus } = useSessionStore();
  const { pathname, state } = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const getComponent = (mnuUrl: string) => {
    const route = getRoute((mnuUrl || '').startsWith('/') ? mnuUrl : `/${mnuUrl}`);
    return route?.[0]?.element ?? null;
  };

  useEffect(() => {
    const mnuUrl = pathname.startsWith('/') ? pathname.replace('/', '') : pathname;
    const idx = menuContext.stacksMenu.findIndex((o) => o.mnuUrl === mnuUrl);
    // 이미 열려있는 메뉴인 경우
    if (idx > -1) {
      menuContext.handleMenuChange({
        ...menuContext,
        currentMenu: menuContext.stacksMenu[idx],
      });
      return;
    }

    // 등록된 메뉴인 경우
    const menu = menus.find((item) => item.mnuUrl === mnuUrl) as Menu;
    if (menu) {
      menuContext.handleMenuChange({
        ...menuContext,
        currentMenu: menu,
      });
      return;
    }
    let upprMnuId = '';
    if (state?.upprMnuUrl) {
      const upprMnuUrl = state.upprMnuUrl.startsWith('/')
        ? state.upprMnuUrl.replace('/', '')
        : state.upprMnuUrl;
      const upprMnu = menus.find((item) => item.mnuUrl === upprMnuUrl) as Menu;
      if (upprMnu) {
        upprMnuId = upprMnu.mnuId || '';
      }
    }
    // 협력업체 사용자 가입 요청 화면
    if (mnuUrl == 'system/user-regist') {
      const registMenu = {
        mnuId: state?.mnuId || mnuUrl,
        mnuUrl: state?.mnuUrl || mnuUrl,
        mnuNm: t('mp.label.협력업체 사용자 가입 요청', '협력업체 사용자 가입 요청'),
        upprMnuId: upprMnuId,
        relateMnuId: upprMnuId,
      } as Menu;
      menuContext.handleMenuChange({
        ...menuContext,
        currentMenu: registMenu,
      });
      return;
    }
    // 기타 화면
    const etcMenu = {
      mnuId: state?.mnuId || mnuUrl,
      mnuUrl: state?.mnuUrl || mnuUrl,
      mnuNm: state?.mnuNm || 'ETC',
      upprMnuId: upprMnuId,
      relateMnuId: upprMnuId,
    } as Menu;
    menuContext.handleMenuChange({
      ...menuContext,
      currentMenu: etcMenu,
    });
  }, [pathname]);

  const [isShow, setShow] = useState<boolean>(false);

  useEffect(() => {
    const init = async function init() {
      setShow(false);
    };
    init();

    const menu = menuContext.currentMenu;

    if (menu?.mnuUrl?.startsWith('system') || menu?.mnuUrl?.startsWith('/system')) {
      const load = async function load() {
        const auth = await hasMenuAuth(menu?.mnuUrl);
        setShow(!!auth);
      };
      load();
    } else {
      setShow(true);
    }

    if (menu) {
      const stacksMenu = menuContext.stacksMenu.some((o) => o.mnuId === menu.mnuId)
        ? menuContext.stacksMenu
        : [...menuContext.stacksMenu, menu];

      const idx = stacksMenu.findIndex((o) => o.mnuId === menu.mnuId);
      const tabIndex = idx < 0 ? stacksMenu.length - 1 : idx;

      const newParentMenus: Menu[] = [];
      let cur = menus.find((item) => item.mnuId === menu.upprMnuId) as Menu;
      if (cur) {
        while (cur.mnuId !== '000000') {
          newParentMenus.unshift(cur);
          cur = menus.find((item) => item.mnuId === cur.upprMnuId) as Menu;
          if (!cur) break;
        }
      }
      setCurrentTabIndex(tabIndex);
      menuContext.handleMenuChange({
        ...menuContext,
        stacksMenu: stacksMenu,
        tabIndex: tabIndex,
        selectedHeaderMenu: newParentMenus.length
          ? newParentMenus[0].mnuId
          : menuContext.selectedHeaderMenu,
      });
    }
  }, [menuContext.currentMenu]);

  return (
    <div css={st.root}>
      {showMenu && <SideMenuBar />}
      <ContentLayout>
        <StacksMenuBar
          currentTabIndex={currentTabIndex}
          onChange={(idx) => setCurrentTabIndex(idx)}
        />
        {menuContext.stacksMenu.map((it: any, idx) => (
          <TabPanel value={currentTabIndex} key={it.mnuId} forceRender={false} index={idx}>
            {isShow && (
              <>
                <PageHeaderLayout showMenu={showMenu} />
                <ContainerLayout>
                  {userId ? (
                    it.mnuId === MenuEnum.root ? (
                      <>
                        <MainPage />
                      </>
                    ) : (
                      getComponent(it.mnuUrl) || <NotFoundPage />
                    )
                  ) : (
                    children
                  )}
                </ContainerLayout>
              </>
            )}
          </TabPanel>
        ))}
      </ContentLayout>
      {/* st.root 안에 있어야 함 (css) */}
      <MessageBar />
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} css={cs.root}>
      <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

export const JoinLayout = styled.div`
  width: 800px;
  margin: 10px auto 0;
  height: calc(100vh - 80px);
`;

const st = {
  root: css`
    margin-top: 52px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 52px) !important;
    overflow: hidden;
    .MuiSnackbar-root {
      transition: ease-in-out 0.7s;
      bottom: 60px;
      .MuiPaper-root {
        min-width: 0px;
        max-width: 600px;
        font-size: 13px;
        line-height: 1.5;
        color: ${CommonText.Basic};
        font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
        border-radius: 3px;
        padding: 10px 16px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.17), 0 4px 5px rgba(0, 0, 0, 0.11);
        .MuiSnackbarContent-message {
          padding: 0px;
        }
      }
      &.default {
        .MuiPaper-root {
          background: ${CommonBG.Inverse};
          color: ${CommonText.Inverse};
        }
      }
      &.confirm {
        .MuiPaper-root {
          background: ${InfoBG.Confirmed};
          padding-left: 42px;
          position: relative;
          &:before {
            content: '';
            clear: both;
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            left: 16px;
            z-index: 99;
            background: ${InfoText.Confirmed};
            -webkit-mask: ${SVGIcon.IcoCofirm};
            mask-image: ${SVGIcon.IcoCofirm};
          }
        }
      }
      &.warning {
        .MuiPaper-root {
          background: ${InfoBG.WarningMajor};
          padding-left: 42px;
          position: relative;
          &:before {
            content: '';
            clear: both;
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            left: 16px;
            z-index: 99;
            background: ${InfoText.WarningMajor};
            -webkit-mask: ${SVGIcon.IcoCofirm};
            mask-image: ${SVGIcon.IcoCofirm};
          }
        }
      }
      &.error {
        .MuiPaper-root {
          background: ${InfoBG.Error};
          padding-left: 42px;
          position: relative;
          &:before {
            content: '';
            clear: both;
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            left: 16px;
            z-index: 99;
            background: ${InfoText.Error};
            -webkit-mask: ${SVGIcon.IcoCofirm};
            mask-image: ${SVGIcon.IcoCofirm};
          }
        }
      }
    }
  `,
};
