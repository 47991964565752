/** @jsxImportSource @emotion/react */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Tab, Tabs } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { tabs } from 'components/layouts/Tab';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getDtalProcCodes } from 'apis/ut/UtMatrixManager';
import CustomGrid from 'components/grids/CustomGrid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import FacilityInspectionHistoryPopup from './FacilityInspectionHistoryPopup';
import PropTypes from 'prop-types';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import { FileTypeName } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { GatingRegist } from 'models/gtng/GatingRegist';
import DetailSummaryContent from 'pages/ut/review/DetailSummaryContent';
import ElectricSummaryContent from 'pages/ut/review/ElectricSummaryContent';
import MachineSummaryContent from 'pages/ut/review/MachineSummaryContent';
import ScheduleRegistGrid from '../regist/ScheduleRegistGrid';
import ManagerRegistGrid from '../regist/ManagerRegistGrid';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};

const FacilityScheduleRegistPopup = ({ open, close, condition, onCallback }: Props) => {
  const [flexRef, setFlexRef] = useState<any>();

  const machineRef = useRef<any>();
  const elecRef = useRef<any>();

  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});

  const [mpItem] = useState<any>('');

  const [machineRowData, setMachineRowData] = useState<any>();
  const [electricRowData, setElectricRowData] = useState<any>();

  const [isOpenEquipInspectionHistoryModal, setOpenEquipInspectionHistoryModal] =
    useState<boolean>(false);

  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [askPurpCode, setAskPurpCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);

  const [checkedItems, setCheckedItems] = useState<any>();

  const initSearchParam = {
    bildPlntCds: [],
    askPurpCds: [],
    prdnProcCds: [],
    dtalProcCds: [],
  };
  const [searchParam, setSearchParam] = useState<any>(initSearchParam);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const tabMenus = useMemo(() => {
    return [
      { id: 'MACH', name: t('pjm.label.일정등록', '일정등록') },
      { id: 'ELTR', name: t('pjm.label.담당자등록', '담당자등록') },
    ];
  }, []);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const factoryCode = await getCommonCodeNames('FACTORY_CODE');
    const elmCopCode = await getCommonCodeNames('ELM_COP_CD');
    // const askPurpCode = await getCommonCodeNames('ASK_PURP_CD');
    // const utmWkProgStatCode = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
    // const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');

    const factoryMerged = [...bildPlntCode, ...factoryCode, ...elmCopCode];
    setBildPlntCode(factoryMerged);
    // setAskPurpCode(askPurpCode);
    setPrdnProcCode(prdnProcCd);
    // setDtalProcCode(dtalProcCd);
    // setUtmWkProgStatCode(utmWkProgStatCode);
  };

  const handleSave = () => {
    console.log('저장');
  };

  const handleOnChange = (name, value) => {
    console.log('변경');
    setSearchParam({ ...searchParam, [name]: value });
    if (name === 'prdnProcCd') {
      getDtalProcCode(value);
      setSearchParam({ ...searchParam, [name]: value, dtalProcCd: '' });
    }
  };

  const getDtalProcCode = async (value) => {
    if (value == '') {
      setDtalProcCode([]);
    } else {
      const dtalProcCode: Code[] = await getDtalProcCodes({
        optValCtn1: value,
        cmnGrCd: 'DTAL_PROC_CD',
      });
      setDtalProcCode(dtalProcCode);
    }
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_대상설비_List', 'Setup_대상설비_List')));
  };

  return (
    <CustomDialog
      title={t('com.label.Setup 일정등록(상세)', 'Setup 일정등록(상세)')}
      size="xl"
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup Master', 'Setup Master')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.공장', '공장')}</span>
              </TableCell>
              <TableCell>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.구분', '구분')}</span>
              </TableCell>
              <TableCell>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.Setup ID', 'Setup ID')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="itemEngNm"
                  placeholder={String(
                    t('pjm.label.Setup ID를 입력해 주세요.', 'Setup ID를 입력해 주세요.')
                  )}
                  value={mpItem?.itemEngNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.itemEngNm}
                  msgError={String(
                    t('pjm.label.Setup ID를 입력해 주세요.', 'Setup ID를 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.Setup Name', 'Setup Name')}</span>
              </TableCell>
              <TableCell colSpan={5}>
                <CustomInputWithSearch
                  name="itemEngNm"
                  placeholder={String(
                    t('pjm.label.Item 영문명을 입력해 주세요.', 'Item 영문명을 입력해 주세요.')
                  )}
                  value={mpItem?.itemEngNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.itemEngNm}
                  msgError={String(
                    t('pjm.label.Item 영문명을 입력해 주세요.', 'Item 영문명을 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div css={tabs.wrap} style={{ marginTop: '24px' }}>
        <Tabs
          value={currentTabIndex}
          onChange={(e, newValue) => setCurrentTabIndex(newValue)}
          css={tabs.tab}
        >
          {tabMenus.map((o, idx) => (
            <Tab
              key={o.id}
              label={o.name}
              id={`ut-tab-${idx}`}
              aria-controls={`ut-tabpanel-${idx}`}
            />
          ))}
        </Tabs>
      </div>
      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentTabIndex === 0 ? (
          <ScheduleRegistGrid ref={machineRef} machineRowData={machineRowData} />
        ) : currentTabIndex === 1 ? (
          <ManagerRegistGrid ref={elecRef} electricRowData={electricRowData} />
        ) : (
          ''
        )}
      </TabPanel>
    </CustomDialog>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`ut-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default FacilityScheduleRegistPopup;
