import {
  ExcelRequestError,
  ExcelRequestErrorCondition,
  ExcelValidResult,
  InfoForExcelConvertion,
} from 'models/common/Excel';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { PaginationResponse } from 'models/common/Pagination';
import { callApi } from 'utils/ApiUtil';
import axios, { AxiosResponseHeaders } from 'axios';
import _ from 'lodash';
import { UtMatrixRegistSearchCondition } from '../../models/ut/UtMatrix';
export const uploadExcelTemplates = async (
  files: any,
  templateType: string,
  atchFileTpCd: string,
  tableName: string,
  id?: string,
  aprReqId?: string,
  aprTplId?: string,
  aprPvnDdlnCd?: string,
  aprReqTitNm?: string,
  trnmBdyCtn?: string
) => {
  if (files && files.length > 0) {
    try {
      const request: CommonRequest = {
        method: Method.POST,
        url: `/v1/excel/upload`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        serviceName: ServiceName.ELM_BE,
        bodyParams: {
          templateType: templateType,
          atchFileTpCd: atchFileTpCd,
          tableName: tableName,
          file: files[0],
          id: id,
          aprReqId: aprReqId,
          aprTplId: aprTplId,
          aprPvnDdlnCd: aprPvnDdlnCd,
          aprReqTitNm: aprReqTitNm,
          trnmBdyCtn: trnmBdyCtn,
        },
        timeout: 180000,
      };

      const response: CommonResponse<any> = await callApi(request);
      if (response.statusCode === 'TIMEOUT') {
        response.data = '데이터 처리가 지연되고 있습니다. 잠시 후 재시도해주시기 바랍니다. ';
      }
      return response;
    } catch (error) {
      console.error('파일 업로드 실패 : ', error);
    }
  }
};

export const uploadExcelUtMatrixTemplates = async (
  files: any,
  templateType: string,
  atchFileTpCd: string,
  tableName: string,
  id?: string,
  detailId?: string,
  aprReqId?: string,
  aprTplId?: string,
  aprPvnDdlnCd?: string,
  aprReqTitNm?: string,
  trnmBdyCtn?: string
) => {
  if (files && files.length > 0) {
    try {
      const request: CommonRequest = {
        method: Method.POST,
        url: `/v1/excel/upload`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        serviceName: ServiceName.ELM_BE,
        bodyParams: {
          templateType: templateType,
          atchFileTpCd: atchFileTpCd,
          tableName: tableName,
          file: files[0],
          id: id,
          detailId: detailId,
          aprReqId: aprReqId,
          aprTplId: aprTplId,
          aprPvnDdlnCd: aprPvnDdlnCd,
          aprReqTitNm: aprReqTitNm,
          trnmBdyCtn: trnmBdyCtn,
        },
        timeout: 180000,
      };

      const response: CommonResponse<any> = await callApi(request);
      if (response.statusCode === 'TIMEOUT') {
        response.data = '데이터 처리가 지연되고 있습니다. 잠시 후 재시도해주시기 바랍니다. ';
      }
      return response;
    } catch (error) {
      console.error('파일 업로드 실패 : ', error);
    }
  }
};

export const downloadExcelUtMatrixTemplatesPost = async (
  atchFileTpCd: string, // 파일유형코드
  condition: UtMatrixRegistSearchCondition
) => {
  try {
    const request: CommonRequest = {
      method: Method.POST,
      url: `/v1/excel/download/template`,
      serviceName: ServiceName.ELM_BE,
      responseType: 'blob',
      bodyParams: {
        atchFileTpCd: atchFileTpCd,
        param1: condition.utmId,
        param2: condition.prdnProcCd,
        param3: condition.eqclId,
        param4: condition.prdnProcCdNm,
        param5: condition.eqclNm,
      },
    };
    const response: CommonResponse<Blob> = await callApi(request);

    if (response?.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      console.log('headers: ', response?.headers);
      const headers = response?.headers as AxiosResponseHeaders;
      const disposition = (headers['content-disposition'] as string) || '';

      if (!_.isEmpty(disposition)) {
        link.href = url;
        link.setAttribute(
          'download',
          String(decodeURIComponent(disposition?.split('filename=')[1]))
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        alert('Failed to download.');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const downloadExcelTemplatesPost = async (
  atchFileTpCd: string, // 파일유형코드
  id: string, // 조회 key
  ids?: string[] // 조회 key가 다건인경우
) => {
  try {
    const request: CommonRequest = {
      method: Method.POST,
      url: `/v1/excel/download/template`,
      serviceName: ServiceName.ELM_BE,
      responseType: 'blob',
      bodyParams: { atchFileTpCd: atchFileTpCd, id: id, ids: ids },
    };
    const response: CommonResponse<Blob> = await callApi(request);

    if (response?.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      console.log('headers: ', response?.headers);
      const headers = response?.headers as AxiosResponseHeaders;
      const disposition = (headers['content-disposition'] as string) || '';

      if (!_.isEmpty(disposition)) {
        link.href = url;
        link.setAttribute(
          'download',
          String(decodeURIComponent(disposition?.split('filename=')[1]))
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        alert('Failed to download.');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const downloadExcelTemplates = async (
  atchFileTpCd: string, // 파일유형코드
  id: string // 조회 key
) => {
  try {
    const request: CommonRequest = {
      method: Method.GET,
      url: `/v1/excel/download/template?atchFileTpCd=${atchFileTpCd}&id=${id}`,
      serviceName: ServiceName.ELM_BE,
      responseType: 'blob',
    };
    const response: CommonResponse<Blob> = await callApi(request);

    if (response?.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      console.log('response?.headers: ', response?.headers);
      const headers = response?.headers as AxiosResponseHeaders;
      const disposition = (headers['content-disposition'] as string) || '';

      if (!_.isEmpty(disposition)) {
        link.href = url;
        link.setAttribute(
          'download',
          String(decodeURIComponent(disposition?.split('filename=')[1]))
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        alert('Failed to download.');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getExcelSample = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excel/download`,
    serviceName: ServiceName.ELM_BE,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) {
    downloadFile(response.data, 'Sample.xlsx');
  }
};

export const convertToExcel = async (infoForExcelConvertion: InfoForExcelConvertion) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/excel/convert`,
    serviceName: ServiceName.ELM_BE,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    bodyParams: infoForExcelConvertion,
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) {
    downloadFile(response.data, `${infoForExcelConvertion.fileName}`);
  }
};

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 데이터 검증 오류 정보 조회
 * @param condition
 */
export const findExcelValidDetail = async (condition?: ExcelRequestErrorCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excel/upload/valid/detail`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<ExcelValidResult> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as ExcelValidResult;
};

/**
 * 데이터 검증 오류 상세 목록 조회
 * @param condition
 */
export const findExcelValidList = async (condition?: ExcelRequestErrorCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excel/upload/valid/list`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<PaginationResponse<ExcelRequestError>> = await callApi(request);
  return (
    response.successOrNot === 'Y' ? response.data : null
  ) as PaginationResponse<ExcelRequestError>;
};

/**
 * 데이터 검증 오류 상세 목록 다운로드
 * @param condition
 */
export const downloadExcelValidList = async (
  condition: ExcelDownloadRequest<ExcelRequestErrorCondition>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/excel/upload/valid/list/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};
