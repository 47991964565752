import styled from '@emotion/styled';

export const ContentFlex = styled.div`
  display: flex;
  justify-content: space-between;

  .section {
    &.width10p {
      width: 10%;
    }
    &.width20p {
      width: 20%;
    }
    &.width25p {
      width: 25%;
    }
    &.width30p {
      width: 30%;
    }
    &.width33p {
      width: 33%;
    }
    &.width35p {
      width: 35%;
    }
    &.width38p {
      width: 38%;
    }
    &.width40p {
      width: 40%;
    }
    &.width45p {
      width: 45%;
    }
    &.width50p {
      width: 50%;
    }
    &.width60p {
      width: 60%;
    }
    &.width65p {
      width: 65%;
    }
    &.width70p {
      width: 70%;
    }
    &.width75p {
      width: 75%;
    }
    &.width80p {
      width: 80%;
    }
    &.width100p {
      width: 100%;
    }
  }

  &.marginT40 {
    margin-top: 40px;
  }

  .section + .section {
    margin-left: 20px;
    &:not(.noSearchBox) {
      margin-top: 0;
    }
  }
`;
