import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { SetupScheduleList } from 'models/pjm/SetupScheduleList';
import { SearchParam, UtMatrixDetailList, UtMatrixMasterList } from 'models/ut/UtMatrixList';
import { callApi } from 'utils/ApiUtil';

export const getSetupScheuleList = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupScheuleList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<SetupScheduleList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupScheduleList[];
};

export const getUtMatrixDetailList = async (utmId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixDetailList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ utmId: utmId }),
  };
  const response: CommonResponse<UtMatrixDetailList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetailList[];
};

export const deleteUtMatrixMaster = async (deleteData: UtMatrixMasterList[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/deleteUtMatrixMaster`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: deleteData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const saveUtMatrixWrtUser = async (saveData: UtMatrixDetailList[]) => {
  console.log('saveData', saveData);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/saveUtMatrixWrtUser`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};
