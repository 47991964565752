/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findElectricSummary } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from 'utils/SessionUtil';
import { getExcelFileName } from '../../../utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { ComboBox } from 'components/selects/ComboBox';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import { FileTypeName } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { GatingRegist } from 'models/gtng/GatingRegist';
import { useMessageBar } from 'hooks/useMessageBar';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import Datepicker, { DateRangeType } from 'react-tailwindcss-datepicker';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import { InputBox, SearchBox, SearchCols } from 'components/layouts/SearchBox';

const ScheduleRegistGrid = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();

  const [searchParam, setSearchParam] = useState<any>();

  const [scheduleRegistRowData, setScheduleRegistRowData] = useState<any>();

  useImperativeHandle(ref, () => ({
    searchElectricSummary: (condition) => {
      searchElectricSummary(condition);
    },
  }));

  const searchElectricSummary = (condition) => {
    findElectricSummary(condition).then((result) => {
      setScheduleRegistRowData(result);
    });
  };

  const ScheduleRegistLayoutdefinition = [
    {
      binding: 'bildPlntCdNm',
      header: String(t('pjm.label.공정', '공정')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.공정군', '공정군')),
      isReadOnly: true,
      align: 'center',
    },
    {
      header: String(t('pjm.label.Line', 'Line')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.A/C', 'A/C')),
          width: 80,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.호기', '호기')),
          width: 80,
        },
      ],
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.Machine', 'Machine')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.Build', 'Build')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.PO발행', 'PO발행')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.시운전자재', '시운전자재')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.FAT', 'FAT')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.설비반입', '설비반입')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.설치', '설치')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.I/O Check', 'I/O Check')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.Wet Run', 'Wet Run')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.Quality Check', 'Quality Check')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.SAT', 'SAT')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.샘플생산', '샘플생산')),
      isReadOnly: true,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setHitTest(ht);
      }
    });
  };

  const handleSubmit = () => {
    props?.onSubmit?.();
  };

  //검토진행
  const handleSubmitReview = () => {
    props?.onSubmit?.();
  };

  //검증
  const handleModify = () => {
    props?.onModify?.();
  };
  //보류 링크
  const handleRequestLinkHold = () => {
    props?.onLinkHold?.();
  };
  //재작성 링크
  const handleRequestLinkRewrite = () => {
    props?.onLinkRewrite?.();
  };

  const handleApproval = () => {
    props?.onAppr?.();
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix 검토 전기 요약';
    book.saveAsync(
      getExcelFileName(t('ut.label.Ut Matrix 검토 전기 요약', 'Ut Matrix 검토 전기 요약'))
    );
  };

  const btnAddRow = () => {
    console.log('추가');
  };

  const btnAllRegist = () => {
    console.log('등록');
  };

  const btnDelRow = () => {
    console.log('삭제');
  };

  const btnRefresh = () => {
    console.log('새로고침');
  };

  const btnCopyRow = useCallback(() => {
    if (flexRef == null) {
      openMessageBar({
        type: 'error',
        content: `${t(
          'gtng.msg.Gating을 선택 후 복사하시기 바랍니다.',
          'Gating을 선택 후 복사하시기 바랍니다.'
        )}`,
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: `${t(
          'gtng.msg.Gating을 선택 후 복사하시기 바랍니다.',
          'Gating을 선택 후 복사하시기 바랍니다.'
        )}`,
      });
      return false;
    } else {
      const selectNo = 0; //단일 선택 복사 시 0번째
      const copiedData = isSelected[selectNo].dataItem;
      const newRow = {
        crudKey: CrudCode.CREATE,
        gatingName: copiedData?.gatingName,
        gatingTypeCode: copiedData?.gatingTypeCode,
        gatingStepCode: copiedData?.gatingStepCode,
        taskLeaderUserId: copiedData?.taskLeaderUserId,
        taskLeaderUserName: copiedData?.taskLeaderUserName,
        goodBasisScore: copiedData?.goodBasisScore,
        levelScore: copiedData?.levelScore,
        productTypeCode: copiedData?.productTypeCode,
        gatingContIds: copiedData?.gatingContIds,
        gatingContNames: copiedData?.gatingContNames,
        gatingContTlIds: copiedData?.gatingContTlIds,
        gatingContTlNames: copiedData?.gatingContTlNames,
        gatingEquipIds: copiedData?.gatingEquipIds,
        gatingEquipNames: copiedData?.gatingEquipNames,
        gatingEqpContData: copiedData?.gatingEqpContData,
        startDate: copiedData?.startDate,
        endDate: copiedData?.endDate,
      } as GatingRegist;
      setScheduleRegistRowData([newRow, ...scheduleRegistRowData]);
    }
  }, [scheduleRegistRowData]);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.일정등록', '일정등록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(setScheduleRegistRowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <SubTitleGroup>
            <span className="total">{t('com.label.단계', '단계')}</span>
          </SubTitleGroup>
          <ComboBox
            options={[]}
            placeholder={String(t('com.label.선택', '선택'))}
            defaultValue={''}
            onChange={(value) => console.log(value)}
          />
          <CustomDatepicker
            isRange={false} // 기간선택인 경우 생략가능
            value={{
              startDate: searchParam?.startDate || null,
              endDate: searchParam?.endDate || null,
            }}
            onChange={(newValue) => {
              setSearchParam((prev) => ({
                ...prev,
                startDate: ((newValue as DateRangeType)?.startDate as string) || prev?.startDate,
                endDate: ((newValue as DateRangeType)?.endDate as string) || prev?.endDate,
              }));
            }}
          />
          <Button css={IconButton.button} className="setting" onClick={btnAllRegist} disableRipple>
            {t('com.button.일괄적용', '일괄적용')}
          </Button>
          <Button
            css={IconButton.button}
            className="write"
            disableRipple
            onClick={(e) => console.log('엑셀신규')}
          >
            {t('com.button.엑셀 신규', '엑셀신규')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              // const id = ([])
              // .reduce(
              //   (acc, cur) => [...acc, `${cur.eqpParaNo}||${cur.versionNo}` as string],
              //   [] as string[]
              // )
              // .join();
              // downloadExcelTemplatesPost(FileTypeName.TPL_IP_LIST, id);
            }}
            disableRipple
          >
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="copyRow" onClick={btnCopyRow} disableRipple>
            {t('com.button.행복사', '행복사')}
          </Button>
          <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
            {t('com.button.행삭제', '행삭제')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnRefresh} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={ScheduleRegistLayoutdefinition}
        rowData={scheduleRegistRowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={true}
        initialized={onInitialized}
        height={400}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
        onChangeCheckedItem={() => console.log('click')}
      />
    </>
  );
};

export default React.forwardRef(ScheduleRegistGrid);
