/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { Button } from '@mui/material';
import { addClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomGrid from 'components/grids/CustomGrid';
import UserModal from 'components/modals/common/UserModal';
import DepartmentType1Modal from 'components/modals/common/DepartmentType1Modal';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import {
  IdleAssetReviewManager,
  IdleAssetReviewManagerCondition,
} from 'models/asst/IdleAssetReview';
import { Employee } from 'models/admin/Employee';
import { useMessageBar } from 'hooks/useMessageBar';
import { useCommonModal } from 'hooks/useCommonModal';
import { findIdleAssetReviewManager, saveIdleAssetReviewManager } from 'apis/asst/IdleAssetReview';
import _ from 'lodash';
import { getExcelFileName } from '../../utils/ExcelUtil';

const IdlAssetReviewManagerPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const gridRef = useRef<any>();
  const [condition, setCondition] = useState<IdleAssetReviewManagerCondition>({});
  const [rowData, setRowData] = useState<IdleAssetReviewManager[]>([]);
  const [code, setCode] = useState<any>();
  const [defaultCopCd, setDefaultCopCd] = useState<string>();
  const [userModalCondition, setUserModalCondition] = useState<any>({});
  const [deptModalCondition, setDeptModalCondition] = useState<any>({});
  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);
  const [isOpenDeptModal, setOpenDeptModal] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    //담당자유형코드(통보자/검토자)
    const mgrTpCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'MGR_TP_CD',
    });
    const useYn = [
      { cmnCd: 'Y', cmnCdNm: String(t('com.label.사용', '사용')) },
      { cmnCd: 'N', cmnCdNm: String(t('com.label.미사용', '미사용')) },
    ];

    setCode({
      elmCopCd: elmCopCd,
      mgrTpCd: mgrTpCd,
      useYn: useYn,
    });
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        align: 'center',
        width: 40,
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 43,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'copCd',
        header: String(t('asst.label.법인', '법인')),
        align: 'center',
        width: 150,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.elmCopCd || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'mgrTpCd',
        header: String(t('asst.label.구분', '구분')),
        align: 'center',
        width: 150,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.mgrTpCd || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'deptNm',
        header: String(t('asst.label.부서', '부서')),
        align: 'left',
        minWidth: 200,
        width: '*',
        isReadOnly: true,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => `
            <span>${params.value || ''}</span> 
            <Button /> 
        `,
      },
      {
        binding: 'wrtUserNms',
        header: String(t('asst.label.담당자', '담당자')),
        align: 'left',
        minWidth: 200,
        width: '*',
        isReadOnly: true,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => `
            <span>${params.value || ''}</span> 
            <Button /> 
        `,
      },
      {
        binding: 'useYn',
        header: String(t('asst.label.사용여부', '사용여부')),
        align: 'center',
        width: 120,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.useYn || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('asst.label.등록자', '등록자')),
        align: 'center',
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('asst.label.등록일자', '등록일자')),
        align: 'center',
        width: 120,
        isReadOnly: true,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('asst.label.수정자', '수정자')),
        align: 'center',
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('asst.label.수정일자', '수정일자')),
        align: 'center',
        width: 120,
        isReadOnly: true,
      },
      {
        binding: 'rvwDeptId',
        visible: false,
      },
      {
        binding: 'deptCd',
        visible: false,
      },
      {
        binding: 'wrtUserIds',
        visible: false,
      },
    ];
  }, [code]);

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;

        // 부서 클릭
        if ('deptNm' === binding) {
          setDeptModalCondition({
            target: {
              row: ht.row,
              col: ht.col,
            },
          });
          setOpenDeptModal(true);
        }
        // 담당자 클릭
        else if ('wrtUserNms' === binding) {
          // 부서가 선택되지 않은 경우
          if (!item.deptCd) {
            openMessageBar({
              type: 'error',
              content: t('asst.msg.부서를 선택해주세요.', '부서를 선택해주세요.'),
            });
            return;
          }
          setUserModalCondition({
            wrtUserIds: item.wrtUserIds,
            deptNm: item.deptNm,
            deptCd: item.deptCd,
            target: {
              row: ht.row,
              col: ht.col,
            },
          });
          setOpenUserModal(true);
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목 (법인, 구분, 부서, 담당자, 사용여부)
      if (['copCd', 'mgrTpCd', 'deptNm', 'wrtUserNms', 'useYn'].includes(binding)) {
        addClass(cell, 'dot');
      }
    } else if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 부서, 담당자 editable css 적용
      if ('deptNm' === binding || 'wrtUserNms' === binding) {
        cell.ariaReadOnly = false;
      }
    }
  });

  const handleSearch = () => {
    findIdleAssetReviewManager(condition).then((result) => setRowData(result));
  };

  const handleConditionChange = (name, value) => {
    setCondition({ ...condition, [name]: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('asst.label.법인별 담당자', '법인별 담당자');
    book.saveAsync(getExcelFileName(t('asst.label.법인별 담당자', '법인별 담당자')));
  };

  const handleAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      copCd: defaultCopCd || '',
    } as IdleAssetReviewManager;
    const rows = [newRow, ...rowData];
    setRowData(rows);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  };

  const validate = () => {
    const valid = (rowData || [])
      .filter((o) => o.crudKey !== CrudCode.DELETE)
      .map((o, index) => {
        if (_.isEmpty(o.copCd)) {
          return `${index + 1} : ${t('asst.msg.법인을 선택해 주세요.', `법인을 선택해 주세요.`)}\n`;
        }
        if (_.isEmpty(o.mgrTpCd)) {
          return `${index + 1} : ${t('asst.msg.구분을 선택해 주세요.', `구분을 선택해 주세요.`)}\n`;
        }
        if (_.isEmpty(o.deptCd)) {
          return `${index + 1} : ${t('asst.msg.부서를 선택해 주세요.', `부서를 선택해 주세요.`)}\n`;
        }
        if (_.isEmpty(o.wrtUserIds)) {
          return `${index + 1} : ${t(
            'asst.msg.담당자를 선택해 주세요.',
            `담당자를 선택해 주세요.`
          )}\n`;
        }
        if (_.isEmpty(o.useYn)) {
          return `${index + 1} : ${t(
            'asst.msg.사용여부를 선택해 주세요.',
            `사용여부를 선택해 주세요.`
          )}\n`;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      openMessageBar({ type: 'error', content: valid.toString() });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validate()) return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        // 변경된 데이터
        const params = (rowData || []).filter((o) => !!o.crudKey);
        saveIdleAssetReviewManager(params)
          .then((response) => {
            handleSearch();
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>
                  <span>{String(t('asst.label.법인', '법인'))}</span>
                </label>
                <ComboBox
                  placeholder={String(t('asst.msg.법인', '법인'))}
                  options={code?.elmCopCd || []}
                  defaultValue={condition?.copCd}
                  onChange={(value) => handleConditionChange('copCd', value)}
                />
                {/*<label>*/}
                {/*  <span>{String(t('asst.label.구분', '구분'))}</span>*/}
                {/*</label>*/}
                {/*<ComboBox*/}
                {/*  placeholder={String(t('asst.msg.구분', '구분'))}*/}
                {/*  options={code?.idlWktTpCd || []}*/}
                {/*  defaultValue={condition?.wktTpCd}*/}
                {/*  onChange={(value) => handleConditionChange('wktTpCd', value)}*/}
                {/*/>*/}
              </SearchCols>
              <SearchCols>
                <label>
                  <span>{String(t('asst.label.부서', '부서'))}</span>
                </label>
                <CustomInputWithSearch
                  name="deptNm"
                  placeholder={String(t('asst.msg.부서를 입력해 주세요.', '부서를 입력해 주세요.'))}
                  value={condition?.deptNm}
                  onChange={(e) => {
                    handleConditionChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>
                  <span>{String(t('asst.label.담당자', '담당자'))}</span>
                </label>
                <CustomInputWithSearch
                  name="wrtUserNm"
                  placeholder={String(
                    t('asst.msg.담당자를 입력해 주세요.', '담당자를 입력해 주세요.')
                  )}
                  value={condition?.wrtUserNm}
                  onChange={(e) => {
                    handleConditionChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <ComboBox
                  placeholder={String(t('asst.msg.전체', '전체'))}
                  options={code?.useYn}
                  defaultValue={condition?.useYn}
                  onChange={(value) => handleConditionChange('useYn', value)}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons setReset={setCondition} onSearch={() => handleSearch()} />
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.법인별 담당자', '법인별 담당자')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <label>{String(t('asst.label.법인', '법인'))}</label>
          <ComboBox
            placeholder={String(t('asst.msg.선택', '선택'))}
            options={code?.elmCopCd || []}
            defaultValue={defaultCopCd}
            onChange={(value) => setDefaultCopCd(value)}
          />
          <Button
            css={IconButton.button}
            className="addRow"
            onClick={() => handleAddRow()}
            disableRipple
          >
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button
            css={IconButton.button}
            className="delRow"
            onClick={() => handleDelRow()}
            disableRipple
          >
            {t('com.button.행삭제', '행삭제')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch()}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={510}
        allowPinning={false}
        initialized={onInitialized}
      />
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="save" onClick={() => handleSave()}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>

      {isOpenDeptModal && (
        <DepartmentType1Modal
          open={isOpenDeptModal}
          close={() => setOpenDeptModal(false)}
          save={(result) => {
            const target = deptModalCondition.target;
            if (target && target?.row > -1) {
              const item = gridRef.current?.rows[target.row].dataItem;
              item.deptCd = result.deptCd;
              item.deptNm = result.deptNm;
              // 부서 정보가 변경된 경우 담당자 정보 초기화
              item.wrtUserIds = '';
              item.wrtUserNms = '';
              if (item.crudKey !== CrudCode.CREATE) {
                item.crudKey = CrudCode.UPDATE;
              }
              gridRef.current?.collectionView.refresh();
            }
          }}
        />
      )}

      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('asst.label.담당자 조회', '담당자 조회')}
          defaultUserId={userModalCondition?.wrtUserIds}
          condition={{
            deptNm: userModalCondition?.deptNm || '',
            deptCd: userModalCondition?.deptCd || '',
          }}
          singleSelect={false}
          isFixedDept={true}
          onCallback={(result) => {
            const target = userModalCondition.target;
            if (target && target?.row > -1) {
              const users = (result || []) as Employee[];
              const item = gridRef.current?.rows[target.row].dataItem;
              item.wrtUserNms = users.map((o) => o.empNm).join(', ');
              item.wrtUserIds = users.map((o) => o.userId).join(', ');
              if (item.crudKey !== CrudCode.CREATE) {
                item.crudKey = CrudCode.UPDATE;
              }
              gridRef.current?.collectionView.refresh();
            }
          }}
        />
      )}
    </>
  );
};

export default IdlAssetReviewManagerPage;
