/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@mui/material';
import { Checkbox } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
  StatusDot,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import {
  ProcessBarWrap,
  ProcessStepBox,
  StepBox,
  ProcessStep,
  ProcessNum,
  ProcessText,
  SubStep,
  ProgressBar,
} from 'components/process/Process';
import Datepicker from 'react-tailwindcss-datepicker';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { SearchParam, UtMatrixDetailList, UtMatrixMasterList } from 'models/ut/UtMatrixList';
import {
  deleteUtMatrixMaster,
  getUtMatrixDetailList,
  getUtMatrixMasterList,
  saveUtMatrixWrtUser,
} from 'apis/ut/UtMatrixList';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { useLocation, useNavigate } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import moment from 'moment';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { st } from 'components/inputs/CustomInput';
import { Diversity1 } from '@mui/icons-material';
import { findProcess, findProcessBar } from 'apis/ut/UtMatrixRegist';
import { resolve } from 'dns';
import { UtMatrixDetail } from 'models/ut/UtMatrix';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';
import { ManagementMode } from 'models/common/Common';
import { findUtMatrixReferrer } from 'apis/ut/UtMatrixReview';
import EquipmentParameterRequestPage from 'pages/ip/base/EquipmentParameterRequestPage';
import ParameterManagementReqReadModal from 'components/modals/ip/ParameterManagementReqReadModal';
import { getUtMatrixSummaryList } from 'apis/ut/UtMatrixSummary';
import { UtMatrixSummaryList } from 'models/ut/UtMatrixSummary';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import { CrudCode } from 'models/common/Edit';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import useEvent from 'react-use-event-hook';
import { getDtalProcCodes, saveUtMatrixManagerList } from 'apis/ut/UtMatrixManager';
import { getSetupScheuleList } from 'apis/pjm/SetupScheduleList';
import { SetupScheduleList } from 'models/pjm/SetupScheduleList';

interface PageLocationState {
  utmNo?: string;
}

const SetupScheduleListPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const userSession = useSessionStore();

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [masterRowData, setMasterRowData] = useState<SetupScheduleList[]>([]);
  const [detailRowData, setDetailRowData] = useState<SetupScheduleList[]>([]);

  const [utmWkProgInfo, setUtmWkProgInfo] = useState<UtMatrixDetail>();

  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [askPurpCode, setAskPurpCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);
  const [utmWkProgStatCode, setUtmWkProgStatCode] = useState<Code[]>([]);
  const progressStatBarCode = utmWkProgStatCode.filter((item) => item.cmnCd !== 'UTM06');

  const today = moment().format('YYYY-MM-DD');
  const bfTwoMonth = moment().subtract(2, 'months');
  const startMonth = bfTwoMonth.startOf('month').format('YYYY-MM-DD');

  const [progressNum, setProgressNum] = useState<number>(0);
  const [userNm, setUserNm] = useState<string>('');
  const [userIds, setUserIds] = useState<string>('');
  const initSearchParam = {
    bildPlntCds: [],
    askPurpCds: [],
    prdnProcCds: [],
    dtalProcCds: [],
    utmWkProgStatCds: [],
    curYn: 'N',
    startDate: startMonth,
    endDate: today,
  };
  const [searchParam, setSearchParam] = useState<any>(initSearchParam);
  const { state } = useLocation();

  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);

  const [mode, setMode] = useState<string>('');
  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);
  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [isOpenRequestReadModal, setOpenRequestReadModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);

  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [referrerRowData, setReferrerRowData] = useState<Employee[]>([]);
  const [summaryRowData, setSummaryRowData] = useState<UtMatrixSummaryList[]>([]);

  const [rowItem, setRowItem] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  // ddd
  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
    btnSearch();
  }, []);

  useEffect(() => {
    setSearchParam((prev) => ({
      ...prev,
      utmNo: locationState?.utmNo || prev?.utmNo || '',
      bildPlntCds: locationState?.utmNo ? [] : prev?.bildPlntCds || [],
      askPurpCds: locationState?.utmNo ? [] : prev?.askPurpCds || [],
      utmWkProgStatCds: locationState?.utmNo ? [] : prev?.utmWkProgStatCds || [],
    }));
    if (locationState?.utmNo) {
      btnSearch();
    }
  }, [locationState?.utmNo]);

  useEffect(() => {
    if (state?.utmNo) {
      setLocationState(state);
    }
  }, [state]);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const factoryCode = await getCommonCodeNames('FACTORY_CODE');
    const elmCopCode = await getCommonCodeNames('ELM_COP_CD');
    // const askPurpCode = await getCommonCodeNames('ASK_PURP_CD');
    // const utmWkProgStatCode = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
    // const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');

    const factoryMerged = [...bildPlntCode, ...factoryCode, ...elmCopCode];
    setBildPlntCode(factoryMerged);
    // setAskPurpCode(askPurpCode);
    setPrdnProcCode(prdnProcCd);
    // setDtalProcCode(dtalProcCd);
    // setUtmWkProgStatCode(utmWkProgStatCode);
  };

  const fnSearchUtmNm = (params: any) => {
    const utmLink = params.utmLink;
    const utmId = params.utmId;
    const bildPlntCd = params.bildPlntCd;
    switch (utmLink) {
      case 'R-DRAFT':
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'DRAFT',
            },
          },
        });
        break;
      case 'R-READ':
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'READ',
            },
          },
        });
        break;
      case 'W-WRITE':
        navigate('/ut/regist-management/regist', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, openMode: 'WRITE' },
        });
        break;
      case 'W-READ':
        navigate('/ut/regist-management/regist', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, openMode: 'READ' },
        });
        break;
      case 'I-UT_MGR':
        navigate('/ut/regist-management/review', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, mode: 'UT_MGR' },
        });
        break;
      case 'I-READ':
        navigate('/ut/regist-management/review', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, mode: 'READ' },
        });
        break;
    }
  };

  const fnSearchDtalProc = (params: any) => {
    const utmLink = params.utmLink;
    const utmId = params.utmId;
    switch (utmLink) {
      case 'W-WRITE':
        navigate('/ut/regist-management/regist', {
          state: {
            utmId: utmId,
            planProcId: params.planProcId,
            openMode: 'WRITE',
          },
        });
        break;
      case 'W-READ':
        navigate('/ut/regist-management/regist', {
          state: {
            utmId: utmId,
            planProcId: params.planProcId,
            openMode: 'READ',
          },
        });
        break;
      case 'I-UT_MGR':
        navigate('/ut/regist-management/review', {
          state: { utmId: utmId, bildPlntCd: params.bildPlntCd, mode: 'UT_MGR' },
        });
        break;
    }
  };

  const masterLayoutDefinition = [
    {
      binding: 'bildPlntCdNm',
      header: String(t('ut.label.법인', '법인')),
      isReadOnly: true,
      width: 150,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('ut.label.구분', '구분')),
      isReadOnly: true,
      width: 80,
      align: 'center',
    },
    {
      binding: 'utmNo',
      header: String(t('ut.label.프로젝트 ID', '프로젝트 ID')),
      width: 150,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'utmNm',
      header: String(t('ut.label.프로젝트 명', '프로젝트 명')),
      width: '*',
      minWidth: 200,
      isReadOnly: true,
      align: 'left',
      cellTemplate: CellMaker.makeLink({
        text: '${item.utmNm}',
        click: (e, ctx) => {
          fnSearchUtmNm(ctx.item);
        },
      }),
    },
    {
      binding: 'utmWkProgStatCdNm',
      header: String(t('ut.label.Factory', 'Factory')),
      width: 100,
      isReadOnly: true,
      align: 'center',
      cssClass: 'WijmoTag',
      cellTemplate: (item) => {
        if (item.item.utmWkProgStatCd === 'UTM01') {
          return `<span class="yellow">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM02') {
          return `<span class="blue">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM03') {
          return `<span class="green">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM04') {
          return `<span class="purple">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM05') {
          return `<span class="grey">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM06') {
          return `<span class="red">${item.value}</span>`;
        }
      },
    },
    {
      binding: 'verNo',
      header: String(t('com.label.공정', '공정')),
      width: 80,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'smitCloDtm',
      header: String(t('pjm.label.세부공정', '세부공정')),
      width: 100,
      isReadOnly: true,
      align: 'center',
    },
    {
      header: String(t('pjm.label.Line', 'Line')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.A/C', 'A/C')),
          width: 80,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.호기', '호기')),
          width: 80,
        },
      ],
    },
    {
      binding: 'smitCloDtm',
      header: String(t('pjm.label.Machine', 'Machine')),
      width: 100,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'smitCloDtm',
      header: String(t('pjm.label.Build', 'Build')),
      width: 100,
      isReadOnly: true,
      align: 'center',
    },
    {
      header: String(t('pjm.label.투자품의', '투자품의')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.PO발행', 'PO발행')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.시운전자재', '시운전자재')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.FAT', 'FAT')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.FOB', 'FOB')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.설비반입', '설비반입')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.설치', '설치')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.I/O Check', 'I/O Check')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.Wet Run', 'Wet Run')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.Quality Check', 'Quality Check')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.SAT', 'SAT')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
    {
      header: String(t('pjm.label.샘플생산', '샘플생산')),
      align: 'center',
      columns: [
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.현재일정', '현재일정')),
          width: 105,
        },
        {
          binding: 'gatingType1Value',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 105,
        },
        {
          binding: 'gatingType2Value',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 105,
        },
      ],
    },
  ];

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setHitTest(ht);
        switch (e.target.id) {
          case 'btnAprReq':
            setRequestModalCondition({
              pageId: ApproveRequestPageType.UT_REVIEW_REQ,
              mode: ManagementMode.READ,
              requestMasterList: item,
            });
            setOpenRequestReadModal(true);
            break;
        }
      }
    });
  };

  const onDetailInitialized = (grid) => {
    gridRef.current = grid;
    grid.hostElement.addEventListener('click', (e) => {
      if (e.target instanceof HTMLButtonElement) {
        const len = grid.rows.length;
        if (len == 0) return;
        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;
        if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setFlexItem(item);
        switch (e.target.id) {
          case 'wrtUserId':
            setOpenUserMultiModal(true);
            setUserIds(item.wrtUserId);
            setMode('gridWrtUserId');
            break;
          case 'readWrtUserId':
            setOpenUserReadModal(true);
            setUserIds(item.wrtUserId);
            break;
        }
      }
    });
  };

  const onDetailItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      if (binding === 'wrtUserId') {
        const splitWrtUserId = item.wrtUserId.split(',');
        const isReadOnly = true;
        if (
          (hasAuth === true ||
            splitWrtUserId.includes(userSession.userId) ||
            rowItem.dataInsUserId === userSession.userId) &&
          !['UTP05', 'UTP06'].includes(item.utmWrtProcProgStatCd)
        ) {
          cell.ariaReadOnly = !isReadOnly;
        } else if (item.crudKey === CrudCode.UPDATE) {
          cell.ariaReadOnly = !isReadOnly;
        } else {
          cell.ariaReadOnly = isReadOnly;
        }
      }
    }
  });

  const callbackUserData = (callbackData) => {
    if (mode === 'gridWrtUserId') {
      flexItem['wrtUserId'] = callbackData.userId;
      flexItem['wrtUserNm'] = callbackData.empNm;
      if (flexItem['crudKey'] !== CrudCode.CREATE && flexItem['crudKey'] !== CrudCode.DELETE) {
        flexItem['crudKey'] = CrudCode.UPDATE;
      }
      gridRef.current.refresh();
    }
  };

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    // readonly 설정
    if (['wrtUserId'].includes(binding)) {
      e.cancel = true;
    }
  });

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
    if (name === 'prdnProcCd') {
      getDtalProcCode(value);
      setSearchParam({ ...searchParam, [name]: value, dtalProcCd: '' });
    }
  };

  const getDtalProcCode = async (value) => {
    if (value == '') {
      setDtalProcCode([]);
    } else {
      const dtalProcCode: Code[] = await getDtalProcCodes({
        optValCtn1: value,
        cmnGrCd: 'DTAL_PROC_CD',
      });
      setDtalProcCode(dtalProcCode);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnMasterExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_List', 'Setup_List')));
  };

  const btnSearch = () => {
    getSetupScheuleList(searchParam).then((result: SetupScheduleList[]) => {
      if (result !== null) {
        setDetailRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setUserNm('');
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
  };

  const btnShowResult = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    const valid = selectedData
      .map((item) => {
        if (!['UTM03', 'UTM04', 'UTM05'].includes(item.utmWkProgStatCd)) {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.결과비교를 할 수 없는 상태입니다.',
              '결과비교를 할 수 없는 상태입니다.'
            ),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    navigate('/ut/ut-matrix-summary', { state: { utmData: selectedData } });
  };

  const getAprReqInfo = (item) => {
    if (item.length > 0) {
      findUtMatrixReferrer(item[0].utmId).then((result) => {
        setReferrerRowData(result);
      });

      getUtMatrixSummaryList(item[0].utmId).then((result) => {
        setSummaryRowData(result);
      });
    }
  };

  const btnAprRequest = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    const aprRequest = selectedData[0].aprRequest;
    if (aprRequest) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.결재 진행중입니다.', '결재 진행중입니다.'),
      });
      return false;
    }

    const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
    if (utmWkProgStatCd !== 'UTM04') {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.결재요청을 할 수 없는 상태입니다.',
          '결재요청을 할 수 없는 상태입니다.'
        ),
      });
      return false;
    }

    setRequestModalCondition({
      pageId: ApproveRequestPageType.UT_REVIEW_REQ,
      mode: ManagementMode.READ,
      requestMasterList: selectedData[0],
    });
    setOpenRequestModal(true);
  };

  const btnHold = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    const valid = selectedData
      .map((item) => {
        if (!['UTM02', 'UTM03', 'UTM04'].includes(item.utmWkProgStatCd)) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.보류 할 수 없는 상태입니다.', '보류 할 수 없는 상태입니다.'),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    const aprRequest = selectedData[0].aprRequest;
    if (aprRequest) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.결재 진행중인 상태에서는 보류를 할 수 없습니다.',
          '결재 진행중인 상태에서는 보류를 할 수 없습니다.'
        ),
      });
      return false;
    }

    const searchParam = {
      utmId: selectedData[0].utmId,
      mode: 'HOLD',
    };
    navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
  };

  const btnNew = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    if (isSelected.length < 1) {
      const searchParam = {
        mode: 'NEW',
      };
      // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
      navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
    } else {
      const selectedData = isSelected.map(({ dataItem }) => dataItem);
      const askPurpCd = selectedData[0].askPurpCd;
      const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
      if (askPurpCd === 'DESIGN') {
        if (utmWkProgStatCd === 'UTM01') {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.신규 진행할 수 없는 상태입니다.',
              '신규 진행할 수 없는 상태입니다.'
            ),
          });
          return false;
        } else {
          const searchParam = {
            utmData: selectedData[0],
            utmId: selectedData[0].utmId,
            mode: 'COPY_NEW',
          };
          // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
          navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
        }
      }
      if (askPurpCd === 'INVEST') {
        if (utmWkProgStatCd === 'UTM01') {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.신규 진행할 수 없는 상태입니다.',
              '신규 진행할 수 없는 상태입니다.'
            ),
          });
          return false;
        } else if (utmWkProgStatCd === 'UTM05') {
          const searchParam = {
            utmId: selectedData[0].utmId,
            mode: 'DESIGN_NEW',
          };
          // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
          navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
        } else {
          openCommonModal({
            modalType: 'confirm',
            content: t(
              'com.label.투자용 UT Matrix가 확정완료가 아닙니다. 설계용 UT Matrix를 작성 요청 하시겠습니까?',
              '투자용 UT Matrix가 확정완료가 아닙니다. 설계용 UT Matrix를 작성 요청 하시겠습니까?'
            ),
            yesCallback: () => {
              const searchParam = {
                utmData: selectedData[0],
                utmId: selectedData[0].utmId,
                mode: 'DESIGN_NEW',
              };
              // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
              navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
            },
          });
        }
      }
    }
  };

  const btnReWriteRequest = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
    const aprRequest = selectedData[0].aprRequest;
    const curYn = selectedData[0].curYn;

    //결재 진행중인 상태면 재요청 불가능
    if (aprRequest) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.결재 진행중인 상태에서는 재작성요청을 할 수 없습니다.',
          '결재 진행중인 상태에서는 재작성요청을 할 수 없습니다.'
        ),
      });
      return false;
    }
    //현재상태가 N이면 재요청 불가능
    if (curYn === 'N') {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.재작성요청을 할 수 없는 상태입니다.',
          '재작성요청을 할 수 없는 상태입니다.'
        ),
      });
      return false;
    }
    //권한이 ADM, UT_MANAGER은 Ut Matrix 상태(작성완료, 검토중, 확정완료) 재요청 가능
    if (hasAuth) {
      if (utmWkProgStatCd === 'UTM03' || utmWkProgStatCd === 'UTM04') {
        const searchParam = {
          mode: 'REWRITE',
          utmId: selectedData[0].utmId,
        };
        // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
        navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
      } else if (utmWkProgStatCd === 'UTM05') {
        const searchParam = {
          mode: 'VERSION_UP',
          utmId: selectedData[0].utmId,
        };
        navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'ut.label.재작성요청을 할 수 없는 상태입니다.',
            '재작성요청을 할 수 없는 상태입니다.'
          ),
        });
        return false;
      }
    } else if (!hasAuth) {
      //권한이 CMN은 Ut Matrix 상태(확정완료) 재요청 가능
      const wrtUserIds = selectedData[0].wrtUserId.split(',');
      if (utmWkProgStatCd === 'UTM05') {
        if (wrtUserIds.includes(userSession.userId)) {
          const searchParam = {
            mode: 'VERSION_UP',
            utmId: selectedData[0].utmId,
          };
          navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
        } else {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.재작성요청을 할 수 없는 상태입니다.',
              '재작성요청을 할 수 없는 상태입니다.'
            ),
          });
          return false;
        }
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'ut.label.재작성요청을 할 수 없는 상태입니다.',
            '재작성요청을 할 수 없는 상태입니다.'
          ),
        });
        return false;
      }
    }
  };

  const btnDelete = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return;
    }

    const valid = isSelected
      .map((item) => {
        if (item.dataItem.utmWkProgStatCd !== 'UTM01') {
          openMessageBar({
            type: 'error',
            content: t('ut.label.요청전 상태만 삭제가능합니다.', '요청전 상태만 삭제가능합니다.'),
          });
          return false;
        }
        if (item.dataItem.dataInsUserId !== userSession.userId) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.삭제 권한이 없습니다.', '삭제 권한이 없습니다.'),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    const deleteData = isSelected.map(({ dataItem }) => dataItem);
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.삭제하시겠습니까?', '삭제하시겠습니까?'),
      yesCallback: async () => {
        deleteUtMatrixMaster(deleteData).then((result) => {
          if (result) {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
            });
            btnSearch();
          } else {
            openMessageBar({
              type: 'error',
              content: t('com.label.삭제 중 오류가 발생했습니다.', '삭제 중 오류가 발생했습니다.'),
            });
          }
        });
      },
    });
  };

  useEffect(() => {
    for (let i = 0; i < progressStatBarCode.length; i++) {
      if (progressStatBarCode[i].cmnCd === rowItem?.utmWkProgStatCd) {
        setProgressNum(i);
      }
    }
  }, [rowItem]);

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('pjm.label.법인', '법인'))}</label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.구분', '구분'))}</label>
                <MultiComboBox
                  id="askPurpCd"
                  options={askPurpCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.askPurpCds || []}
                  onChange={(value) => {
                    handleOnChange('askPurpCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.프로젝트 ID', '프로젝트 ID')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNo"
                  placeholder={String(
                    t('ut.label.Set ID를 입력해 주세요.', 'Set ID를 입력해 주세요.')
                  )}
                  value={searchParam?.utmNo}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.프로젝트 명', '프로젝트 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNm"
                  placeholder={String(
                    t('ut.label.Setup 명을 입력해 주세요.', 'Setup 명을 입력해 주세요.')
                  )}
                  value={searchParam?.utmNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourColThree">
              <SearchCols>
                <label>{String(t('pjm.label.Factory', 'Factory'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCd"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.Line', 'Line'))}</label>
                <MultiComboBox
                  id="utmWkProgStatCd"
                  options={utmWkProgStatCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.utmWkProgStatCds}
                  onChange={(value) => {
                    handleOnChange('utmWkProgStatCd', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup List', 'Setup List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{masterRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="regist" onClick={btnShowResult} disableRipple>
            {t('com.button.일정등록', '일정등록')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnMasterExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnShowResult} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={masterLayoutDefinition}
        rowData={masterRowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={true}
        initialized={onInitialized}
        height={400}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
        onChangeCheckedItem={getAprReqInfo}
      />
      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          singleSelect={true}
          onCallback={(result) => {
            const users = (result || []) as Employee;
            setSearchParam({ ...searchParam, dataInsUserId: users.userId });
            setUserNm(users.empNm || '');
          }}
          condition={{ empNm: userNm }}
        />
      )}
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={ApproveRequestPageType.UT_REVIEW_REQ}
          aprReqId={summaryRowData[0].aprReqId}
          condition={{
            utmId: requestModalCondition.requestMasterList.utmId,
            // requestMaster: summaryRowData[0],
            mode: requestModalCondition.requestMasterList.mode,
            approvalInfr: referrerRowData,
          }}
        />
      )}
      {isOpenRequestReadModal && (
        <ParameterManagementReqReadModal
          open={isOpenRequestReadModal}
          close={() => setOpenRequestReadModal(false)}
          aprReqId={requestModalCondition.requestMasterList.aprRequest}
        />
      )}
      {isOpenUserMultiModal && (
        <UserMultiModal
          open={isOpenUserMultiModal}
          close={() => setOpenUserMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={userIds}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            if (callbackData.userId === '') {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackUserData(callbackData);
          }}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
    </ContainerLayout>
  );
};

export default SetupScheduleListPage;
