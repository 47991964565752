/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Select } from '@mui/material';
import { Checkbox } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { toggleClass } from '@grapecity/wijmo';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import Datepicker from 'react-tailwindcss-datepicker';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { useNavigate } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import { addClass } from '@grapecity/wijmo';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import moment from 'moment';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { CrudCode } from 'models/common/Edit';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import useEvent from 'react-use-event-hook';
import {
  getDtalProcCodes,
  getUtMatrixManagerMasterList,
  saveUtMatrixManagerList,
} from 'apis/ut/UtMatrixManager';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import { d } from 'msw/lib/glossary-de6278a9';
import { Create } from '@mui/icons-material';
import _ from 'lodash';
import {
  checkDuplicationStandardCode,
  deleteUtMatrixStandardCodeList,
  getUtMatrixStandardCodeList,
  saveUtMatrixStandardCodeList,
} from 'apis/ut/UtMatrixStandartdCode';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import StandardEquipmentPopup from './popup/StandardEquipmentPopup';
import { SearchParam, UtMatrixHookupTag } from 'models/ut/UtMatrixHookupTag';
import { getUtMatrixHookupTagList } from 'apis/ut/UtMatrixHookupTag';

const UtMatrixHookupTagPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const userSession = useSessionStore();

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [rowData, setRowData] = useState<UtMatrixHookupTag[]>([]);

  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);
  // const [electrodeTypeCode, setElectorodeTypeCode] = useState<Code[]>([]);
  const [dtalProcListCode, setDtalProcListCode] = useState<Code[]>([]);

  const initSearchParam = {
    utmNo: '',
    utmNm: '',
    prdnProcCds: [],
    eqclIds: [],
    dtalProcCds: [],
    eqpMchId: '',
  };

  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [delData, setDelData] = useState<UtMatrixHookupTag[]>([]);

  const isReadOnly = useMemo(() => {
    return hasAuth;
  }, [hasAuth]);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  const initCondition = async () => {
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclIdCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'EQCL_ID',
    });
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');
    setPrdnProcCode(prdnProcCd);
    setEqclIdCode(eqclIdCd);
    setDtalProcCode(dtalProcCd);
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'utmNo',
        header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
        align: 'center',
        width: 140,
      },
      {
        binding: 'utmNm',
        header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
        width: 200,
      },
      {
        header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
        columns: [
          {
            binding: 'tagDivsCdNm',
            header: String(t('ut.label.기계/전기', '기계/전기')),
            width: 120,
            align: 'center',
          },
          {
            binding: 'tagId',
            header: String(t('ut.label.TAG ID', 'TAG ID')),
            width: 250,
          },
        ],
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'eqclIdNm',
        header: String(t('ut.label.설비군', '설비군')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 110,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 110,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 150,
        align: 'left',
      },
      {
        binding: 'bizAreaNm',
        header: String(t('ut.label.Area', 'Area')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'bldgFloCdNm',
        header: String(t('ut.label.Floor', 'Floor')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 80,
        align: 'right',
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 90,
        align: 'right',
      },
      {
        header: String(t('ut.label.수량', '수량')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.기계', '기계')),
            width: 80,
            align: 'right',
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.전기', '전기')),
            width: 80,
            align: 'right',
          },
        ],
      },
      {
        binding: 'vltgNvl',
        header: String(t('ut.label.Voltage', 'Voltage')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'elpwPhasCd',
        header: String(t('ut.label.Phase', 'Phase')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'ctwTpCd',
        header: String(t('ut.label.Wire', 'Wire')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'od1EcNvl',
        header: String(t('ut.label.Current(입력값)', 'Current(입력값)')),
        width: 140,
        align: 'right',
      },
      {
        binding: 'brkeCapa',
        header: String(t('ut.label.Breaker(MCCB)', 'Breaker(MCCB)')),
        width: 140,
        align: 'right',
      },
      {
        binding: 'elpwCapa',
        header: String(t('ut.label.Electricity Capacity', 'Electricity Capacity')),
        width: 150,
        align: 'right',
      },
      {
        binding: 'cdaDevcCnctTpCd',
        header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'cdaPntCnt',
        header: String(t('ut.label.CDA(Qty)', 'CDA(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'nitrPntCtn',
        header: String(t('ut.label.N2(Qty)', 'N2(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'iwPntCnt',
        header: String(t('ut.label.IW(Qty)', 'IW(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'frwtPntCnt',
        header: String(t('ut.label.FW(Qty)', 'FW(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'coolPntCnt',
        header: String(t('ut.label.RCW(Qty)', 'RCW(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'wwPntCnt',
        header: String(t('ut.label.WW(Qty)', 'WW(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'stemPntCnt',
        header: String(t('ut.label.Steam(Qty)', 'Steam(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'lngPntCnt',
        header: String(t('ut.label.NG(Qty)', 'NG(Qty)')),
        width: 100,
        align: 'right',
      },
      {
        binding: 'insdExhaPntCnt',
        header: String(t('ut.label.Retrun Air(Qty)', 'Retrun Air(Qty)')),
        width: 140,
        align: 'right',
      },
      {
        binding: 'otsdExhaPntCnt',
        header: String(t('ut.label.Exhaust Air(Qty)', 'Exhaust Air(Qty)')),
        width: 140,
        align: 'right',
      },
      {
        binding: 'suarPntCnt',
        header: String(t('ut.label.Supply Air(Qty)', 'Supply Air(Qty)')),
        width: 140,
        align: 'right',
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.등록자', '등록자')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.등록일', '등록일')),
        width: 100,
        align: 'center',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);
    gridRef.current = grid;
  };

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Hookup tagging list';
    book.saveAsync(getExcelFileName(t('ut.label.Hookup Tagging List', 'Hookup Tagging List')));
  };

  const btnSearch = () => {
    getUtMatrixHookupTagList(searchParam).then((result: UtMatrixHookupTag[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
  };

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: 'Y',
    } as UtMatrixHookupTag;
    setRowData([newRow, ...rowData]);
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCode"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  name="eqpMchNm"
                  value={searchParam.eqpMchNm || ''}
                  defaultValue={searchParam.eqpMchNm || ''}
                  placeholder={String(
                    t('com.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourColThree">
              <SearchCols>
                <label>{t('com.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  name="utmNo"
                  value={searchParam.utmNo || ''}
                  defaultValue={searchParam.utmNo || ''}
                  placeholder={String(
                    t(
                      'com.label.UT Matrix 번호를 입력해 주세요.',
                      'UT Matrix 번호를 입력해 주세요.'
                    )
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.UT Matrix 명', 'UT Matrix 명')}</label>
                <CustomInputWithSearch
                  name="utmNm"
                  value={searchParam.utmNm || ''}
                  defaultValue={searchParam.utmNm || ''}
                  placeholder={String(
                    t('com.label.UT Matrinx 명을 입력해 주세요.', 'UT Matrinx 명을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.Hookup Tagging List', 'Hookup Tagging List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        initialized={onInitialized}
        height={520}
        excludeFilter={[]}
        excludePin={[]}
        autoCheck={true}
        isReadOnly={true}
        selectionMode={SelectionMode.Row}
      />
    </ContainerLayout>
  );
};

export default UtMatrixHookupTagPage;
