/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@mui/material';
import { Checkbox } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
  StatusDot,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { useLocation, useNavigate } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import moment from 'moment';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import { ManagementMode } from 'models/common/Common';
import { findUtMatrixReferrer } from 'apis/ut/UtMatrixReview';
import ParameterManagementReqReadModal from 'components/modals/ip/ParameterManagementReqReadModal';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import { CrudCode } from 'models/common/Edit';
import useEvent from 'react-use-event-hook';
import SetupScheduleRegistPopup from './popup/SetupScheduleRegistPopup';
import SetupResultRegistPopup from './popup/SetupResultRegistPopup';
import { getEquGroupParameter } from 'apis/pjm/SetupManager';
import { SearchParam, SetupDashboard } from 'models/pjm/SetupDashboard';
import { getSetupDashboardList } from 'apis/pjm/SetupDashboard';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { SetupDashboardLegendPopup } from './popup/SetupDashboardLegendPopup';

interface PageLocationState {
  utmNo?: string;
}

interface scheduleRegistCondition {
  elmPrjId?: string;
  mode?: string;
}

const SetupDashboardPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const userSession = useSessionStore();

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [rowData, setRowData] = useState<SetupDashboard[]>([]);

  const [copCode, setCopCode] = useState<Code[]>([]);
  const [prdnPldoCode, setPrdnPldoCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);

  const [userIds, setUserIds] = useState<string>('');

  const initSearchParam = {
    copCds: [],
    prdnPldoCds: [],
    prdnProcCds: [],
    eqclIds: [],
    elmPrjId: '',
    elmPrjNm: '',
  };

  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);
  const { state } = useLocation();

  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);

  const [isOpenScheduleRegistModal, setOpenScheduleRegistModal] = useState<boolean>(false);
  const [isOpenResultRegistModal, setOpenResultRegistModal] = useState<boolean>(false);
  const [isOpenlegendPopUp, setOpenlegendPopUp] = useState<boolean>(false);

  const condition = {
    emlPrjId: '',
    mode: 'NEW',
  };

  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [scheduleRegtistCondition, setScheduleRegtistCondition] =
    useState<scheduleRegistCondition>(condition);
  const [referrerRowData, setReferrerRowData] = useState<Employee[]>([]);
  const [rowItem, setRowItem] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
    btnSearch();
  }, []);

  // useEffect(() => {
  //   setSearchParam((prev) => ({
  //     ...prev,
  //     utmNo: locationState?.utmNo || prev?.utmNo || '',
  //     bildPlntCds: locationState?.utmNo ? [] : prev?.bildPlntCds || [],
  //     askPurpCds: locationState?.utmNo ? [] : prev?.askPurpCds || [],
  //     utmWkProgStatCds: locationState?.utmNo ? [] : prev?.utmWkProgStatCds || [],
  //   }));
  //   if (locationState?.utmNo) {
  //     btnSearch();
  //   }
  // }, [locationState?.utmNo]);

  useEffect(() => {
    if (state?.utmNo) {
      setLocationState(state);
    }
  }, [state]);

  const initCondition = async () => {
    const copCode: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE'); // 법인코드
    const prdnProcCode: Code[] = await getCommonCodeNamesCondition({
      optValCtn3: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclCode: Code[] = await getEquGroupParameter();

    setCopCode(copCode);
    setPrdnPldoCode(factoryCode);
    setPrdnProcCode(prdnProcCode);
    setEqclIdCode(eqclCode);

    if (hasRole('ADM') || hasRole('SETUP_MANAGER')) {
      setHasAuth(true);
    }
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'copCd',
        header: String(t('ut.label.법인', '법인')),
        isReadOnly: true,
        width: 120,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.copCd}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        binding: 'prdnProcCd',
        visible: false,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        isReadOnly: true,
        width: 120,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.prdnProcCdNm}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        binding: 'prdnProcCd',
        visible: false,
      },
      {
        binding: 'eqclId',
        header: String(t('ut.label.설비군', '설비군')),
        isReadOnly: true,
        width: 120,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.eqclId}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        header: String(t('pjm.label.Line', 'Line')),
        align: 'center',
        columns: [
          {
            binding: 'eltrTpCdNm',
            header: String(t('pjm.label.A/C', 'A/C')),
            width: 80,
            align: 'center',
          },
          {
            binding: 'eqpLnId',
            header: String(t('pjm.label.호기', '호기')),
            width: 80,
            align: 'center',
            cellTemplate: CellMaker.makeLink({
              text: '${item.eqpLnId}',
              click: (e, ctx) => {
                btnShowChart(ctx.item);
              },
            }),
          },
        ],
      },
      {
        header: String(t('pjm.label.시운전자재', '시운전자재')),
        align: 'center',
        columns: [
          {
            binding: 'atMatStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 80,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.atMatDt),
          },
          {
            binding: 'atMatStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 80,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.atMatCrs === null ? '' : params.item.atMatCrs}</span>`;
            },
          },
          {
            binding: 'atMatDt',
            visible: false,
          },
          {
            binding: 'atMatCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.FAT', 'FAT')),
        align: 'center',
        columns: [
          {
            binding: 'fatStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 80,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.fatDt),
          },
          {
            binding: 'fatStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 80,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.fatCrs === null ? '' : params.item.fatCrs}</span>`;
            },
          },
          {
            binding: 'fatDt',
            visible: false,
          },
          {
            binding: 'fatCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.FOB', 'FOB')),
        align: 'center',
        columns: [
          {
            binding: 'fobStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.fobDt),
          },
          {
            binding: 'fobStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.fobCrs === null ? '' : params.item.fobCrs}</span>`;
            },
          },
          {
            binding: 'fobDt',
            visible: false,
          },
          {
            binding: 'fobCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.설비반입', '설비반입')),
        align: 'center',
        columns: [
          {
            binding: 'eqpCrryStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.eqpCrryDt),
          },
          {
            binding: 'eqpCrryStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            cellTemplate: (params) => {
              return `<span>${
                params.item.eqpCrryCrs === null ? '' : params.item.eqpCrryCrs
              }</span>`;
            },
          },
          {
            binding: 'eqpCrryDt',
            visible: false,
          },
          {
            binding: 'eqpCrryCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.설치', '설치')),
        align: 'center',
        columns: [
          {
            binding: 'istlStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.istlDt),
          },
          {
            binding: 'istlStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.istlCrs === null ? '' : params.item.istlCrs}</span>`;
            },
          },
          {
            binding: 'istlDt',
            visible: false,
          },
          {
            binding: 'istlCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.I/O Check', 'I/O Check')),
        align: 'center',
        columns: [
          {
            binding: 'iocStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.iocDt),
          },
          {
            binding: 'iocStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.iocCrs === null ? '' : params.item.iocCrs}</span>`;
            },
          },
          {
            binding: 'iocDt',
            visible: false,
          },
          {
            binding: 'iocCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.Wet Run', 'Wet Run')),
        align: 'center',
        columns: [
          {
            binding: 'wetRunStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.wetRunDt),
          },
          {
            binding: 'wetRunStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.wetRunCrs === null ? '' : params.item.wetRunCrs}</span>`;
            },
          },
          {
            binding: 'wetRunDt',
            visible: false,
          },
          {
            binding: 'wetRunCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.Quality Check', 'Quality Check')),
        align: 'center',
        columns: [
          {
            binding: 'qcStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.qcDt),
          },
          {
            binding: 'qcStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.qcCrs === null ? '' : params.item.qcCrs}</span>`;
            },
          },
          {
            binding: 'qcDt',
            visible: false,
          },
          {
            binding: 'qcCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.SAT', 'SAT')),
        align: 'center',
        columns: [
          {
            binding: 'satStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.satDt),
          },
          {
            binding: 'satStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.satCrs === null ? '' : params.item.satCrs}</span>`;
            },
          },
          {
            binding: 'satDt',
            visible: false,
          },
          {
            binding: 'satCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.샘플생산', '샘플생산')),
        align: 'center',
        columns: [
          {
            binding: 'smplPrdnStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.smplPrdnDt),
          },
          {
            binding: 'smplPrdnStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${
                params.item.smplPrdnCrs === null ? '' : params.item.smplPrdnCrs
              }</span>`;
            },
          },
          {
            binding: 'smplPrdnDt',
            visible: false,
          },
          {
            binding: 'smplPrdnCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.양산', '양산')),
        align: 'center',
        columns: [
          {
            binding: 'mspdStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.mspdDt),
          },
          {
            binding: 'mspdStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 105,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.mspdCrs === null ? '' : params.item.mspdCrs}</span>`;
            },
          },
          {
            binding: 'mspdDt',
            visible: false,
          },
          {
            binding: 'mspdCrs',
            visible: false,
          },
        ],
      },
      {
        binding: 'elmPrjId',
        header: String(t('ut.label.프로젝트 ID', '프로젝트 ID')),
        isReadOnly: true,
        width: 150,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.elmPrjId}</span>',
          click: (e, ctx) => {
            btnScheduleRegist(ctx.item);
          },
        }),
      },
      {
        binding: 'elmPrjNm',
        header: String(t('ut.label.프로젝트 명', '프로젝트 명')),
        isReadOnly: true,
        width: 200,
        align: 'center',
      },
      {
        binding: 'prdnPldoCdNm',
        header: String(t('ut.label.Factory', 'Factory')),
        isReadOnly: true,
        width: 150,
        align: 'center',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      // grid.startEditing(true);

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLAnchorElement) {
        if (e.target.id === 'btnOpen') {
          setOpenResultRegistModal(true);
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      if (binding.includes('Dt') || binding.includes('Stat')) {
        switch (item[binding]) {
          case 'CLPT':
            addClass(cell, 'clpt');
            break;
          case 'DLY_CPLT':
            addClass(cell, 'dlyClpt');
            break;
          case 'OBSR':
            addClass(cell, 'obsr');
            break;
          case 'DLY':
            addClass(cell, 'dly');
            break;
        }
      }
    }
  });

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnMasterExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_일정_Dashboard', 'Setup_일정_Dashboard')));
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  const btnSearch = () => {
    getSetupDashboardList(searchParam).then((result: SetupDashboard[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
  };

  const btnGridScheduleRegist = (params) => {
    const condition = {
      elmPrjId: params?.elmPrjId,
      mode: 'WRITE',
    };
    setScheduleRegtistCondition(condition);
    setOpenScheduleRegistModal(true);
  };

  const btnScheduleRegist = (params) => {
    console.log('일정등록');

    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.Setup을 하나만 선택해 주세요.', 'Setup을 하나만 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length === 1) {
      const elmPrjId = selectedData[0].elmPrjId;
      const condition = {
        elmPrjId: elmPrjId,
        mode: 'WRITE',
      };
      setScheduleRegtistCondition(condition);
    }

    setOpenScheduleRegistModal(true);
  };

  const btnResultRegist = (params, binding) => {
    if (['OBSR', 'DLY'].includes(params.value)) {
      return `<a href="#" class="wj-cell-maker" tabindex="-1" id="btnOpen">${binding}</a>`;
    } else {
      return `<span>${binding}</span>`;
    }
  };

  const btnShowChart = (params) => {
    console.log('차트보기');
    // setOpenResultRegistModal(true);
  };

  const btnRefresh = () => {
    console.log('새로고침');
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('pjm.label.법인', '법인'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={copCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('copCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.공장', '공장'))}</label>
                <MultiComboBox
                  id="prdnPldoCd"
                  options={prdnPldoCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnPldoCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnPldoCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourColThree">
              <SearchCols>
                <label>{t('ut.label.프로젝트 ID', '프로젝트 ID')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="elmPrjId"
                  placeholder={String(
                    t('ut.label.프로젝트 ID를 입력해 주세요.', '프로젝트 ID를 입력해 주세요.')
                  )}
                  value={searchParam?.elmPrjId}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.프로젝트 명', '프로젝트 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="elmPrjNm"
                  placeholder={String(
                    t('ut.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')
                  )}
                  value={searchParam?.elmPrjNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup 일정 Dashboard', 'Setup 일정 Dashboard')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="rule"
            onClick={() => setOpenlegendPopUp(true)}
            disableRipple
          >
            {t('pjm.label.범례', '범례', '범례')}
          </Button>
          {/* <StatusDot>
            <span className="red">{t('pjm.label.지연', '지연')}</span>
            <span className="yellow">{t('pjm.label.지연완료', '지연완료')}</span>
            <span className="green">{t('pjm.label.준수', '준수')}</span>
            <span className="blue">{t('pjm.label.적기완료', '적기완료')}</span>
          </StatusDot> */}
          {hasAuth && (
            <Button
              css={IconButton.button}
              className="regist"
              onClick={btnScheduleRegist}
              disableRipple
            >
              {t('com.button.일정등록', '일정등록')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnMasterExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnRefresh} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={true}
        initialized={onInitialized}
        itemFormatter={onItemFormatter}
        height={400}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
      />
      {isOpenScheduleRegistModal && (
        <SetupScheduleRegistPopup
          open={isOpenScheduleRegistModal}
          close={() => setOpenScheduleRegistModal(false)}
          onCallback={() => console.log('콜백')}
          condition={scheduleRegtistCondition}
        />
      )}
      {isOpenResultRegistModal && (
        <SetupResultRegistPopup
          open={isOpenResultRegistModal}
          close={() => setOpenResultRegistModal(false)}
          onCallback={() => console.log('콜백')}
          condition={''}
        />
      )}
      {isOpenlegendPopUp && (
        <SetupDashboardLegendPopup
          open={isOpenlegendPopUp}
          close={() => setOpenlegendPopUp(false)}
        />
      )}
    </ContainerLayout>
  );
};

export default SetupDashboardPage;
