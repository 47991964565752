/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import { useCommonModal } from 'hooks/useCommonModal';
import { IdleAssetReview } from 'models/asst/IdleAssetReview';
import { findAssetReview } from 'apis/asst/IdleAssetReview';
import { GatingContListPopUp } from '../../gtng/popup/GatingContListPopUp';

interface Prop {
  condition?: any;
}

const IdleAssetReviewGrid = (props: Prop, ref) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<IdleAssetReview[]>([]);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);
  const [userIds, setUserIds] = useState<string>('');

  useImperativeHandle(ref, () => ({
    search: (params) => {
      findAssetReview(params.rvwReqId, params.asstTpCd, params.eqpId).then((result) => {
        setRowData(result);
      });
    },
  }));

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;
        if ('wrtUserNms' === binding) {
          const ids = (item.wrtUserIds || '').replaceAll(' ', '');
          if (ids) {
            setUserIds(ids);
            setOpenUserReadModal(true);
          }
        }
      }
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'tgtCopCd',
      header: String(t('asst.label.법인', '법인')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'crgeDeptNm',
      header: String(t('asst.label.부서', '부서')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'wrtUserNms',
      header: String(t('asst.label.담당자', '담당자')),
      align: 'left',
      minWidth: 200,
      width: '*',
      cssClass: 'WijmoFind',
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      header: String(t('asst.label.상태', '상태')),
      align: 'center',
      width: 100,
      cellTemplate: (params) => `
            ${
              params.item.rvwRltDivsCd
                ? t('asst.label.검토완료', '검토완료')
                : t('asst.label.검토중', '검토중')
            }
          `,
    },
    {
      binding: 'rvwRltDivsCdNm',
      header: String(t('asst.label.검토결과', '검토결과')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'rvwRltCtn',
      header: String(t('asst.label.Comment', 'Comment')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'rvwUserNm',
      header: String(t('asst.label.검토자', '검토자')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'rvwDt',
      header: String(t('asst.label.검토일자', '검토일자')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'cfmYn',
      header: String(t('asst.label.법인지원팀 확정', '법인지원팀 확정')),
      align: 'center',
      width: 140,
      isReadOnly: true,
    },
    {
      binding: 'cfmOpinCtn',
      header: String(t('asst.label.법인지원팀 검토결과', '법인지원팀 검토결과')),
      align: 'left',
      width: 160,
      isReadOnly: true,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.법인별 검토 현황', '법인별 검토 현황')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={100}
        isSelector={false}
        isReadOnly={true}
        allowPinning={false}
        initialized={onInitialized}
      />

      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
    </>
  );
};

export default React.forwardRef(IdleAssetReviewGrid);
