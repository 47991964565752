/** @jsxImportSource @emotion/react */
import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { Button, Checkbox } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import CustomGrid from 'components/grids/CustomGrid';
import {
  checkAvailableRequest,
  findMultiUtMatrixLibraryList,
  findUtMatrixLibraryList,
} from 'apis/ut/UtMatrixLibrary';
import { UtMatrixLibrary } from 'models/ut/UtMatrixLibrary';
import { hasRole } from 'utils/SessionUtil';
import { useMessageBar } from 'hooks/useMessageBar';
import { useCommonModal } from 'hooks/useCommonModal';
import ApproveRequestModal from '../../approves/ApproveRequestModal';
import { UtMatrixStandardEquipmentCondition } from 'models/ut/UtMatrixStandardEquipment';
import _ from 'lodash';
import { st } from 'components/inputs/CustomInput';

interface Props {
  masterRef: any;
  onDetailGridRef: (ref: any) => void;
  onClickStndConfirmBtn: () => void;
  onClickRegistBtn: () => void;
  onClickEditBtn: () => void;
  onClickDeleteBtn: () => void;
  onClickLibraryExcelDownloadBtn: () => void;
  onClickExcelDownloadBtn: () => void;
}
export const UtMatrixLibraryDetailGrid = (
  {
    masterRef,
    onDetailGridRef,
    onClickStndConfirmBtn,
    onClickRegistBtn,
    onClickEditBtn,
    onClickDeleteBtn,
    onClickLibraryExcelDownloadBtn,
    onClickExcelDownloadBtn,
  }: Props,
  ref
) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();

  const [subTitle, setSubTitle] = useState<string>('');
  const [rowData, setRowData] = useState<UtMatrixLibrary[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [condition, setCondition] = useState({
    stndEqpId: '',
    curVerYn: 'Y',
  });

  const curVerYn = useRef('Y');

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    init: () => {
      setTotalCount(0);
      setRowData([]);
      setSubTitle('');
    },
    search: async (params) => {
      await handleSearch(params);
    },
  }));

  useEffect(() => {
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  const getSelectedMasterRow = () => {
    const selectedRowNodes = masterRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    return isSelected.map(({ dataItem }) => dataItem);
  };

  const handleSearch = async (params) => {
    let response;
    let searchCondition;

    if (Array.isArray(params) && params.length > 1) {
      const ids = params.map((item) => item.stndEqpId);
      searchCondition = {
        ...condition,
        stndEqpId: '',
        stndEqpIds: ids,
        curVerYn: curVerYn.current,
      };

      setCondition(searchCondition);
      response = await findMultiUtMatrixLibraryList(searchCondition);
    } else {
      let stndEqpId = 'No Selection';
      if (params && params.length > 0) {
        stndEqpId = params[0].stndEqpId;
      }
      searchCondition = {
        ...condition,
        stndEqpIds: [],
        stndEqpId: stndEqpId,
        curVerYn: curVerYn.current,
      };
      setCondition(searchCondition);
      response = await findUtMatrixLibraryList(searchCondition);
    }
    // const title =
    //   (searchCondition?.stndEqpId || '') === 'No Selection' ? '' : searchCondition?.stndEqpId || '';
    // setSubTitle(title);
    setTotalCount(response?.length || 0);

    if (response) {
      setRowData(response);
    }
  };

  const layoutDefinition = [
    {
      binding: 'utmLibId',
      header: String(t('ut.grid.Library ID', 'Library ID')),
      align: 'center',
      width: 140,
    },
    {
      binding: 'utmLibVerNo',
      header: String(t('ut.grid.Version', 'Version')),
      align: 'center',
      minWidth: 80,
      width: '*',
    },
    {
      binding: 'prjNm',
      header: String(t('ut.grid.프로젝트', '프로젝트')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'vdcpNm',
      header: String(t('ut.grid.Vendor', 'Vendor')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'genNm',
      header: String(t('ut.grid.Generation', 'Generation')),
      align: 'center',
      width: 140,
    },
    {
      binding: 'vltgNvl',
      header: String(t('ut.grid.Voltage', 'Voltage')),
      align: 'center',
      width: 90,
    },
    {
      binding: 'elpwPhasCd',
      header: String(t('ut.grid.Phase', 'Phase')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'ctwTpCd',
      header: String(t('ut.grid.Wire', 'Wire')),
      align: 'center',
      width: 90,
    },
    {
      binding: 'od2EcNvl',
      header: String(t('ut.grid.Current(입력값)', 'Current(입력값)')),
      align: 'right',
      width: 120,
      cellTemplate: (params) =>
        _.isNaN(Number(params.value)) ? params.value : Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'brkeCapa',
      header: String(t('ut.label.Breaker', 'Breaker')),
      width: 120,
      isRequired: false,
      align: 'right',
    },
    {
      binding: 'elpwCapa',
      header: String(t('ut.grid.Capacity', 'Capacity')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'cdaCspCapa',
      header: String(t('ut.grid.CDA', 'CDA')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'nitrPeakCspCapa',
      header: String(t('ut.grid.N2', 'N2')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'iwCspCapa',
      header: String(t('ut.grid.IW', 'IW')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'frwtCspCapa',
      header: String(t('ut.grid.FW', 'FW')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'coolCspCapa',
      header: String(t('ut.grid.PCW', 'PCW')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'wwCspCapa',
      header: String(t('ut.grid.WW', 'WW')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'stemCspCapa',
      header: String(t('ut.grid.ST', 'ST')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'lngCspCapa',
      header: String(t('ut.grid.NG', 'NG')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'insdExhaCspCapa',
      header: String(t('ut.grid.RA', 'RA')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'otsdExhaCspCapa',
      header: String(t('ut.grid.EA', 'EA')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'suarCspCapa',
      header: String(t('ut.grid.SA', 'SA')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'rmk',
      header: String(t('ut.grid.Remark', 'Remark')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('ut.grid.등록자', '등록자')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('ut.grid.등록일자', '등록일자')),
      align: 'center',
      width: 110,
    },
  ];

  const onInitialized = (grid) => {
    onDetailGridRef && onDetailGridRef(grid);
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.UT Matrix Library', 'UT Matrix Library')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <div style={{ display: 'inline-flex', width: '100px', paddingTop: '5px' }}>
            <label htmlFor="curVerYn_detail" style={{ paddingTop: '0px' }}>
              {t('ut.label.이전버전보기', '이전버전보기')}
            </label>
            <Checkbox
              id="curVerYn_detail"
              css={st.checkbox}
              className="custom"
              checked={curVerYn.current === 'N'}
              onChange={(e) => {
                curVerYn.current = curVerYn.current === 'Y' ? 'N' : 'Y';
                const selectedRow = getSelectedMasterRow();
                handleSearch(selectedRow);
              }}
              disableRipple
            />
          </div>
          <Button
            css={IconButton.button}
            className="info"
            onClick={onClickStndConfirmBtn}
            disableRipple
          >
            {t('com.button.설비코드확인', '설비코드확인')}
          </Button>
          {hasAuth && (
            <Button
              css={IconButton.button}
              className="regist"
              onClick={onClickRegistBtn}
              disableRipple
            >
              {t('com.button.등록', '등록')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="edit" onClick={onClickEditBtn} disableRipple>
              {t('com.button.수정', '수정')}
            </Button>
          )}
          {hasAuth && (
            <Button
              css={IconButton.button}
              className="delete"
              onClick={onClickDeleteBtn}
              disableRipple
            >
              {t('com.button.삭제', '삭제')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={onClickLibraryExcelDownloadBtn}
            disableRipple
          >
            {t('com.button.Library 다운로드', 'Library 다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={onClickExcelDownloadBtn}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={handleSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isSelector={false}
        autoCheck={true}
        isReadOnly={true}
        allowSorting={false}
        allowPinning={false}
        allowDragging={false}
        excludeFilter={['utmLibId']}
        height={510}
        initialized={onInitialized}
      />
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={requestModalCondition?.pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            mode: requestModalCondition?.mode,
            requestMasterList: requestModalCondition?.requestMasterList,
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(UtMatrixLibraryDetailGrid);
