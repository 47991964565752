/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { tabs } from 'components/layouts/Tab';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getDtalProcCodes } from 'apis/ut/UtMatrixManager';
import CustomGrid from 'components/grids/CustomGrid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};

const FacilityInspectionHistoryPopup = ({ open, close, condition, onCallback }: Props) => {
  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const { t } = useTranslation();

  const [inspectionHistoryRowData, setInspectionHistoryRowData] = useState<any>();

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setHitTest(ht);
      }
    });
  };

  const inspectionHistoryLayoutDefinition = [
    {
      binding: 'bildPlntCdNm',
      header: String(t('pjm.label.Weekly', 'Weekly')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.기간', '기간')),
      isReadOnly: true,
      align: 'center',
      width: 300,
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.점검내용', '점검내용')),
      isReadOnly: true,
      align: 'center',
      width: '*',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.점검자', '점검자')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('pjm.label.점검일자', '점검일자')),
      isReadOnly: true,
      align: 'center',
    },
  ];

  const btnInsHistoryExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_대상설비_List', 'Setup_대상설비_List')));
  };

  const btnRefresh = () => {
    console.log('새로고침');
  };

  return (
    <CustomDialog
      title={t('com.label.Setup 주간 점검이력', '주간 점검이력')}
      size="lg"
      open={open}
      onClose={close}
      onCancel={close}
    >
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.공정', '공정')}</span>
              </TableCell>
              <TableCell>
                <span></span>
                {/* <CustomInputWithSearch name="itemEngNm" value={mpItem?.itemEngNm} /> */}
              </TableCell>
              <TableCell>
                <span>{t('mp.label.세부공정', '세부공정')}</span>
              </TableCell>
              <TableCell>
                <span></span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.라인', '라인')}</span>
              </TableCell>
              <TableCell>
                <span></span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.Machine', 'Machine')}</span>
              </TableCell>
              <TableCell>
                <span></span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.단계', '단계')}</span>
              </TableCell>
              <TableCell>
                <span></span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.계획일정', '계획일정')}</span>
              </TableCell>
              <TableCell>
                <span></span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.점검이력 List', '점검이력 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{inspectionHistoryRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnInsHistoryExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnRefresh} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={inspectionHistoryLayoutDefinition}
        rowData={inspectionHistoryRowData}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        isReadOnly={true}
        initialized={onInitialized}
        height={400}
        // excludePin={['smitCloDtm', 'planCpltDtm', 'dataInsDtm']}
        onChangeCheckedItem={() => console.log('click')}
      />
    </CustomDialog>
  );
};

export default FacilityInspectionHistoryPopup;
