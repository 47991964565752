import { Employee, EmployeeCondition } from 'models/admin/Employee';
import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { SetupMaster, SetupTgtEqpSchedule } from 'models/pjm/SetupScheduleRegistDetail';
import { callApi } from 'utils/ApiUtil';

export const getSetupScheduleRegistList = async (elmPrjId) => {
  console.log('elmPrjId', elmPrjId);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupScheduleRegistList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ elmPrjId: elmPrjId }),
  };
  const response: CommonResponse<SetupMaster> = await callApi(request);
  console.log('response', response);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupMaster;
};

export const getSetupEqpTgtScheduleList = async (searchParam) => {
  console.log('searchParam', searchParam);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupEqpTgtScheduleList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...searchParam }),
  };
  const response: CommonResponse<SetupTgtEqpSchedule[]> = await callApi(request);
  console.log('response', response);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupTgtEqpSchedule[];
};

export const getSetupMgrByCondition = async (condition: EmployeeCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/employee`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as Employee[];
};
