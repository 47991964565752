import { Code } from 'models/common/CommonCode';
import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { SearchParam, SetupManager } from 'models/pjm/SetupManager';
import { callApi } from 'utils/ApiUtil';

export const getSetupManagerList = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupManagerList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<SetupManager[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupManager[];
};

export const saveSetupManagerList = async (saveData: SetupManager[]) => {
  console.log('saveData', saveData);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/setup/saveSetupManagerList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const getEquGroupParameter = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getEquGroupParameter`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<Code[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as Code[];
};
