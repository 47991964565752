/** @jsxImportSource @emotion/react */
import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { InputDate } from '@grapecity/wijmo.input';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import { getExcelFileName } from 'utils/ExcelUtil';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { IdleAssetReviewManager } from 'models/asst/IdleAssetReview';
import { useMessageBar } from 'hooks/useMessageBar';

interface Props {
  aprReqId?: string;
  rvwReqId: string;
  isReadOnly?: boolean;
}

const IdleAssetRequest = (props: Props, ref) => {
  const { rvwReqId, isReadOnly = false } = props;
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const dateEditor = useRef(
    new InputDate(document.createElement('div'), {
      format: 'yyyy.MM.dd',
      isRequired: false,
    })
  );
  const [rowData, setRowData] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return false;
    },
    getBizReqData: (aprReqId: string, reqRsn: string) => {
      return null;
    },
  }));

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('asst.label.불용자산 등록', '불용자산 등록');
    book.saveAsync(getExcelFileName(t('asst.label.불용자산 등록', '불용자산 등록')));
  };

  const handleAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
    } as IdleAssetReviewManager;
    const rows = [newRow, ...rowData];
    setRowData(rows);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  };

  const handleSearch = () => {
    console.log('-');
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: '',
      header: String(t('asst.label.분류', '분류')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.설비ID', '설비ID')),
      align: 'left',
      width: 100,
      isReadOnly: true,
      cssClass: 'WijmoFind',
      cellTemplate: (params) => `
            <span>${params.value || ''}</span> 
            <Button /> 
        `,
    },
    {
      binding: '',
      header: String(t('asst.label.설비명', '설비명')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.코스트센터', '코스트센터')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.코스트센터명', '코스트센터명')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.자산번호', '자산번호')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.고정자산명', '고정자산명')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.공정', '공정')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.자산취득일자', '자산취득일자')),
      align: 'left',
      width: 100,
      cssClass: 'WijmoDate',
      editor: dateEditor.current,
      cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      // isReadOnly: false, // 컬럼 속성에서 isReadOnly 설정을 하는 경우 달력 아이콘 미노출되어 별도로 이벤트 제어
    },
    {
      binding: '',
      header: String(t('asst.label.대응모델', '대응모델')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.취득가액(KRW)', '취득가액(KRW)')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.잔존가액(KRW)', '잔존가액(KRW)')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.수량', '수량')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.단위', '단위')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.site', 'site')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.참고자료', '참고자료')),
      align: 'center',
      width: 100,
    },
    {
      binding: '',
      header: String(t('asst.label.비고', '비고')),
      align: 'center',
      width: 100,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.불용자산 활용 요청 정보', '불용자산 활용 요청 정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} className="colFix3" style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('asst.label.요청번호', '요청번호')}</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span>{t('asst.label.요청법인', '요청법인')}</span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <span>{t('asst.label.요청부서', '요청부서')}</span>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('asst.label.검토완료 일자', '검토완료 일자')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomDatepicker
                  isRange={false}
                  value={dayjs().add(3, 'day').toDate()}
                  minDate={dayjs().toDate()}
                  onChange={(newValue) => console.log(newValue)}
                />
              </TableCell>
              <TableCell>
                <span>{t('asst.label.요청자', '요청자')}</span>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.불용자산 등록', '불용자산 등록')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          <div className="info warning">
            {t(
              'asst.msg.취득가 및 잔존가는 KRW(원)으로 입력하세요.',
              '취득가 및 잔존가는 KRW(원)으로 입력하세요.'
            )}
          </div>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="addRow"
            onClick={() => handleAddRow()}
            disableRipple
          >
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button
            css={IconButton.button}
            className="delRow"
            onClick={() => handleDelRow()}
            disableRipple
          >
            {t('com.button.행삭제', '행삭제')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch()}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={100}
        allowPinning={false}
        initialized={onInitialized}
      />
    </>
  );
};

export default forwardRef(IdleAssetRequest);
