/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo, useRef, useImperativeHandle } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@mui/material';
import { DataType, addClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import {
  SubTitleLayout,
  SubTitleGroup,
  GlobalBtnGroup,
  ControlBtnGroup,
} from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import { IconButton } from 'components/buttons/IconSVG';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { commonYNcodes } from 'models/common/Common';
import { downloadExcelTemplates } from 'apis/common/Excel';
import { findUtMatrix, saveUtMatrix } from 'apis/ut/UtMatrixRegist';
import { useLoading } from 'components/process/Loading';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { calculateDmdElpwCapa } from 'utils/UtMatrixUtil';
import { CommonUtil } from 'utils/CommonUtil';
import UtMatrixHookupTagPopup from '../popup/UtMatrixHookupTagPopup';
import UtMatrixRegistHistoryPoupup from '../popup/UtMatrixRegistHistoryPoupup';
import { hasRole } from 'utils/SessionUtil';

const DetailSummaryContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [isOpenHookupTagPopup, setOpenHookupTagPopup] = useState<boolean>(false);
  const [hookupTagCondition, setHookupTagCondition] = useState<any>();
  const [isUtMatrixRegistHistoryModalOpen, setUtMatrixRegistHistoryModalOpen] =
    useState<boolean>(false);
  const [utMatrixRegistHistoryCondition, setUtMatrixRegistHistoryCondition] = useState<any>();
  const [code, setCode] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const isAprText = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');

    return condition.utmWkProgStatCd === 'UTM04' && p.length > 0
      ? String(t('com.button.결재정보', '결재정보'))
      : String(t('com.button.결재요청', '결재요청'));
  }, [processData, condition.utmWkProgStatCd]);

  //검토중 <재작성요청><보류><검증><임시저장><결재요청>
  const isUtReview = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM04' && condition.openMode !== 'appr' && hasAuth;
  }, [condition.utmWkProgStatCd, hasAuth]);

  // 작성완료 : <검토진행> <재작성요청> <보류>
  const isUtComplete = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');
    return condition.utmWkProgStatCd === 'UTM03' && p.length === 0 && hasAuth;
  }, [processData, condition.utmWkProgStatCd, hasAuth]);

  // 작성중 : <검증> <임시저장>
  const isUtWriter = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM02' && hasAuth; //작성중
  }, [processData, condition.utmWkProgStatCd, hasAuth]);

  useImperativeHandle(ref, () => ({
    searchRegistMatrix: (params) => {
      searchRegistMatrix(params);
    },
    saveRegistMatrix: (showMsg = true) => {
      return new Promise((resolve) => {
        handleSave(showMsg).finally(() => {
          resolve(true);
        });
      });
    },
  }));

  useEffect(() => {
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const bldgFloCd: Code[] = await getCommonCodeNames('BLDG_FLO_CD');
    const utVltgList: Code[] = await getCommonCodeNames('UT_VLTG_LIST');
    const elpwPhasCd: Code[] = await getCommonCodeNames('ELPW_PHAS_CD');
    const ctwTpCd: Code[] = await getCommonCodeNames('CTW_TP_CD');
    const utFrqList: Code[] = await getCommonCodeNames('UT_FRQ_LIST');
    const elpwEqpCnctTpCd: Code[] = await getCommonCodeNames('ELPW_EQP_CNCT_TP_CD');
    const devcCnctTpCd: Code[] = await getCommonCodeNames('DEVC_CNCT_TP_CD');
    const exhaCllrTpCd: Code[] = await getCommonCodeNames('EXHA_CLLR_TP_CD');
    const utBrkeCapaList: Code[] = await getCommonCodeNames('UT_BRKE_CAPA_LIST');
    const insTpCd: Code[] = await getCommonCodeNames('INS_TP_CD');
    // const suarCllrTpCd: Code[] = await getCommonCodeNames('SUAR_CLLR_TP_CD');

    setCode({
      bldgFloCd: bldgFloCd,
      utVltgList: utVltgList,
      elpwPhasCd: elpwPhasCd,
      ctwTpCd: ctwTpCd,
      utFrqList: utFrqList,
      elpwEqpCnctTpCd: elpwEqpCnctTpCd,
      devcCnctTpCd: devcCnctTpCd,
      exhaCllrTpCd: exhaCllrTpCd,
      utBrkeCapaList: utBrkeCapaList,
      insTpCd: insTpCd,
      // suarCllrTpCd: suarCllrTpCd,
    });
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'utmSeq',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'bizAreaNm',
        header: String(t('ut.label.AREA', 'AREA')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqclNm',
        header: String(t('ut.label.설비군', '설비군')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 140,
        isReadOnly: true,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => `
            <span>${params.value || ''}</span> 
            ${params.value ? '<Button id="stndEqpId" />' : ''} 
        `,
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 110,
        isReadOnly: true,
      },
      {
        header: String(t('ut.label.Facility Location', 'Facility Location')),
        columns: [
          {
            binding: 'bldgFloCd',
            header: String(t('ut.label.Floor', 'Floor')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.bldgFloCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'istlLocNm',
            header: String(t('ut.label.Room', 'Room')),
            width: 100,
            isReadOnly: true,
          },
        ],
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqpUntNm',
        header: String(t('ut.label.Unit', 'Unit')),
        width: 100,
        isReadOnly: true,
      },
      {
        header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
        columns: [
          {
            // TODO 미입력 (검토/결과 보기 화면에서는 Hookup ID 생성 자동 표시)
            binding: 'machEqpNm',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `
                <span>${params.value || ''}</span>   
                ${params.value && params.item.tagIssuYn === 'Y' ? '<Button />' : ''} 
            `,
          },
          {
            // TODO 미입력 (검토/결과 보기 화면에서는 Hookup ID 생성 자동 표시)
            binding: 'elpwEqpNm',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `
                <span>${params.value || ''}</span> 
                ${params.value && params.item.tagIssuYn === 'Y' ? '<Button />' : ''}
            `,
          },
        ],
      },
      {
        header: String(t('ut.label.설비 Qty', '설비 Qty')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isReadOnly: true,
            align: 'right',
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isReadOnly: true,
            align: 'right',
          },
        ],
      },
      {
        header: String(t('ut.label.EQP Spec(Dimension_mm)', 'Dimension[mm]/Weight[kg]')),
        collapseTo: 'eqpDimWthLen',
        isCollapsed: true,
        columns: [
          {
            binding: 'eqpDimWthLen',
            header: String(t('ut.label.Width', 'Width')),
            width: 190,
            isReadOnly: true,
            align: 'right',
          },
          {
            binding: 'eqpDimDpthNvl',
            header: String(t('ut.label.Depth', 'Depth')),
            width: 120,
            isReadOnly: true,
            align: 'right',
          },
          {
            binding: 'eqpDimHigtNvl',
            header: String(t('ut.label.Height', 'Height')),
            width: 120,
            isReadOnly: true,
            align: 'right',
          },
          {
            binding: 'eqpWgt',
            header: String(t('ut.label.EQP Spec(Weight_kg)', 'Weight')),
            width: 120,
            isReadOnly: true,
            align: 'right',
          },
        ],
      },
      {
        header: String(t('ut.label.Library', 'Library')),
        columns: [
          {
            binding: 'utmLibId',
            header: String(t('ut.label.ID', 'ID')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'utmLibVerNo',
            header: String(t('ut.label.Version', 'Version')),
            width: 100,
            isReadOnly: true,
          },
        ],
      },
      {
        // 시스템 자동 (표준설비코드 Library 선택에 따라 자동으로 설정)
        // 엑셀에서 업로드한 경우는 작성구분을 사용자가 입력한 값을 지정하고 작성구분에 따라 제출 시 표준설비코드, LibraryID의 선택여부를 interlock 한다.
        binding: 'insTpCd',
        header: String(t('ut.label.작성구분', '작성구분')),
        width: 100,
        align: 'center',
        isReadOnly: true,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.insTpCd || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        header: String(t('ut.label.Electricity', 'Electricity')),
        collapseTo: 'vltgNvl',
        columns: [
          /*
          // [24.11.19] 미사용 삭제
          {
            binding: 'elpwEqpGrNm',
            header: String(t('ut.label.Group 명', 'Group 명')),
            width: 100,
            isReadOnly: true,
          },
          */
          {
            binding: 'vltgNvl', // 전기필수 (ELPW_EQP_QTY 값이 1 이상일때 필수)
            header: String(t('ut.label.Voltage[V]', 'Voltage[V]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.utVltgList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'elpwPhasCd', // 전기필수
            header: String(t('ut.label.Phase[Φ]', 'Phase')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.elpwPhasCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'ctwTpCd',
            header: String(t('ut.label.Wires[EA]', 'Wires')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.ctwTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // UT Matrix (UT Matrix No)별로 동일해야 함
            binding: 'frqNvl', // 전기필수
            header: String(t('ut.label.Frequency[Hz]', 'Frequency[Hz]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.utFrqList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // 0.9로 Default 입력
            binding: 'pwftNvl', // 전기필수
            header: String(t('ut.label.Power Factor-', 'Power Factor-')),
            width: 100,
            align: 'right',
            isReadOnly: true,
            dataType: DataType.String,
          },
          {
            binding: 'od1EcNvl', // 수식 시스템 계산
            header: String(t('ut.label.Current(계산값)[A]', 'Current(계산값)[A]')),
            width: 100,
            isReadOnly: true,
            align: 'right',
          },
          {
            binding: 'od2EcNvl',
            header: String(t('ut.label.Current(입력값)[A]', 'Current(입력값)[A]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'smtmOprt',
            header: String(t('ut.label.동시가동율[%]', '동시가동율[%]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'brkeCapa',
            header: String(t('ut.label.Breaker(MCCB)-', 'Breaker(MCCB)-')),
            width: 100,
            align: 'right',
            isReadOnly: true,
          },
          {
            binding: 'plcClctYn', // 전기필수
            header: String(t('ut.label.PLC접지유무-', 'PLC접지유무-')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
          },
          {
            // Voltage[V] = 120 이면 “OUTLET” 자동 아니면 사용자 선택
            binding: 'elpwEqpCnctTpCd', // 전기필수
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.elpwEqpCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'elpwCapa', // 전기필수
            header: String(t('ut.label.Capacity[KW]', 'Capacity[KW]')),
            width: 138,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'elpwSumCapa', // 수식계산 (= 설비 Qty Elec * Capacity[KW])
            header: String(t('ut.label.Capacity Sum', 'Capacity Sum')),
            width: 140,
            isReadOnly: true,
          },
          {
            binding: 'elpwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'elpwHeatCapa', // 전기필수
            header: String(t('ut.label.Heat Capacity[KW]', 'Heat Capacity[KW]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'dmdElpwTarfNvl', // TODO 검토화면에서 UT Manager 권한인 경우 입력
            header: String(t('ut.label.Demand Factor[%]', 'Demand Factor[%]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'dmdElpwCapa', // 검토화면에서 자동계산 (= Capacity Sum * Demand Factor[%])
            header: String(
              t('ut.label.Capacity_Sum(수용율 적용)[KW]', 'Capacity_Sum(수용율 적용)[KW]')
            ),
            width: 195,
            align: 'right',
            isReadOnly: true,
            cellTemplate: (params) =>
              calculateDmdElpwCapa(
                params.item.elpwEqpQty,
                params.item.elpwCapa,
                params.item.dmdElpwTarfNvl
              ),
          },
          /*
          // 개선요청사항(김중열) 57번 - 13. Static Condenser Capa. 해당 열 삭제
          {
            binding: 'cdsCapa', // 검토화면에서 UT Manager 권한인 경우 입력
            header: String(
              t(
                'ut.label.Static Condenser Capa. (p.f. = 0.92)[KVAR]',
                'Static Condenser Capa. (p.f. = 0.92)[KVAR]'
              )
            ),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          */
        ],
      },
      {
        header: String(t('ut.label.CDA', 'CDA')),
        collapseTo: 'cdaOtsdSizeValCtn',
        columns: [
          {
            binding: 'cdaOtsdSizeValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'cdaInsdSizeValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'cdaDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
            isReadOnly: true,
          },
          {
            binding: 'cdaPresValCtn', // [24.11.11] 사용자선택 -> 사용자입력 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'cdaPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'cdaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 143,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'cdaCspSumCapa', // 수식계산 (= Qty * CDA Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 165,
            isReadOnly: true,
          },
          {
            binding: 'cdaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.N2', 'N2')),
        collapseTo: 'nitrOtsdDmtrValCtn',
        columns: [
          {
            binding: 'nitrOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'nitrInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'nitrDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'nitrPresValCtn', // [24.11.11] 사용자선택 -> 사용자입력 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'nitrPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrCspCapa',
            header: String(t('ut.label.AVG Consumption[ℓ/min]', 'AVG Consumption[ℓ/min]')),
            width: 190,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrCspSumCapa',
            header: String(t('ut.label.AVG Consumption Sum', 'AVG Consumption Sum')),
            width: 195,
            isReadOnly: true,
          },
          {
            binding: 'nitrPeakCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Peak Consumption[ℓ/min]', 'Peak Consumption[ℓ/min]')),
            width: 190,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrPeakCspSumCapa', // 수식 (= Qty * N2 Consumption[ℓ/min])
            header: String(t('ut.label.Peak Consumption Sum', 'Peak Consumption Sum')),
            width: 195,
            isReadOnly: true,
          },
          {
            binding: 'nitrStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.IW', 'IW')),
        collapseTo: 'iwOtsdDmtrValCtn',
        columns: [
          {
            binding: 'iwOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'iwInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'iwDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'iwTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'iwPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'iwPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDrnCapa',
            header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
            width: 115,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDrnSumCapa',
            header: String(t('ut.label.Drain Sum', 'Drain Sum')),
            width: 127,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 160,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwCspSumCapa', // 수식 (= Qty * IW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 170,
            isReadOnly: true,
          },
          {
            binding: 'iwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.IW for Emergency fire', 'FW')),
        collapseTo: 'frwtOtsdDmtrValCtn',
        columns: [
          {
            binding: 'frwtOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'frwtInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'frwtDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'frwtTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'frwtPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'frwtPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDrnCapa',
            header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
            width: 112,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDrnSumCapa',
            header: String(t('ut.label.Drain Sum', 'Drain Sum')),
            width: 120,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 155,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtCspSumCapa', // 수식 (= Qty * IW2 Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 165,
            isReadOnly: true,
          },
          {
            binding: 'frwtStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.PCW', 'PCW')),
        collapseTo: 'coolOtsdSizeValCtn',
        columns: [
          {
            binding: 'coolOtsdSizeValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'coolInsdSizeValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'coolDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'coolTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'coolPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'coolPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'coolCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 152,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'coolCspSumCapa', // 수식 (= Qty * PCW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 168,
            isReadOnly: true,
          },
          {
            binding: 'coolStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.WW', 'WW')),
        collapseTo: 'wwOtsdDmtrValCtn',
        columns: [
          {
            binding: 'wwOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'wwInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'wwDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'wwTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'wwPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'wwPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'wwCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 152,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'wwCspSumCapa', // 수식 (= Qty * WW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 168,
            isReadOnly: true,
            align: 'right',
          },
          {
            binding: 'wwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Steam', 'Steam')),
        collapseTo: 'stemOtsdDmtrValCtn',
        columns: [
          {
            binding: 'stemOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'stemInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'stemDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'stemCwtSize',
            header: String(
              t('ut.label.Condenstaor connection size[Φ]', 'Condenstaor connection size[Φ]')
            ),
            width: 237,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCwtDevcCnctTpCd',
            header: String(
              t('ut.label.Condensator connection[Type]', 'Condensator connection[Type]')
            ),
            width: 228,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'stemPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'stemPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[t/hr]', 'Consumption[t/hr]')),
            width: 150,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCspSumCapa', // 수식 (= Qty * Steam Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 170,
            isReadOnly: true,
          },
          {
            binding: 'stemStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.NG', 'NG')),
        collapseTo: 'lngOtsdDmtrValCtn',
        columns: [
          {
            binding: 'lngOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'lngInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'lngDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'lngPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'lngPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'lngCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 150,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'lngCspSumCapa', // 수식 (= Qty * NG Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 170,
            isReadOnly: true,
          },
          {
            binding: 'lngStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Exhaust air [Return(to Air Handling Unit)]', 'Return Air')),
        collapseTo: 'exhaOtsdDmtrValCtn',
        columns: [
          {
            binding: 'exhaOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'insdExhaCllrTpCd',
            header: String(t('ut.label.Collector Type[Type]', 'Collector Type[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'insdExhaTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'insdExhaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 150,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaCspSumCapa', // 수식 (= Qty * Exhaust air Return Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 170,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Exhaust air [Ventilation(to Outside)]', 'Exhaust air')),
        collapseTo: 'otsdExhaOtsdDmtrValCtn',
        columns: [
          {
            binding: 'otsdExhaOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'otsdExhaInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'otsdExhaCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'otsdExhaPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'otsdExhaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 150,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'otsdExhaCspSumCapa', // 수식 (= Qty * Exhaust air [Ventilation Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 170,
            isReadOnly: true,
          },
          {
            binding: 'otsdExhaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Supply air', 'Supply air')),
        collapseTo: 'suarOtsdDmtrValCtn',
        columns: [
          {
            binding: 'suarOtsdDmtrValCtn',
            header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'suarInsdDmtrValCtn',
            header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'suarDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          /*
          [24.12.02] suarDevcCnctTpCd과 중복항목이므로 삭제
          {
            binding: 'suarCllrTpCd',
            header: String(t('ut.label.Connection Type[Duct/Pipe]', 'Connection Type[Duct/Pipe]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.suarCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          */
          {
            binding: 'suarPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'suarCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 150,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'suarCspSumCapa', // 수식 (= Qty * Supply air Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 170,
            isReadOnly: true,
          },
          {
            binding: 'suarStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        binding: 'rmk',
        header: String(t('ut.label.Remark', 'Remark')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.작성자', '작성자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.작성일자', '작성일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일자', '수정일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'utmId',
        visible: false,
      },
      {
        binding: 'planProcId',
        visible: false,
      },
      {
        binding: 'utmSeq',
        visible: false,
      },
      {
        binding: 'eqclId',
        visible: false,
      },
      {
        binding: 'eqpMainId',
        visible: false,
      },
      {
        binding: 'eqpMchId',
        visible: false,
      },
      {
        binding: 'exlUpldUseYn',
        visible: false,
      },
    ];
  }, [code]);

  const searchRegistMatrix = (params) => {
    findUtMatrix('review', params).then((result) => {
      setRowData(result || []);
    });
  };

  const handleSave = (showMsg = true) => {
    return new Promise((resolve) => {
      if (!(rowData || []).filter((o) => o.crudKey).length) {
        /*
        // [24.08.19] 탭 이동 시 변경사항 없는 경우 자동저장 안내문구 미노출
        if (showMsg) {
          openMessageBar({
            type: 'warning',
            content: t('ut.label.변경사항이 없습니다.', '변경사항이 없습니다.'),
          });
        }
        */
        resolve(true);
        return;
      }

      saveUtMatrix('review', rowData)
        .then((res) => {
          if (showMsg) {
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                  : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
          }
          if (res?.successOrNot === 'Y') {
            searchRegistMatrix(condition);
          }
        })
        .finally(() => resolve(true));
    });
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;

        if (e.target instanceof HTMLButtonElement) {
          const id = e.target.id;
          // 표준설비코드 클릭
          if ('stndEqpId' === id) {
            setUtMatrixRegistHistoryCondition({
              stndEqpId: item.stndEqpId,
            });
            setUtMatrixRegistHistoryModalOpen(true);
          }
        }

        if ('machEqpNm' === binding) {
          if (item.machEqpNm && item.tagIssuYn === 'Y') {
            if (item.utmId && item.planProcId && item.utmSeq) {
              setHookupTagCondition({
                utmId: item.utmId,
                planProcId: item.planProcId,
                utmSeq: item.utmSeq,
                tagDivsCd: 'MECH',
              });
              setOpenHookupTagPopup(true);
            }
          }
        }
        if ('elpwEqpNm' === binding) {
          if (item.elpwEqpNm && item.tagIssuYn === 'Y') {
            if (item.utmId && item.planProcId && item.utmSeq) {
              setHookupTagCondition({
                utmId: item.utmId,
                planProcId: item.planProcId,
                utmSeq: item.utmSeq,
                tagDivsCd: 'ELEC',
              });
              setOpenHookupTagPopup(true);
            }
          }
        }
      }
    });
  };

  const downloadExcel = async (templateType) => {
    downloadExcelTemplates(templateType, '1');
  };

  const onBeginningEdit = useEvent((grid, e) => {
    const binding = grid.columns[e.col].binding;
    const item = grid.rows[e.row].dataItem;
    // 표준사용량
    if (
      [
        'elpwStndUseQty',
        'cdaStndUseQty',
        'nitrStndUseQty',
        'iwStndUseQty',
        'frwtStndUseQty',
        'coolStndUseQty',
        'wwStndUseQty',
        'stemStndUseQty',
        'lngStndUseQty',
        'insdExhaStndUseQty',
        'otsdExhaStndUseQty',
        'suarStndUseQty',
      ].includes(binding)
    ) {
      // 작성/검토 상태가 아닌 경우 readonly
      if (!(isUtWriter || isUtComplete || isUtReview)) {
        e.cancel = true;
      }
      // Electricity > 표준사용량 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
      if ('elpwStndUseQty' === binding && Number(item.elpwEqpQty || 0) < 1) {
        e.cancel = true;
      }
    }
    // TODO 검토화면에서 UT Manager 권한인 경우 입력
    // 수용율
    if (['dmdElpwTarfNvl', 'cdsCapa'].includes(binding)) {
      // Electricity > 수용율 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
      if (Number(item.elpwEqpQty || 0) < 1) {
        e.cancel = true;
      }
    }
  });

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 표준사용량
      if (
        [
          'elpwStndUseQty',
          'cdaStndUseQty',
          'nitrStndUseQty',
          'iwStndUseQty',
          'frwtStndUseQty',
          'coolStndUseQty',
          'wwStndUseQty',
          'stemStndUseQty',
          'lngStndUseQty',
          'insdExhaStndUseQty',
          'otsdExhaStndUseQty',
          'suarStndUseQty',
        ].includes(binding)
      ) {
        // 작성/검토 상태가 아닌 경우 readonly
        if (!(isUtWriter || isUtComplete || isUtReview)) {
          cell.ariaReadOnly = true;
        }
        // Electricity > 표준사용량 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
        if ('elpwStndUseQty' === binding && Number(item.elpwEqpQty || 0) < 1) {
          cell.ariaReadOnly = true;
        }
      }
      // TODO 검토화면에서 UT Manager 권한인 경우 입력
      // 수용율
      if (['dmdElpwTarfNvl', 'cdsCapa'].includes(binding)) {
        // Electricity > 수용율 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
        if (Number(item.elpwEqpQty || 0) < 1) {
          cell.ariaReadOnly = true;
        }
      }
    }
  });

  //검토진행
  const handleSubmitReview = () => {
    props?.onSubmit?.();
  };

  //검증
  const handleModify = () => {
    props?.onModify?.();
  };
  //보류 링크
  const handleRequestLinkHold = () => {
    props?.onLinkHold?.();
  };
  //재작성 링크
  const handleRequestLinkRewrite = () => {
    props?.onLinkRewrite?.();
  };

  const handleApproval = () => {
    props?.onAppr?.();
  };

  const onCellEditEnding = useEvent((grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;
    // Demand Factor
    if ('dmdElpwTarfNvl' === binding) {
      if (!_.isNull(newVal) && newVal != '') {
        if (Number(newVal) > 100 || Number(newVal) < 0) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.0~100점 사이로 입력하세요.', '0~100점 사이로 입력하세요.'),
          });
          e.cancel = true;
          return;
        }
      }
    }
  });

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.상세보기', '상세보기')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              CommonUtil.exportWijmoExcelGrid(
                flexRef,
                t('ut.label.Ut Matrix 작성 대상', 'Ut Matrix 작성 대상'),
                t('ut.label.Ut Matrix 작성 대상', 'Ut Matrix 작성 대상')
              );
            }}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>

          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchRegistMatrix(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isSelector={false}
        frozenColumns={4}
        isReadOnly={!isUtWriter && !isUtComplete && !isUtReview}
        excludePin={['utmSeq']}
        excludeFilter={['utmSeq']}
        initialized={onInitialized}
        beginningEdit={onBeginningEdit}
        cellEditEnding={onCellEditEnding}
      />

      <GlobalBtnGroup>
        {/* 작성중 : <검증> <임시저장> <새로고침> UTM02  */}
        {/* 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>  UTM05 */}
        {/* 검토중 : <결재요청> <목록> <다운로드>  UTM04 */}
        {/* 그외 (결재중) : <목록> <다운로드>  */}

        {isUtWriter && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
          </>
        )}
        {isUtComplete && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleSubmitReview}>
              {t('com.button.검토진행', '검토진행')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
          </>
        )}
        {isUtReview && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
            <Button css={IconButton.button} className="save" onClick={handleApproval}>
              {isAprText}
            </Button>
          </>
        )}
      </GlobalBtnGroup>
      {isOpenHookupTagPopup && (
        <UtMatrixHookupTagPopup
          open={isOpenHookupTagPopup}
          condition={hookupTagCondition}
          close={() => setOpenHookupTagPopup(false)}
        />
      )}
      {isUtMatrixRegistHistoryModalOpen && (
        <UtMatrixRegistHistoryPoupup
          open={isUtMatrixRegistHistoryModalOpen}
          close={() => setUtMatrixRegistHistoryModalOpen(false)}
          condition={{
            stndEqpId: utMatrixRegistHistoryCondition?.stndEqpId,
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(DetailSummaryContent);
