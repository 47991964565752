import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { SearchParam, UtMatrixHookupTag } from 'models/ut/UtMatrixHookupTag';
import { callApi } from 'utils/ApiUtil';

export const getUtMatrixHookupTagList = async (searchParam: SearchParam) => {
  console.log('searchParam', searchParam);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixHookupTagList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<UtMatrixHookupTag[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixHookupTag[];
};
