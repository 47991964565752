/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@mui/material';
import { Checkbox } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import {
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
  SearchBox,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { SearchParam, UtMatrixDetailList, UtMatrixMasterList } from 'models/ut/UtMatrixList';
import {
  deleteUtMatrixMaster,
  getUtMatrixDetailList,
  getUtMatrixMasterList,
} from 'apis/ut/UtMatrixList';
import { Code } from 'models/common/CommonCode';
import moment from 'moment';
import { st } from 'components/inputs/CustomInput';
import CustomDialog from 'components/modals/common/CustomDialog';

type Props = {
  open: boolean;
  close: () => void;
  onCallback: (result) => void;
};

const UtMatrixListPopUp = ({ open, close, onCallback }: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [flexRef, setFlexRef] = useState<any>();

  const [masterRowData, setMasterRowData] = useState<UtMatrixMasterList[]>([]);

  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  // const [askPurpCode, setAskPurpCode] = useState<Code[]>([]);
  const [utmWkProgStatCode, setUtmWkProgStatCode] = useState<Code[]>([]);

  const today = moment().format('YYYY-MM-DD');
  const bfTwoMonth = moment().subtract(2, 'months');
  const startMonth = bfTwoMonth.startOf('month').format('YYYY-MM-DD');
  const [searchParam, setSearchParam] = useState<SearchParam>({
    bildPlntCds: [],
    askPurpCds: [],
    utmWkProgStatCds: ['UTM03', 'UTM04', 'UTM05'],
    curYn: 'N',
    startDate: startMonth,
    endDate: today,
  });

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    // const askPurpCode = await getCommonCodeNames('ASK_PURP_CD');
    const utmWkProgStatCode = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    setBildPlntCode(bildPlntCode);
    // setAskPurpCode(askPurpCode);
    const filteredWkProgStatCode = utmWkProgStatCode.filter((item) => {
      return item.cmnCd === 'UTM03' || item.cmnCd === 'UTM04' || item.cmnCd === 'UTM05';
    });
    setUtmWkProgStatCode(filteredWkProgStatCode);
  };

  const masterLayoutDefinition = [
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      visible: true,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'bildPlntCdNm',
      header: String(t('ut.label.공장', '공장')),
      isReadOnly: true,
      width: 180,
      align: 'center',
    },
    {
      binding: 'bildPlntCd',
      visible: false,
    },
    // {
    //   binding: 'askPurpCdNm',
    //   header: String(t('ut.label.목적', '목적')),
    //   isReadOnly: true,
    //   width: 100,
    //   align: 'center',
    // },
    {
      binding: 'askPurpCd',
      visible: false,
    },
    {
      binding: 'utmNo',
      header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
      width: 180,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'utmNm',
      header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
      width: '*',
      isReadOnly: true,
      align: 'left',
    },
    {
      binding: 'utmWkProgStatCdNm',
      header: String(t('ut.label.상태', '상태')),
      width: 100,
      isReadOnly: true,
      align: 'center',
      cssClass: 'WijmoTag',
      cellTemplate: (item) => {
        if (item.item.utmWkProgStatCd === 'UTM01') {
          return `<span class="grey">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM02') {
          return `<span class="green">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM03') {
          return `<span class="yellow">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM04') {
          return `<span class="blue">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM05') {
          return `<span class="orange">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM06') {
          return `<span class="red">${item.value}</span>`;
        }
      },
    },
    {
      binding: 'utmWkProgStatCd',
      visible: false,
    },
    {
      binding: 'tagIssuYn',
      header: String(t('ut.label.태그여부', '태그여부')),
      width: 120,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'verNo',
      header: String(t('com.label.버전', '버전')),
      width: 80,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'dataInsDtm',
      header: String(t('ut.label.요청일자', '요청일자')),
      width: 120,
      isReadOnly: true,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // cell 선택 시 checkBox 체크
      if (grid.rows[ht.row].isSelected == true) {
        grid.rows[ht.row].isSelected = false;
      } else {
        grid.rows[ht.row].isSelected = true;
      }
      grid.refresh();
    });
  };

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnSearch = () => {
    getUtMatrixMasterList(searchParam).then((result: UtMatrixMasterList[]) => {
      if (result !== null) {
        setMasterRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as SearchParam
    );
    setSearchParam({
      utmWkProgStatCds: [],
      curYn: 'N',
    });
  };

  const handleSave = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const utmData = isSelected.map(({ dataItem }) => dataItem);
    console.log('utmData', utmData);
    onCallback(utmData);
    close();
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('com.label.UT Matrix', 'UT Matrix')}
      size="lg"
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{String(t('ut.label.공장', '공장'))}</label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.bildPlntCds}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </SearchCols>
              {/* <SearchCols>
                <label>{String(t('ut.label.목적', '목적'))}</label>
                <MultiComboBox
                  id="askPurpCd"
                  options={askPurpCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.askPurpCds}
                  onChange={(value) => {
                    handleOnChange('askPurpCd', value.join());
                  }}
                />
              </SearchCols> */}
              <SearchCols>
                <label>{String(t('ut.label.상태', '상태'))}</label>
                <MultiComboBox
                  id="utmWkProgStatCd"
                  options={utmWkProgStatCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.utmWkProgStatCds}
                  onChange={(value) => {
                    handleOnChange('utmWkProgStatCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <div style={{ display: 'inline-flex', width: '200px', paddingTop: '5px' }}>
                  <label style={{ paddingTop: '0px' }}>
                    {t('ut.label.이전버전보기', '이전버전보기')}
                  </label>
                  <Checkbox
                    id="showPrevVersion"
                    name="showPrevVersion"
                    css={st.checkbox}
                    className="custom"
                    checked={searchParam?.curYn === 'Y'}
                    onClick={(e) => {
                      setSearchParam({
                        ...searchParam,
                        curYn: searchParam?.curYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                    disableRipple
                  />
                </div>
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNo"
                  placeholder={String(
                    t('ut.label.UT Matrix 번호를 입력해 주세요.', 'UT Matrix 번호를 입력해 주세요.')
                  )}
                  value={searchParam?.utmNo}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNm"
                  placeholder={String(
                    t('ut.label.UT Matrix 명을 입력해 주세요.', 'UT Matrix 명을 입력해 주세요.')
                  )}
                  value={searchParam?.utmNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix List', 'UT Matrix List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{masterRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={masterLayoutDefinition}
        rowData={masterRowData}
        alternatingRowStep={0}
        initialized={onInitialized}
        height={250}
      />
    </CustomDialog>
  );
};

export default UtMatrixListPopUp;
