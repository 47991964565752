import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import {
  UtMatrixStandardEquipment,
  UtMatrixStandardEquipmentCondition,
} from 'models/ut/UtMatrixStandardEquipment';
import {
  UtMatrixLibraryCondition,
  UtMatrixLibrary,
  MultiUtMatrixLibraryCondition,
} from 'models/ut/UtMatrixLibrary';

/**
 * Ut 표준설비 조회
 * @param condition
 */
export const findUtMatrixStandardEquipmentList = async (
  condition: UtMatrixStandardEquipmentCondition
) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/standard-equipment`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<UtMatrixStandardEquipment[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixStandardEquipment[];
};

/**
 * Ut 라이브러리 조회
 * @param condition
 */
export const findUtMatrixLibraryList = async (condition?: UtMatrixLibraryCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/library`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as UtMatrixLibrary[];
};

/**
 * Ut 라이브러리 조회(다건)
 * @param condition
 */
export const findMultiUtMatrixLibraryList = async (condition?: MultiUtMatrixLibraryCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/multi-library`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as UtMatrixLibrary[];
};

/**
 * Ut 라이브러리 조회(버전확인)
 * @param condition
 */
export const findUtMatrixAllLibraryList = async (condition?: UtMatrixLibraryCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/library/all`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as UtMatrixLibrary[];
};

/**
 * UT 라이브러리 요청 가능여부 확인
 * @param masterList
 */
export const checkAvailableRequest = async (
  mode: string,
  masterList: UtMatrixStandardEquipment[]
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/library/check-request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      mode: mode,
      requestMasterList: masterList,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * UT 라이브러리 요청 가능여부 확인
 * @param masterList
 */
export const checkAvailableDeleteRequest = async (deleteList: UtMatrixLibrary[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/library/check-delete-request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: deleteList,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};
