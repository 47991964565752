import {
  IdleAssetReview,
  IdleAssetReviewManager,
  IdleAssetReviewManagerCondition,
} from 'models/asst/IdleAssetReview';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * 유휴자산관리 > 법인별 진행 현황 목록 조회
 * @param rvwReqId
 * @param asstTpCd
 * @param eqpId
 */
export const findAssetReviewStatus = async (rvwReqId: string, asstTpCd: string, eqpId: string) => {
  const params = {
    rvwReqId: rvwReqId,
    asstTpCd: asstTpCd,
    eqpId: eqpId,
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/review-status`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetReview[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReview[];
};

/**
 * 유휴자산관리 > 법인별 검토 현황 목록 조회
 * @param rvwReqId
 * @param asstTpCd
 * @param eqpId
 */
export const findAssetReview = async (rvwReqId: string, asstTpCd: string, eqpId: string) => {
  const params = {
    rvwReqId: rvwReqId,
    asstTpCd: asstTpCd,
    eqpId: eqpId,
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/review`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetReview[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReview[];
};

export const findIdleAssetReviewManager = async (condition?: IdleAssetReviewManagerCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/review-manager`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IdleAssetReviewManager[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReviewManager[];
};

export const saveIdleAssetReviewManager = async (
  idleAssetReviewManager?: IdleAssetReviewManager[]
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/asst/review-manager`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: idleAssetReviewManager,
  };

  const response: CommonResponse<IdleAssetReviewManager[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReviewManager[];
};
