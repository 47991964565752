/** @jsxImportSource @emotion/react */

/**
 * 부서 선택 팝업 TYPE 1
 */
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDepartments } from 'apis/admin/Department';
import { Department } from 'models/admin/Department';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { useMessageBar } from 'hooks/useMessageBar';
import { Button } from '@mui/material';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from './CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';

type DepartmentModalProps = {
  open: boolean;
  close: () => void;
  save: (item: Department) => void;
};

const DepartmentType1Modal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [checkedItems, setCheckedItems] = useState<Department[]>([]);
  const [searchDeptNm, setSearchDeptNm] = useState<string>('');
  const [searchItem, setSearchItem] = useState<string>('');

  const layoutDefinition = [
    {
      binding: 'btnSelect',
      header: ' ',
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button />',
      width: 39,
    },
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'deptCd',
      header: String(t('com.label.부서코드', '부서코드')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'copCd',
      header: String(t('com.label.법인 코드', '법인 코드')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'deptNm',
      header: String(t('com.label.부서명', '부서명')),
      align: 'center',
      minWidth: 100,
      width: '*',
    },
  ];

  const getDepartmentList = useCallback(async () => {
    const response = await getDepartments(searchItem, searchDeptNm);
    setDepartmentList(response.departmentList);
  }, [searchItem, searchDeptNm]);

  const handleClose = () => {
    setDepartmentList([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDeptNm(e.target.value);
  };
  const handleOnChangeDeptCd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleResetCondition = () => {
    setSearchItem('');
    setSearchDeptNm('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = useCallback(() => {
    getDepartmentList();
  }, [searchItem, searchDeptNm]);

  const btnDoubleClick = (item) => {
    props.save(item);
    props.close();
    setDepartmentList([]);
  };

  const handleOnSave = () => {
    if ((checkedItems || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.한 부서 이상 선택해야 합니다.', '한 부서 이상 선택해야 합니다.'),
      });
      return;
    }
    btnDoubleClick(checkedItems[0]);
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        // Quick Select
        if ('btnSelect' === binding) {
          btnDoubleClick(item);
        }
      }
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (item) {
        btnDoubleClick(item);
      }
    });
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleOnSave}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('com.label.부서조회', '부서조회')}
      open={props.open}
      onClose={handleClose}
      onCancel={handleClose}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className={'twoCol'}>
              <SearchCols>
                <label>{t('com.label.부서명', '부서명')}</label>
                <CustomInputWithSearch
                  value={searchDeptNm}
                  onChange={handleOnChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.부서코드', '부서코드')}</label>
                <CustomInputWithSearch
                  value={searchItem}
                  onChange={handleOnChangeDeptCd}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            />
            <Button css={IconButton.button} className="find" disableRipple onClick={handleSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.부서', '부서')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(departmentList || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={departmentList}
        height={500}
        rowSelection="single"
        isReadOnly={true}
        isFilter={false}
        autoCheck={true}
        allowPinning={false}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
    </CustomDialog>
  );
};

export default DepartmentType1Modal;
